import React, { useState, useEffect } from 'react';
import { DatePickerComponent } from '@syncfusion/ej2-react-calendars';
import { FieldValues, UseFormRegister } from "react-hook-form";
import { TooltipComponent } from '@syncfusion/ej2-react-popups';
import { Button, Modal } from 'react-bootstrap';
interface IInputProps {
  name: string;
  id: string;
  label?: string;
  placeholder?: string;
  errors: any;
  register: UseFormRegister<FieldValues>;
  defaultValue?: any;
  setValue?: any;
  getDayWeek?: (date: Date) => void;
  minDate?: Date;
  maxDate?: Date;
  req?: string;
  disableDate?: boolean
  modal?: string
}

const InputDate: React.FC<IInputProps> = ({
  name,
  id,
  label,
  placeholder,
  errors,
  register,
  defaultValue,
  getDayWeek,
  setValue,
  minDate,
  maxDate,
  req,
  disableDate,
  modal
}) => {
  const [selectedDate, setSelectedDate] = useState<Date | null>(
    defaultValue ? defaultValue : null
  );
  if (defaultValue) {
  }
  const handleDateChange = (date: Date | null) => {
    setSelectedDate(date);
    if (getDayWeek && date) {
      getDayWeek(date);
    }
  };
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);

  return (
    <div className={`container-inputdate input-block ${id} ${errors && errors[name]?.message ? "error" : ""}`}>
      {label && <label htmlFor={id} className="input-block__label">{label}
      </label>}
      {modal ? <TooltipComponent content="Mas Información">
        <div onClick={handleShow} className='information-icon-orange p-0'></div>
      </TooltipComponent> : ''}
      <DatePickerComponent
        format='dd-MM-yyyy'
        name={name}
        id={id}
        change={(event) => handleDateChange(event.value)}
        value={selectedDate}
        // locale='es' 
        firstDayOfWeek={1}
        {...register(name, { required: req })}
        min={minDate}
        max={maxDate}
        disabled={disableDate}
      />
      {errors && errors[name]?.message && <p className="error m-0">{errors && errors[name]?.message}</p>}
      {errors?.[name.split('.')[0]] && errors?.[name.split('.')[0]][name.split('.')[1]]?.message ? //procesamiento de error cuando se usa dentro de un ArrayField
        <>
          <p className="error m-0 ">
            {errors?.[name.split('.')[0]][name.split('.')[1]]?.message}
          </p>
        </>

        : ''}

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          
          <Modal.Title>{label}</Modal.Title>
         
        </Modal.Header>
        <Modal.Body>{modal}</Modal.Body>
      </Modal>
    </div>
  );
};

export default InputDate;
