import React, { useState } from 'react';
import { record } from "@solimat/solimat-web-endpoint";

const useUpdateDirectPaymentFormItByID = () => {
  const [loadingUpdateSolicitudPagoDirectoById, setLoadingCreateSolicitudPagoDirecto] = useState(false);
  const [successUpdateSolicitudPagoDirectoById, setSuccessCreateSolicitudPagoDirecto] = useState(false);
  const [errorUpdateSolicitudPagoDirectoById, setErrorCreateSolicitudPagoDirecto] = useState(null);
  const [dataUpdateSolicitudPagoDirectoById, setDataSolicitudPagoDirecto] = useState(null);

  const BASE_URL = process.env.REACT_APP_PRESTACIONES_PACIENTE_PATH || "";
  const FUNCTIONS_KEY = process.env.REACT_APP_PRESTACIONES_PACIENTE_KEY || "";
  const apiRecord = new record.RecordService(
    BASE_URL,
    FUNCTIONS_KEY
  )


  //UPDATE
  const updateSolicitudPagoDirectoById = async (userId:string ,prestacionId: string, formId: string, data:any, _cb?:(data:any)=>void) => {
    setLoadingCreateSolicitudPagoDirecto(true);
    setSuccessCreateSolicitudPagoDirecto(false);
    setErrorCreateSolicitudPagoDirecto(null);

    apiRecord.updateDirectPaymentFormItByID(userId, prestacionId, formId, data).then((res: any) => {
      setLoadingCreateSolicitudPagoDirecto(false);
      setSuccessCreateSolicitudPagoDirecto(true);
      setDataSolicitudPagoDirecto(res.data);
      if (typeof _cb === "function") {
        _cb(res.data);
      }

    }).catch((err:any) => {
      console.log(err)
      setLoadingCreateSolicitudPagoDirecto(false);
      setErrorCreateSolicitudPagoDirecto(err.response.data)
    })
  }



  return {
    updateSolicitudPagoDirectoById,
    loadingUpdateSolicitudPagoDirectoById,
    successUpdateSolicitudPagoDirectoById,
    errorUpdateSolicitudPagoDirectoById,
    dataUpdateSolicitudPagoDirectoById
  }

}

export default useUpdateDirectPaymentFormItByID;