import React, { useState, useEffect, useRef } from "react";
import { AutoCompleteComponent } from "@syncfusion/ej2-react-dropdowns";
import { useForm } from "react-hook-form";
import { user } from "@solimat/solimat-web-endpoint";
import useGetTypeRoad from "services/utils/useGetTypeRoad";
import useGetAllLocations from "../../../services/utils/useGetAllLocations";
import useGetAllProvinceUser from "services/utils/useGetAllProvinceUser";
import useGetAllCityUser from "services/utils/useGetAllCityUser";
import Moment from "moment";
import { Link } from "react-router-dom";
import './loaderFormEditUser.scss'
import FormOTPPhoneEmail from "./FormOTPPhoneEmail";
import useGetOtpPhoneEmail from "hooks/useGetOtpPhoneEmail";
import { DialogComponent } from '@syncfusion/ej2-react-popups';
import { Modal, Button } from 'react-bootstrap';
const FormEditUser = ({ props, completeUser }) => {
  const BASE_URL = process.env.REACT_APP_USUARIOS_PATH || "";
  const FUNCTIONS_KEY = process.env.REACT_APP_USUARIOS_KEY || "";
  const apiUser = new user.UserService(BASE_URL, FUNCTIONS_KEY);
  const [searchDataAllTypeRoad, setSearchDataAllTypeRoad] = useState([]);
  const [searchDataAllCityUser, setSearchDataAllCityUser] = useState([]);
  const [userAddress, setUserAddress] = useState(null);
  const [loadingSaveAddress, setLoadingSaveAddress] = useState(false);
  const [userEmail, setUserEmail] = useState("");
  const [userPhone, setUserPhone] = useState("");
  const [originalPhone, setOriginalPhone] = useState('');
  const [originalEmail, setOriginalEmail] = useState('');
  const [searchDataAllProvinceUser, setSearchDataAllProvinceUser] = useState([]);
  const [updateError, setUpdateError] = useState({})
  const [modalTitle, setModalTitle] = useState('')
  const [loading, setloading] = useState(false);
  const [filteredLocations, setFilteredLocations] = useState([]);
  const [showModalPhoneOrEmailAlreadyExists, setShowModalPhoneOrEmailAlreadyExists] = useState(false)
  const [titlePhoneOrEmailAlreadyExists, setTitlePhoneOrEmailAlreadyExists] = useState('')
  const [bodyPhoneOrEmailAlreadyExists, setBodyPhoneOrEmailAlreadyExists] = useState('')
  let dialogInstanceRequired = useRef(null);
  const settings = { effect: 'Zoom', duration: 400, delay: 0 };
  const { allTypeRoad, getAllTypeRoad, successGetAllTypeRoad } =
    useGetTypeRoad();
  const { allProvinceUser, getAllProvinceUser, successGetAllProvinceUser } =
    useGetAllProvinceUser();
  const [canBeSent, setCanBeSent] = useState(true);
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    reset,
    getValues,
  } = useForm();
  const { otpPhoneEmail,
    getOtpPhoneEmail,
    loadingGetOtpPhoneEmail,
    errorGetOtpPhoneEmail
  } = useGetOtpPhoneEmail()
  const { allLocationsUser, getAllLocationsUser, successGetAllLocationsUser } =
    useGetAllLocations();
  const { allCityUser, getCityByProvinceUser, successGetAllCityUser } =
    useGetAllCityUser();
  const watchAllFields = watch();
  const values = getValues();
  const [showOTPModal, setShowOTPModal] = useState(false);
  const [showOTPModalInputRequired, setShowOTPModalInputRequired] = useState(false);
  const [hasChangedPhone, setHasChangedPhone] = useState(false)
  const [hasChangedMail, setHasChangedMail] = useState(false)
  useEffect(() => {
    getAllTypeRoad();
    getAllProvinceUser();
  }, []);

  useEffect(() => {
    if (
      successGetAllProvinceUser &&
      successGetAllTypeRoad &&
      successGetAllCityUser
    ) {
      setCanBeSent(false);
      reset({
        change_name: completeUser?.nombre || "",
        change_first_last_name: completeUser?.apellido1 || "",
        change_second_last_name: completeUser?.apellido2 || "",
        change_phone: completeUser?.formated_phone || "",
        change_email: completeUser?.formated_email_address || "",
        road_name: userAddress?.nombreVia || "",
        number: userAddress?.numero || "",
        bisNumber: userAddress?.bisNumero || "",
        block: userAddress?.bloque || "",
        stair: userAddress?.escalera || "",
        floor: userAddress?.piso || "",
        door: userAddress?.puerta || "",
        city: userAddress?.localidad || "",
        postalCode: userAddress?.codigoPostal || "",
        road_type: getTypeRoad(userAddress?.tipoViaCodigo, allTypeRoad) || "",
        province:
          getProvinceUser(
            userAddress?.codigoProvincia,
            allProvinceUser && allProvinceUser
          ) || "",

      });
    }
    if (completeUser) {
      saveAddress(completeUser.direcciones);
      saveEmail(completeUser.emails);
      savePhone(completeUser.telefonos);
    }
  }, [
    userAddress,
    successGetAllProvinceUser,
    successGetAllTypeRoad,
    successGetAllCityUser
  ]);

  useEffect(() => {
    if (allTypeRoad) {
      const arrAllTypeRoadDescription = allTypeRoad.map(
        (typeRoad) => typeRoad.Descripcion1
      );
      setSearchDataAllTypeRoad(arrAllTypeRoadDescription);
    }
  }, [allTypeRoad]);
  useEffect(() => {
    if (allCityUser) {
      const arrCityUserDescription = allCityUser.map(
        (city) => city.Descripcion2
      );
      setSearchDataAllCityUser(arrCityUserDescription);
    }
  }, [allCityUser]);
  useEffect(() => {
    if (allProvinceUser) {
      const arrProvinceUserDescription = allProvinceUser.map(
        (province) => province.Descripcion
      );
      setSearchDataAllProvinceUser(arrProvinceUserDescription);
    }
  }, [allProvinceUser]);
  const saveAddress = (data) => {
    const mainAddress = data.filter((address) => address.principal);

    setUserAddress(mainAddress[0]);
  };
  const saveEmail = (data) => {
    const mainEmail = data.filter((email) => email.principal);
    setUserEmail(mainEmail[0]);
  };
  const savePhone = (data) => {
    const mainPhone = data.filter((telefono) => telefono.principal);
    setUserPhone(mainPhone[0]);
  };
  const timeStamp = Moment().format("Y MM DD, h:mm:ss");
  const fiterCodeRoadType = (type, data) => {
    const code = data?.filter((cod) => cod.Descripcion1 === type);

    return code && code[0]?.Codigo;
  };

  const fiterCodeCity = (type, data) => {
    const code = data?.filter((cod) => cod.Descripcion1 === type);

    return code && code[0]?.Codigo;
  };
  const fiterNameCity = (type, data) => {
    const nameCity = data?.filter((cit) => cit.Codigo === type);
    return nameCity && nameCity[0]?.Descripcion1;
  };
  const fiterCodeProvince = (type, data) => {
    const code = data?.filter((cod) => cod.Descripcion === type);
    return code && code[0]?.Codigo;
  };
  useEffect(() => {
    if (completeUser) {
      setOriginalPhone(completeUser.formated_phone || '');
      setOriginalEmail(completeUser.formated_email_address || '');
      if (completeUser.direcciones && completeUser.direcciones.find((address) => address.principal)) {
        getCityByProvinceUser(completeUser.direcciones.find((address) => address.principal).codigoProvincia);
      }
    }
  }, [completeUser]);

  useEffect(() => {
    if (allCityUser && allProvinceUser) {
      const codeProvinceUser = values.province;
      const codeP = fiterCodeProvince(codeProvinceUser, allProvinceUser);
      const filteredLocations = allCityUser?.filter((location) =>
        location?.Codigo?.startsWith(codeP)
      );
      setFilteredLocations(filteredLocations ? filteredLocations : []);
    }
  }, [allCityUser, userAddress, allProvinceUser, values.province, values.city]);
  const openModalInputRequired = () => {
    setShowOTPModalInputRequired(true);

  }
  const closeOTPModalInputRequired = () => {
    setShowOTPModalInputRequired(false);
  };
  const openOTPModal = () => {
    setShowOTPModal(true);
  };

  const closeOTPModal = () => {
    setShowOTPModal(false);
  };

  const onSubmitWithOTP = (values, otpPhone, otpEmail) => {
    setLoadingSaveAddress(true);
    setloading(true);
    if (userAddress !== null) {
      const codeRoadType = fiterCodeRoadType(
        values.road_type,
        allTypeRoad && allTypeRoad
      );
      const codeCity = fiterCodeCity(values.city, allCityUser && allCityUser);
      const codigoProvincia = fiterCodeProvince(
        values.province,
        allProvinceUser && allProvinceUser
      );
      const thereIsTelephone =
        values.change_phone === ""
          ? {}
          : {
            id: userPhone?.id.length > 0 ? userPhone?.id : otpPhoneEmail?.telefonoId,
            usuarioId: completeUser.id,
            prefijo: "(+34)",
            numero: values.change_phone,
            tipoTelefono: 1,
            principal: true,
            otp: otpPhone || ""
          };
      let dataUser = {
        id: completeUser.id,
        azureADB2CObjectId: completeUser.azureADB2CObjectId,
        numeroSeguridadSocial: completeUser.numeroSeguridadSocial,
        externalId: completeUser.externalId,
        telefono: thereIsTelephone,
        email: {
          id: userEmail?.id.length > 0 ? userEmail?.id : otpPhoneEmail?.emailId,
          correo: values.change_email,
          principal: true,
          usuarioId: completeUser.id,
          otp: otpEmail || ""
        },
        nombre: values.change_name,
        apellido1: values.change_first_last_name,
        apellido2: values.change_second_last_name,
        ipf: completeUser.ipf,
        sexo: completeUser.sexo,
        fechaNacimiento: completeUser.fechaNacimiento,
        tipoIPF: completeUser.tipoIPF,
        direccion: {
          id: userAddress?.id,
          usuarioId: completeUser.id,
          tipoViaCodigo: codeRoadType,
          tipoViaDescripcion: values.road_type,
          nombreVia: values.road_name,
          domicilio: "",
          codigoProvincia: codigoProvincia,
          provincia: values.province,
          localidad: values.city,
          codigoLocalidad: codeCity,
          codigoPostal: values.postalCode,
          numero: values.number,
          piso: values.floor,
          escalera: values.stair,
          puerta: values.door,
          principal: true,
          tipoDomicilio: 1,
          bisNumero: values.bisNumber,
          bloque: values.block,
          direccionExtranjero: values.direccionExtranjero,
          paisExtranjero: values.countryForeign,
          localidadExtranjero: values.localidadExtranjero,
          periodo: timeStamp,
        },

      };
      apiUser
        .updateUser(dataUser)
        .then(() => {
          setLoadingSaveAddress(false)
          setloading(false);
          closeOTPModal()
          props.history.push("/ajustes");
        })
        .catch((err) => {
          setLoadingSaveAddress(false)
          setloading(false);
          console.error("data updateUser ERROR: ", err);
          setUpdateError(err)
        });
    }

  };


  const onSubmit = async (values, e) => {
    e.preventDefault();
    setLoadingSaveAddress(true);

    const userId = completeUser.id;
    const openRequiredModal = (title) => {
      setModalTitle(title);
      setHasChangedPhone(true);
      openModalInputRequired();
    };

    let shouldCallAPI = true;

    switch (true) {
      case values.change_phone.length === 0 && originalPhone.length === 9:
        openRequiredModal('El campo Teléfono es obligatorio');
        setLoadingSaveAddress(false)
        shouldCallAPI = false;
        break;

      case values.change_email.length === 0 && originalEmail.length > 1:
        openRequiredModal('El campo Email es obligatorio');
        setLoadingSaveAddress(false)
        shouldCallAPI = false;
        break;


        case values.change_email !== originalEmail && values.change_phone !== originalPhone && values.change_email.length != 0 && values.change_phone.length != 0:
          try {
            const otpPhoneEmail = await getOtpPhoneEmail(userId, values.change_phone, values.change_email);
  
            if (otpPhoneEmail?.telefonoRepetido && otpPhoneEmail?.emailRepetido) {
              setTitlePhoneOrEmailAlreadyExists('¡El correo electrónico y teléfono introducidos ya existen!');
              setBodyPhoneOrEmailAlreadyExists('Ya hay un usuario registrado en el Portal de Paciente con esta dirección y este teléfono. Por favor, proceda a introducir un correo electrónico y un teléfono distintos. Si necesita ayuda, póngase en contacto con su gestor asignado.');
              handleShowModalPhoneOrEmailAlreadyExists();
              setLoadingSaveAddress(false)
              return
            } else {
              setModalTitle('Necesitamos verificar su identidad, por favor introduzca el código que hemos enviado a su teléfono y a su email');
              setHasChangedPhone(true);
              setHasChangedMail(true);
              openOTPModal();
              setLoadingSaveAddress(false)
              shouldCallAPI = false;
            }
          } catch (error) {
            console.error('Error obteniendo OTP:', error);
          }
          break;

          
      case values.change_phone !== originalPhone && values.change_phone.length === 9 && values.change_email === originalEmail && values.change_phone != 0:
        try {
          const otpPhoneEmail = await getOtpPhoneEmail(userId, values.change_phone, '');

          if (otpPhoneEmail?.telefonoRepetido && !otpPhoneEmail?.emailRepetido) {
            setTitlePhoneOrEmailAlreadyExists('¡El teléfono introducido ya existe!');
            setBodyPhoneOrEmailAlreadyExists('Ya hay un usuario registrado en el Portal de Paciente con este teléfono. Por favor, proceda a introducir un teléfono distinto. Si necesita ayuda, póngase en contacto con su gestor asignado.');
            handleShowModalPhoneOrEmailAlreadyExists();
            setLoadingSaveAddress(false)
            return
          }
          else {
            setModalTitle('Necesitamos verificar su identidad, por favor introduzca el código que hemos enviado a su teléfono');
            setHasChangedPhone(true);
            setHasChangedMail(false);
            openOTPModal();
            setLoadingSaveAddress(false)
            shouldCallAPI = false;
          }
        } catch (error) {
          console.error('Error obteniendo OTP:', error);
        }
        break;

      case values.change_email !== originalEmail && values.change_phone === originalPhone && values.change_email.length != 0:
        try {
          const otpPhoneEmail = await getOtpPhoneEmail(userId, '', values.change_email);

          if (otpPhoneEmail?.emailRepetido && !otpPhoneEmail?.telefonoRepetido) {
            setTitlePhoneOrEmailAlreadyExists('¡El correo electrónico introducido ya existe!');
            setBodyPhoneOrEmailAlreadyExists('Ya hay un usuario registrado en el Portal de Paciente con esta dirección. Por favor, proceda a introducir un correo electrónico distinto. Si necesita ayuda, póngase en contacto con su gestor asignado.');
            handleShowModalPhoneOrEmailAlreadyExists();
            setLoadingSaveAddress(false)
            return
          } else {
            setModalTitle('Necesitamos verificar su identidad, por favor introduzca el código que hemos enviado a su email');
            setHasChangedMail(true);
            setHasChangedPhone(false);
            openOTPModal();
            setLoadingSaveAddress(false)
            shouldCallAPI = false;
          }
        } catch (error) {
          console.error('Error obteniendo OTP:', error);
        }
        break;

      default: break;
    }

    if (shouldCallAPI) {
      if (userAddress !== null) {
        const codeRoadType = fiterCodeRoadType(values.road_type, allTypeRoad);
        const codeCity = fiterCodeCity(values.city, allCityUser);
        const codigoProvincia = fiterCodeProvince(values.province, allProvinceUser);

        const thereIsTelephone =
          values.change_phone === ""
            ? {}
            : {
              usuarioId: userId || '',
              prefijo: "(+34)",
              numero: values.change_phone,
              tipoTelefono: 1,
              principal: true,
              otp: '',
            };

        const dataUser = {
          id: completeUser.id,
          azureADB2CObjectId: completeUser.azureADB2CObjectId,
          numeroSeguridadSocial: completeUser.numeroSeguridadSocial,
          externalId: completeUser.externalId,
          telefono: thereIsTelephone,
          email: {
            id: userEmail.id,
            correo: values.change_email,
            principal: true,
            usuarioId: userEmail.usuarioId,
            otp: ''
          },
          nombre: values.change_name,
          apellido1: values.change_first_last_name,
          apellido2: values.change_second_last_name,
          ipf: completeUser.ipf,
          sexo: completeUser.sexo,
          fechaNacimiento: completeUser.fechaNacimiento,
          tipoIPF: completeUser.tipoIPF,
          direccion: {
            id: userAddress?.id,
            usuarioId: completeUser.id,
            tipoViaCodigo: codeRoadType,
            tipoViaDescripcion: values.road_type,
            nombreVia: values.road_name,
            domicilio: "",
            codigoProvincia: codigoProvincia,
            provincia: values.province,
            localidad: values.city,
            codigoLocalidad: codeCity,
            codigoPostal: values.postalCode,
            numero: values.number,
            piso: values.floor,
            escalera: values.stair,
            puerta: values.door,
            principal: true,
            tipoDomicilio: 1,
            bisNumero: values.bisNumber,
            bloque: values.block,
            direccionExtranjero: values.direccionExtranjero,
            paisExtranjero: values.countryForeign,
            localidadExtranjero: values.localidadExtranjero,
            periodo: timeStamp,
          },
        };
        setLoadingSaveAddress(true)
        apiUser
          .updateUser(dataUser)
          .then(() => {
            setLoadingSaveAddress(false);
            setTimeout(() => {
              props.history.push("/ajustes");
            }, 2000);



          })
          .catch((err) => {
            console.error("Error al actualizar el usuario:", err);
          });
      }
    }
  };

  const getProvinceUser = (cod, data) => {
    const province = data?.filter((prov) => prov.Codigo === cod);
    return province && province[0]?.Descripcion;
  };
  const getTypeRoad = (cod, data) => {
    const roadType = data?.filter((type) => type.Codigo === cod);
    return roadType && roadType[0]?.Descripcion1;
  };

  const handleProvinceChange = (args) => {
    let codeP = args?.value;

    codeP = fiterCodeProvince(args?.itemData?.text, allProvinceUser);
    if (codeP) {
      getCityByProvinceUser(codeP);
    }

    if ((codeP === undefined || codeP === null) && userAddress) {
      reset({
        city: "",
        postalCode: "",
      });
      // setFilteredLocations([]);
    }
    // if (codeP && (args.previousItemData || filteredLocations.length == 0) && userAddress) {
    //   console.log('args.previousItemData' ,args.previousItemData )
    //   console.log('filteredLocations',filteredLocations)
    //   console.log('userAddress',userAddress)
    //   reset({
    //     city: "",
    //     postalCode: "",
    //   });
    //   getCityByProvinceUser(codeP);
    // }
  };

  const handleCityChange = (args) => {

    if (args.value === null && userAddress) {
      reset({
        postalCode: "",
      });

    }

  };
  const handleShowModalPhoneOrEmailAlreadyExists = () => {

    setShowModalPhoneOrEmailAlreadyExists(true);
  };

  const handleCloseModalPhoneOrEmailAlreadyExists = () => {
    setShowModalPhoneOrEmailAlreadyExists(false);

  };

  return (
    <>
      {canBeSent ? (
        <div className={`box-loader  ${canBeSent ? "active" : ""}`} >
          <div className="loader-spinner" data-testid='divLoader'></div>
          <span className='label-loader'>Descargando datos de usuario</span>
        </div>
      ) : (
        <div className="container container-principal-form-edit-user">
          {!!completeUser && (
            <form
              onSubmit={handleSubmit((values, e) => onSubmit(values, e))}
              className="form-modal form-regular form-labels"
            >
              <div className=" row container-complete-name">
                <div className="col-sm-12 col-lg-4">
                  <label htmlFor="change_name" className="label-block">
                    <input
                      type="text"
                      className={`input ${errors.subject ? "invalid" : ""} ${watchAllFields.change_name !== "" ? "typed" : ""
                        }`}
                      name="change_name"
                      id="change_name"
                      // defaultValue={completeUser.nombre || ""}
                      {...register("change_name", {
                        required: "Campo requerido",
                        maxLength: {
                          value: 33,
                          message: "Máximo 33 caracteres",
                        },
                      })}
                    />
                    <p className="float-label">Nombre</p>
                    {errors.change_name && (
                      <p className="form-error">
                        {errors["change_name"].message}
                      </p>
                    )}
                  </label>
                </div>
                <div className="col-sm-12 col-lg-4">
                  <label
                    htmlFor="change_first_last_name"
                    className="label-block"
                  >
                    <input
                      type="text"
                      className={`input ${errors.subject ? "invalid" : ""} ${watchAllFields.change_first_last_name !== ""
                        ? "typed"
                        : ""
                        }`}
                      name="change_first_last_name"
                      id="change_first_last_name"
                      // defaultValue={completeUser.apellido1 || ""}
                      {...register("change_first_last_name", {
                        required: "Campo requerido",
                        maxLength: {
                          value: 33,
                          message: "Máximo 33 caracteres",
                        },
                      })}
                    />
                    <p className="float-label">Primer apellido</p>
                    {errors.change_first_last_name && (
                      <p className="form-error">
                        {errors["change_first_last_name"].message}
                      </p>
                    )}
                  </label>
                </div>
                <div className="col-sm-12 col-lg-4">
                  <label
                    htmlFor="change_second_last_name"
                    className="label-block"
                  >
                    <input
                      type="text"
                      className={`input ${errors.subject ? "invalid" : ""} ${watchAllFields.change_second_last_name !== ""
                        ? "typed"
                        : ""
                        }`}
                      name="change_second_last_name"
                      id="change_second_last_name"
                      // defaultValue={completeUser.apellido2 || ""}
                      {...register("change_second_last_name", {
                        required: "Campo requerido",
                        maxLength: {
                          value: 33,
                          message: "Máximo 33 caracteres",
                        },
                      })}
                    />
                    <p className="float-label">Segundo apellido</p>
                    {errors.change_second_last_name && (
                      <p className="form-error">
                        {errors["change_second_last_name"].message}
                      </p>
                    )}
                  </label>
                </div>
                <div className="col-sm-12 col-lg-6">
                  <label htmlFor="change_phone" className="label-block">
                    <input
                      type="text"
                      className={`input ${errors.subject ? "invalid" : ""} ${watchAllFields.change_phone !== "" ? "typed" : ""
                        }`}
                      name="change_phone"
                      id="change_phone"
                      // readOnly
                      // defaultValue={completeUser.formated_phone || ""}
                      {...register("change_phone", {
                        required: values?.change_email?.length === 0 ? "Debe de haber almenos un campo relleno, teléfono ó email" : false,
                        pattern: {
                          value: /^([+]?\d{1,2}[-\s]?|)[6|7][0-9]{8}$/i,
                          message:
                            "Su teléfono debe tener al menos 9 dígitos y empezar por 6 ó 7",
                        },
                        minLength: {
                          value: 9,
                          message:
                            "El número de teléfono tiene que tener al menos 9 caracteres",
                        },
                      })}
                    />
                    <span className="float-label">Número de teléfono</span>
                    {errors.change_phone && (
                      <p className="form-error">
                        {errors["change_phone"].message}
                      </p>
                    )}
                  </label>
                </div>
                <div className="col-sm-12 col-lg-6">
                  <label htmlFor="change_email" className="label-block">
                    <input
                      type="text"
                      className={`input ${errors.subject ? "invalid" : ""} ${watchAllFields.change_email !== "" ? "typed" : ""
                        }`}
                      name="change_email"
                      id="change_email"
                      // readOnly
                      // defaultValue={completeUser.formated_email_address || ""}
                      {...register("change_email", {
                        required: values?.change_phone?.length !== 9 ? "Debe de haber almenos un campo relleno, teléfono ó email" : false,
                        pattern: {
                          value:
                            /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                          message:
                            "debe introducir una direccion de  correo valida",
                        },
                      })}
                    />
                    <p className="float-label">Email</p>
                    {errors.change_email && (
                      <p data-testid="error-mail" className="form-error">
                        {errors["change_email"].message}
                      </p>
                    )}
                  </label>
                </div>

                <div className="col-sm-12 col-lg-12">
                  <div>
                    <div className="row">
                      <div className="col-xl-6">
                        <label htmlFor="road_type" className="label-block">
                          <div className="auto-complete-component--form">
                            <AutoCompleteComponent
                              id="road_type"
                              name="road_type"
                              ignoreAccent={true}
                              allowCustom={false}
                              dataSource={
                                searchDataAllTypeRoad?.length
                                  ? searchDataAllTypeRoad
                                  : []
                              }
                              delayUpdate={getTypeRoad(
                                userAddress?.tipoViaCodigo,
                                allTypeRoad
                              )}
                              // value={getTypeRoad(
                              //   userAddress?.tipoViaCodigo,
                              //   allTypeRoad
                              // )||''}
                              placeholder="Tipo de vía"
                              floatLabelType="Auto"
                              {...register("road_type", {
                                required: "Campo requerido",
                              })}
                            />
                          </div>
                          {errors.road_type && (
                            <p className="form-error">
                              {errors["road_type"].message}
                            </p>
                          )}
                        </label>
                      </div>
                      <div className="col-xl-6">
                        <label htmlFor="road_name" className="label-block">
                          <input
                            type="text"
                            className={`input ${errors.road_name ? "invalid" : ""
                              } ${watchAllFields.road_name !== "" ? "typed" : ""
                              }`}
                            name="road_name"
                            id="road_name"
                            // defaultValue={
                            //   (userAddress && userAddress?.nombreVia) || ""
                            // }
                            {...register("road_name", {
                              required: "Campo requerido",
                              maxLength: {
                                value: 50,
                                message: "Máximo 50 caracteres",
                              },
                            })}
                          />
                          <span className="float-label">Nombre Vía</span>
                          {errors.road_name && (
                            <p className="form-error">
                              {errors["road_name"].message}
                            </p>
                          )}
                        </label>
                      </div>
                      <div className="col-xl-4">
                        <label htmlFor="number" className="label-block">
                          <input
                            type="text"
                            className={`input ${errors.number ? "invalid" : ""
                              } ${watchAllFields.number !== "" ? "typed" : ""}`}
                            name="number"
                            id="number"
                            // defaultValue={userAddress?.numero || ""}
                            {...register("number", {
                              maxLength: {
                                value: 5,
                                message: "Máximo 5 caracteres",
                              },
                            })}
                            min={1}
                          />
                          <span className="float-label">Número</span>
                          {errors.number && (
                            <p className="form-error">
                              {errors["number"].message}
                            </p>
                          )}
                        </label>
                      </div>
                      <div className="col-xl-4">
                        <label htmlFor="bisNumber" className="label-block">
                          <input
                            type="text"
                            className={`input ${errors.bisNumber ? "invalid" : ""
                              } ${watchAllFields.bisNumber !== "" ||
                                watchAllFields.bisNumber
                                ? "typed"
                                : ""
                              }`}
                            name="bisNumber"
                            id="bisNumber"
                            // defaultValue={userAddress?.bisNumero || ""}
                            {...register("bisNumber", {
                              maxLength: {
                                value: 2,
                                message: "Máximo 2 caracteres",
                              },
                            })}
                          />
                          <span className="float-label">Número Bis</span>
                          {errors.bisNumber && (
                            <p className="form-error">
                              {errors["bisNumber"].message}
                            </p>
                          )}
                        </label>
                      </div>
                      <div className="col-xl-4">
                        <label htmlFor="block" className="label-block">
                          <input
                            type="text"
                            className={`input ${errors.block ? "invalid" : ""
                              } ${watchAllFields.block !== "" ? "typed" : ""}`}
                            name="block"
                            id="block"
                            // defaultValue={userAddress?.bloque || ""}
                            {...register("block", {
                              maxLength: {
                                value: 4,
                                message: "Máximo 4 caracteres",
                              },
                            })}
                          />
                          <span className="float-label">Bloque</span>
                          {errors.block && (
                            <p className="form-error">
                              {errors["block"].message}
                            </p>
                          )}
                        </label>
                      </div>
                      <div className="col-xl-4">
                        <label htmlFor="stair" className="label-block">
                          <input
                            type="text"
                            className={`input ${errors.stair ? "invalid" : ""
                              } ${watchAllFields.stair !== "" ? "typed" : ""}`}
                            name="stair"
                            id="stair"
                            // defaultValue={userAddress?.escalera || ""}
                            {...register("stair", {
                              maxLength: {
                                value: 2,
                                message: "Máximo 2 caracteres",
                              },
                            })}
                          />
                          <span className="float-label">Escalera</span>
                          {errors["stair"]?.message !== undefined ? (
                            <p className="form-error">
                              {errors["stair"]?.message}
                            </p>
                          ) : (
                            ""
                          )}
                        </label>
                      </div>
                      <div className="col-xl-4">
                        <label htmlFor="floor" className="label-block">
                          <input
                            type="text"
                            className={`input ${errors.floor ? "invalid" : ""
                              } ${watchAllFields.floor !== "" ? "typed" : ""}`}
                            name="floor"
                            id="floor"
                            // defaultValue={userAddress?.piso || ""}
                            {...register("floor", {
                              maxLength: {
                                value: 2,
                                message: "Máximo 2 caracteres",
                              },
                            })}
                          />
                          <span className="float-label">Piso</span>
                          {errors.floor && (
                            <p className="form-error">
                              {errors["floor"].message}
                            </p>
                          )}
                        </label>
                      </div>
                      <div className="col-xl-4">
                        <label htmlFor="door" className="label-block">
                          <input
                            type="text"
                            className={`input ${errors.door ? "invalid" : ""} ${watchAllFields.door !== "" ? "typed" : ""
                              }`}
                            name="door"
                            id="door"
                            // defaultValue={userAddress?.puerta || ""}
                            {...register("door", {
                              maxLength: {
                                value: 3,
                                message: "Máximo 3 caracteres",
                              },
                            })}
                          />
                          <span className="float-label">Puerta</span>
                          {errors.door && (
                            <p className="form-error">
                              {errors["door"].message}
                            </p>
                          )}
                        </label>
                      </div>

                      <div className="col-xl-4">
                        <label htmlFor="province" className="label-block">
                          <div className="auto-complete-component--form">
                            <AutoCompleteComponent
                              id="province"
                              name="province"
                              ignoreAccent={true}
                              allowCustom={false}
                              dataSource={
                                searchDataAllProvinceUser?.length
                                  ? searchDataAllProvinceUser
                                  : []
                              }
                              value={getProvinceUser(
                                userAddress?.codigoProvincia,
                                allProvinceUser
                              )}
                              placeholder="Provincia"
                              floatLabelType="Auto"
                              change={handleProvinceChange}
                              {...register("province", {
                                required: "Campo requerido",
                                maxLength: {
                                  value: 40,
                                  message: "Máximo 40 caracteres",
                                },
                              })}
                            />
                          </div>
                          {errors.province && (
                            <p className="form-error">
                              {errors["province"].message}
                            </p>
                          )}
                        </label>
                      </div>
                      <div className="col-xl-4">
                        <label htmlFor="city" className="label-block">
                          <div className="auto-complete-component--form">
                            <AutoCompleteComponent
                              id="city"
                              name="city"
                              // className="auto-complete-component--form"
                              ignoreAccent={true}
                              allowCustom={false}
                              dataSource={
                                filteredLocations?.length
                                  ? filteredLocations?.map(
                                    (location) => location.Descripcion2
                                  )
                                  : []
                              }
                              value={fiterNameCity(userAddress?.codigoLocalidad, allLocationsUser) || ''}
                              placeholder="Municipio"
                              floatLabelType="Auto"
                              change={handleCityChange}
                              {...register("city", {
                                required: "Campo requerido",
                                maxLength: {
                                  value: 40,
                                  message: "Máximo 40 caracteres",
                                },
                              })}
                            />
                          </div>
                          {errors.city && (
                            <p className="form-error">
                              {errors["city"].message}
                            </p>
                          )}
                        </label>
                      </div>
                      <div className="col-xl-4">
                        <label htmlFor="postalCode" className="label-block">
                          <input
                            type="text"
                            className={`input ${errors.postalCode ? "invalid" : ""
                              } ${watchAllFields.postalCode !== "" ? "typed" : ""}
                        }`}
                            name="postalCode"
                            id="postalCode"
                            //  defaultValue={userAddress?.codigoPostal || ""}

                            {...register("postalCode", {
                              required: "Campo requerido",
                              maxLength: {
                                value: 5,
                                message: "El código postal debe tener 5 caracteres",
                              },
                              minLength: {
                                value: 5,
                                message: "Minimo 5 caracteres",
                              }
                            })}
                          />
                          <span className="float-label">Código postal</span>
                          {errors.postalCode && (
                            <p className="form-error">
                              {errors["postalCode"].message}
                            </p>
                          )}
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="container">
                <div className="btns-container container-btn-formedituser">
                  <Link
                    type="button"
                    className="btn-box btn-clear"
                    to={`/ajustes`}
                  >
                    Cancelar
                  </Link>
                  <button
                    type="submit"
                    value="Enviar"
                    className={`btn-box btn-accent loading ${loadingSaveAddress ? "active" : ""
                      }`}
                    disabled={canBeSent}
                  >
                    <div className="loader-btn"></div>
                    {loadingSaveAddress ? "" : "Enviar"}
                  </button>
                </div>
              </div>
            </form>
          )}
        </div>
      )}

      <FormOTPPhoneEmail showOTPModal={showOTPModal} openOTPModal={openOTPModal} completeUser={completeUser} getOtpPhoneEmail={getOtpPhoneEmail} closeOTPModal={closeOTPModal} hasChangedMail={hasChangedMail} hasChangedPhone={hasChangedPhone} newPhone={values.change_phone} newEmail={values.change_email} modalTitle={modalTitle} values={values} onSubmitWithOTP={onSubmitWithOTP} loadingSaveAddress={loadingSaveAddress} updateError={updateError} setModalTitle={setModalTitle} setUpdateError={setUpdateError} loading={loading}></FormOTPPhoneEmail>
      <Modal show={showOTPModalInputRequired} onHide={closeOTPModalInputRequired} centered>
        <Modal.Header closeButton={false} className='header-modal-observaciones-rd'>
          <span className='information-icon-orange big'></span>
          <Modal.Title>
            <h2 className='title-obsevaciones-rd text-center'>{modalTitle}</h2>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className='body-modal-observaciones-rd text-center'>
          <p className='text-body-modal-observaciones-rd'>No puede dejar este campo en blanco</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={closeOTPModalInputRequired}>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>


      <Modal show={showModalPhoneOrEmailAlreadyExists} onHide={handleCloseModalPhoneOrEmailAlreadyExists} centered>
        <Modal.Header closeButton={false} className='header-modal-observaciones-rd'>
          <span className='information-icon-orange big'></span>
          <Modal.Title>

            <h2 className='title-obsevaciones-rd text-center'>{titlePhoneOrEmailAlreadyExists}</h2>

          </Modal.Title>
        </Modal.Header>
        <Modal.Body className='body-modal-observaciones-rd text-center'>
          <p className='text-body-modal-observaciones-rd'>{bodyPhoneOrEmailAlreadyExists}</p>

        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModalPhoneOrEmailAlreadyExists}>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default FormEditUser;