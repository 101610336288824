import React from 'react'
import { useLocation } from "react-router-dom";
//import SolicitudPagoDirectoIncapacidadTemporalGrid from 'components/pages/ManagementNewForms/SolicitudPagoDirectoIncapacidadTemporalGrid';
import Modelo145Grid from 'components/pages/ManagementNewForms/FomularioModelo45/Modelo145Grid';
import GridFormularioPagoDirecto from 'components/pages/ManagementNewForms/FormularioPagoDirecto/GridFormularioPagoDirecto';
import GridDeclaracionSituacionActividad from 'components/pages/ManagementNewForms/FormularioDeclaracionSituacionActividad/GridDeclaracionSituacionActividad';
const PageRenderNewForms = () => {
    const location = useLocation();
    const { id } = location.state || {};
    switch (id) {

        case 4:

            return (
                <GridDeclaracionSituacionActividad/>
            )



        case 5:

            return (
                <div>

                    <GridFormularioPagoDirecto/>

                </div>
            )

        case 29:

            return (
                <div>
                    <Modelo145Grid/>
                </div>
            )

        default:
            break;
    }

}


export default PageRenderNewForms

