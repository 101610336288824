import React, { useEffect, useState } from 'react'
import { useHistory } from "react-router-dom";
import PageMenu from 'components/blocks/PageMenu/PageMenu'
import IconArrowGray from "../../../../img/icons/svg/botones/flecha-big-gris.svg";
import { useParams } from 'react-router-dom';

import Loader from 'components/blocks/Loader/Loader';
import SolicitudModelo145 from './Modelo145';
import useGetModelo145ItByID from 'services/record/useGetModelo145ItByID';
const jwtDecode = require('jwt-decode');
interface Params {
    idprestacion: string;
    idform: string;
}


const HistorialModelo145 = () => {

    const [data, setData] = useState(null);
    const history = useHistory()
    const { getModelo145ById,
        loadingGetModelo145ById,
        dataGetModelo145ById } = useGetModelo145ItByID();
    const { idprestacion, idform } = useParams<Params>();


    useEffect(() => {
        const actualUrl = window.location.href;
        if (!actualUrl.includes('/mis-gestiones/formularios')) {
            const userId = jwtDecode(localStorage.getItem("token")).extension_id;
            const prestacionId = idprestacion.split('_')[1];
            const formId = idform;
            console.log("loading", loadingGetModelo145ById)
            getModelo145ById(userId, prestacionId, formId, setData);
            console.log("loading", loadingGetModelo145ById)
        }
    }, []);




    return (
        <div>
            <PageMenu title={'Modelo 145'}>

                <div className="btns-header-container">
                    <button onClick={() => history.goBack()}>
                        <img src={IconArrowGray} alt="icon arrow right" />
                    </button>
                </div>
            </PageMenu>


            {
                loadingGetModelo145ById ?
                    <Loader activeLoader={true} label="Cargando Formulario"></Loader>
                    :
                    <SolicitudModelo145 dataUser={data} info={false} />
            }




        </div>
    )
}

export default HistorialModelo145