import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import PageMenu from '../../../blocks/PageMenu/PageMenu';
import IconArrowGray from "../../../../img/icons/svg/botones/flecha-big-gris.svg";
import useGetDirectPaymentFrmsItByUser from "services/record/useGetDirectPaymentFormsItByUser";
import useGetInfoDirectPaymentFormIt from "services/record/useGetInfoDirectPaymentFormIt";
import useDecode from "hooks/useDecode";
import {
    GridComponent,
    ColumnsDirective,
    ColumnDirective,
    Page,
    Sort,
    Inject,
} from "@syncfusion/ej2-react-grids";
import Loader from "components/blocks/Loader/Loader";
import LoaderMin from "components/blocks/Loader/LoaderMin";
import useGetModelo145ItByUser from "services/record/useGetModelo145ItByUser";
import useGetInfoModelo145It from "services/record/useGetInfoModelo145It";
import { get } from "http";
import Modelo145 from "../../GestionForms/Modelo145";
import SolicitudModelo145 from "./Modelo145";

const Modelo145Grid = () => {
    const { dataGetModelo145ItByUser, getModelo145ItByUser, successGetModelo145ItByUser } = useGetModelo145ItByUser()
    const { getInfoModelo145It, infoModelo145It, loadingGetInfoModelo145It, successGetInfoModelo145It } = useGetInfoModelo145It()
    const title = 'Modelo 145'
    const history = useHistory();
    const tokenResponse = useDecode("token");
    const userId = tokenResponse && tokenResponse.extension_id;
    const [arrData, setArrData] = useState(null)
    const [dataUser, setDataUser] = useState(null);
    // const [loadInfo, setLoadInfo] = useState('')
    const [info, setInfo] = useState(false);
    const [loading, setLoading] = useState(false);
    const [show, setShow] = useState(false);
    const [sortingOptions] = useState({
        columns: [{ field: "created", direction: "Descending" }],
    });
    let pageOptions = {
        pageSize: 10,
        pageSizes: ["5", "10", "15", "20", "Todos"],
    };
    const rowSelected = (args) => {
        setInfo(false)
        setLoading(true)
        setShow(false)
        setTimeout(() => {
            setShow(true)
            setLoading(false)

        }, 200);
        const selectedData = args.data;
        setDataUser(selectedData);

    };
    useEffect(() => {

        getModelo145ItByUser(userId);
    }, [])

    const handleGetInfoDirectPaymentFormIt = () => {
        setInfo(true)
        getInfoModelo145It(userId, setDataUser)
        if (dataUser) {
            console.log(dataUser)
            //setLoadInfo('endProcces')
        }
    }

    useEffect(() => {
        setLoading(true)
        setShow(false)
        setTimeout(() => {
            setShow(true)
            setLoading(false)

        }, 200);
    }, [dataUser]);

    useEffect(() => {
        if (successGetModelo145ItByUser) {
            setArrData(dataGetModelo145ItByUser);
        }
    }, [dataGetModelo145ItByUser]);

    return (
        <div className="container bg-#EEEEEE">
            <PageMenu title={title} >
                <div className="btns-header-container">
                    <button onClick={() => history.goBack()}>
                        <img src={IconArrowGray} alt="icon arrow right" />
                    </button>
                </div>
            </PageMenu>
            <GridComponent
                dataSource={arrData && arrData}
                allowPaging={true}
                allowSorting={true}
                rowSelected={rowSelected}
                pageSettings={pageOptions}
                //sortSettings={sortingOptions}
                enablePersistence={false}
            >
                <ColumnsDirective>
                    <ColumnDirective
                        field="DatosPersonales.SolicitanteNombre"
                        headerText="Razon Social"
                        width="100"
                        textAlign="Center"
                    />
                    <ColumnDirective
                        field="EstadoFormulario"
                        headerText="Estado"
                        width="100"
                        textAlign="Center"
                    />
                    <ColumnDirective
                        field="FechaAceptacionSolicitud"
                        headerText="Fecha aceptación"
                        type="date"
                        format="dd/MM/yyyy"
                        width="100"
                    />
                    <ColumnDirective
                        field="FechaBajaMedica"
                        headerText="Fecha Baja"
                        type="date"
                        format="dd/MM/yyyy"
                        width="100"
                    />
                </ColumnsDirective>
                <Inject services={[Page, Sort]} />
            </GridComponent>
            <div className="delta-buttons c-right">
                <button className="btn-box btn-accent" onClick={() => handleGetInfoDirectPaymentFormIt()}>
                    Generar Formulario
                </button>
            </div>
            <Loader activeLoader={loading} label="Cargando Formulario"></Loader>
            {dataUser && show &&
                <SolicitudModelo145 dataUser={dataUser} info={info} ></SolicitudModelo145>
                //  <LoaderMin activeLoader label="Cargando Datos "></LoaderMin>

            }

        </div>
    );
};
export default Modelo145Grid;
