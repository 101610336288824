import React, { useEffect, useState, createContext, useContext } from 'react'
import { useLocation, Link, useHistory } from 'react-router-dom';
import PageMenu from 'components/blocks/PageMenu/PageMenu';
import IconArrowGray from "../../../../img/icons/svg/botones/flecha-big-gris.svg";
import FieldsetContainer from 'components/blocks/Delta/Inputs/Containers/FieldsetContainer';
import { Controller, FormProvider, set, useFieldArray, useForm, useWatch } from 'react-hook-form';
import Loader from 'components/blocks/Loader/Loader';
import AccordionForm from 'components/blocks/ManagementNewForms/Accordion/AccordionForm';
import FormInputText from 'components/blocks/ManagementNewForms/Inputs/FormInputText';
import FormInputIPF from 'components/blocks/ManagementNewForms/Inputs/FormInputIPF';
import FormInputFecha from 'components/blocks/ManagementNewForms/Inputs/FormInputFecha';
import Province from 'components/blocks/Delta/Inputs/Province';
import TestCommunity from 'components/blocks/Delta/Inputs/TestCommunity';
import AgreementType from 'components/blocks/Delta/Inputs/AgreementType';
//import FormInputTipoContrato from 'components/blocks/ManagementNewForms/Inputs/FormInputTipoContrato';
import InputRegimenFormularios from 'components/blocks/ManagementNewForms/Inputs/InputRegimenFormularios';
import InputContigenciasPagoDirecto from 'components/blocks/ManagementNewForms/Inputs/InputContingenciasPagoDirecto';
import InputCausaDelPago from 'components/blocks/ManagementNewForms/Inputs/InputCasuaDelPago';
import InputIBAN from 'components/blocks/ManagementNewForms/Inputs/InputIBAN';
import FormInputCuentaBancaria from 'components/blocks/ManagementNewForms/Inputs/FormInputCuentaBancaria';
import useGetInfoDirectPaymentFormIt from 'services/record/useGetInfoDirectPaymentFormIt';
import useGetDirectPaymentFormItByID from 'services/record/useGetDirectPaymentFormItByID';
import useUpdateDirectPaymentFormItByID from 'services/record/useUpdateDirectPaymentFormItByID';
import useCreateNewDirectPaymentFormIt from 'services/record/useCreateNewDirectPaymentFormIt';
import JSZip from 'jszip';
import ProgressBarBattery from 'components/ProgressBarBattery/ProgressBarBattery';
import InputArchivo from 'components/blocks/ManagementNewForms/Inputs/InputArchivo';
import { Button, Modal } from 'react-bootstrap';


export const FormularioPagoDirecto: React.FC<{ prestacionId: string, userId: string, formId: string, info?: boolean }> = ({ prestacionId, userId, formId, info }) => {

    const formMethods = useForm({
        defaultValues: {
            Acepto: false,
            DatosPersonales: {
                SolicitanteNombre: "",
                SolicitanteApellido1: "",
                SolicitanteApellido2: "",
                SolicitanteFechaNacimiento: "",
                SolicitanteProvincia: "",
                SolicitanteComunidadCodigo: "",
                SolicitanteIPF: "",
                SolicitanteIPFTipo: "",
                SolicitanteSeguridadSocial: "",
                SolicitanteEmail: "",
                SolicitanteTelefono: "",
                SolicitanteFijo: "",
                SolicitanteDomicilio: "",
                SolicitanteCodigoPostal: "",
                SolicitanteProvinciaDescripcion: "",
                SolicitanteComunidadCodigoDescripcion: "",
                IRPFVoluntario: "",
            },
            EntornoLaboral: {
                TipoContrato: "",
                TipoContratoDescripcion: "",
                RegimenSeguridadSocial: "",
                PrestacionRazonSocial: "",
                PrestacionCCC: "",
                PrestacionNAF: "",
                RegimenSeguridadSocialDescripcion: "",
            },
            CausaPagoDirecto: {
                Contingencias: "",
                CausaPago: "",
                PrestacionFechaBajaMedica: "",
                ContingenciasDescripcion: "",
                CausaPagoDescripcion: "",
            },
            DatosBancarios: {
                SolicitanteIBAN: "",
                SolicianteIBANIBAN: "",
                SolicitanteIBANEntidad: "",
                SolicitanteIBANSucursal: "",
                SolicitanteIBANDC: "",
                SolicitanteIBANNumeroCuenta: "",
            },
            Documentacion: [

            ],
        },
    });

    const { register, handleSubmit, setError, formState: { errors }, setValue, watch, reset, control } = formMethods;
    const { fields, append, prepend, remove, swap, move, insert } = useFieldArray({
        control,
        name: "Documentacion",
    });
    const MAX_SIZE_MB = 50;
    const [prodDesc, setProdDesc] = useState<any>(null);
    const [isAutonomo, setIsAutonomo] = useState<any>(null);
    const [entornosLaborales, setEntornosLaborales] = useState(null);
    const [entornoId, setEntornoId] = useState<number>(0);
    const [datosAPI, setDatosAPI] = useState(null);
    const [showProgressBar, setShowProgressBar] = useState(true);
    const [totalSize, setTotalSize] = useState(0);
    const [showModalErrorDocumentWeightLimit, setshowModalErrorDocumentWeightLimit] = useState(false)
    const history = useHistory()
    const watchProvincia = watch("DatosPersonales.SolicitanteProvincia");
    const watchProvinciaDesc = watch("DatosPersonales.SolicitanteProvinciaDescripcion");
    const watchComm = watch("DatosPersonales.SolicitanteComunidadCodigo");
    const watchCommDesc = watch("DatosPersonales.SolicitanteComunidadCodigoDescripcion");
    const watchWorkerContractCode = watch("EntornoLaboral.TipoContrato");

    //METODO PARA ADAPTAR LA RESPUESTA DE LA API AL FORMULARIO
    const adaptApiResponseToDefaultValues = (result) => {
        //console.log(result)
        if (result?.EntornosLaborales?.length > 0) {
            setEntornosLaborales(result.EntornosLaborales);
        }
        else {
            setEntornosLaborales(null)
        }

        reset({
            DatosPersonales: {
                SolicitanteNombre: result?.Nombre || "",
                SolicitanteApellido1: result?.Apellido1 || "",
                SolicitanteApellido2: result?.Apellido2 || "",
                SolicitanteFechaNacimiento: result?.FechaNacimiento || "",
                SolicitanteProvincia: result?.ProvinciaCodigo || "",
                SolicitanteComunidadCodigo: result?.LocalidadCodigo || "",
                SolicitanteIPF: result?.IPF || "",
                SolicitanteIPFTipo: result?.TipoIPF || "",
                SolicitanteSeguridadSocial: result?.NumeroSeguridadSocial || "",
                SolicitanteEmail: result?.Email || "",
                SolicitanteTelefono: result?.TelefonoMovil || "",
                SolicitanteFijo: result?.TelefonoFijo || "",
                SolicitanteDomicilio: result?.Domicilio || "",
                SolicitanteCodigoPostal: result?.CodigoPostal || "",
                IRPFVoluntario: result?.PorcentajeIRPFVoluntario || "",
            },
            EntornoLaboral: {
                TipoContrato: result?.TipoContratoCodigo || "",
                TipoContratoDescripcion: result?.TipoContratoDescripcion || "",
                RegimenSeguridadSocial: result?.RegimenSeguridadSocialCodigo || "",
                PrestacionRazonSocial: result?.RazonSocial || "",
                PrestacionCCC: result?.CodigoCuentaCotizacion || "",
            },
            CausaPagoDirecto: {
                Contingencias: result?.Contingencia || "",
                CausaPago: result?.CausaPagoDirecto || "",
                PrestacionFechaBajaMedica: result?.FechaBajaMedica || "",
            },
            DatosBancarios: {
                SolicitanteIBAN: result?.NumeroCuentaConIBAN?.replace(/-/g, '') || "",
            },
            Documentacion: !result?.EntornosLaborales ? result?.Documentacion.map((doc) => {
                return {
                    EntornoId: dataUser?.EntornoLaboralId,
                    DocumentacionPagoDirectoId: doc?.DocumentacionPagoDirectoId,
                    Id: doc?.Id,
                    TipoDocumento: doc?.TipoDocumento,
                    Uri: doc?.Uri,
                    SasUri: doc?.SasUri,
                    NombrePortal: doc?.NombrePortal,
                    Extension: doc?.Extension,
                    Nota: doc?.Nota,
                    FechaPresentacion: doc?.FechaPresentacion,
                };
            })
                :
                [],
        });
    };


    const [aceptarPagoDir, setAceptarPagoDir] = useState(true);
    const AceptarSolicitud = () => {
        setAceptarPagoDir(!aceptarPagoDir);
    }


    const [loader, setLoader] = useState(false);
    const [dataUser, setDataUser] = useState(null);
    const { getInfoDirectPaymentFormIt } = useGetInfoDirectPaymentFormIt()
    const { getSolicitudPagoDirectoById } = useGetDirectPaymentFormItByID();
    const { updateSolicitudPagoDirectoById,
        loadingUpdateSolicitudPagoDirectoById,
        successUpdateSolicitudPagoDirectoById,
        errorUpdateSolicitudPagoDirectoById,
        dataUpdateSolicitudPagoDirectoById } = useUpdateDirectPaymentFormItByID();
    const { createDirectPaymentFormIt,
        dataDirectPaymentFormIt,
        loadingDirectPaymentFormIt,
        successDirectPaymentFormIt,
        errorDirectPaymentFormIt } = useCreateNewDirectPaymentFormIt();

    //////////////////// SABER SI ES HISTORIAL O NO Y OBTENER LOS DATOS ////////////
    useEffect(() => {
        const fetchData = async () => {
            setTotalSize(0);
            setLoader(true);
            if (userId != "") {
                try {
                    if (info === true) {
                        let result = await getInfoDirectPaymentFormIt(userId);
                        if (result) {
                            adaptApiResponseToDefaultValues(result)
                            setDatosAPI(result)
                            //console.log(result)
                        }
                    }
                    else {
                        let result = await getSolicitudPagoDirectoById(userId, prestacionId, formId);
                        if (result) {
                            adaptApiResponseToDefaultValues(result)
                            setDatosAPI(result)
                        }

                    }

                } catch (error) {
                    console.error("Error al obtener los datos:", error);
                } finally {
                    setLoader(false);
                }
            }

        };

        fetchData(); // Llamar a la función asincrónica
    }, [userId, prestacionId, formId, info]);


    const handleEntornoLaboralChange = (e: any) => {
        //console.log(e.target.value)
        setEntornoId(e.target.value);
        if (e.target.value == 0) {
            reset({
                EntornoLaboral: {
                    TipoContrato: "",
                    TipoContratoDescripcion: "",
                    RegimenSeguridadSocial: "",
                    PrestacionRazonSocial: "",
                    PrestacionCCC: "",
                    PrestacionNAF: "",
                },
            })
        } else {
            let entorno = entornosLaborales.find((entorno: any) => entorno.EntornoLaboralEmpresaId == e.target.value);
            reset({
                EntornoLaboral: {
                    TipoContrato: entorno?.TipoContratoCodigo,
                    TipoContratoDescripcion: entorno.TipoContratoDescripcion,
                    RegimenSeguridadSocial: entorno.RegimenSeguridadSocialCodigo,
                    PrestacionRazonSocial: entorno.Nombre,
                    PrestacionCCC: entorno.CCC,
                    PrestacionNAF: entorno.NAF,
                },
                Documentacion: entorno?.Documentacion.map((doc) => {
                    return {
                        EntornoId: dataUser?.EntornoLaboralId,
                        Id: doc?.Id,
                        TipoDocumento: doc?.TipoDocumento,
                        Uri: doc?.Uri,
                        SasUri: doc?.SasUri,
                        NombrePortal: doc?.NombrePortal,
                        Extension: doc?.Extension,
                        Nota: doc?.Nota,
                        FechaPresentacion: doc?.FechaPresentacion,
                    };
                })
            })

            reset({
                EntornoLaboral: {
                    TipoContrato: entorno?.TipoContratoCodigo,
                    TipoContratoDescripcion: entorno.TipoContratoDescripcion,
                    RegimenSeguridadSocial: entorno.RegimenSeguridadSocialCodigo,
                    PrestacionRazonSocial: entorno.Nombre,
                    PrestacionCCC: entorno.CCC,
                    PrestacionNAF: entorno.NAF,
                },
            })
        }

    };


    const createZipFile = async (files: File[]): Promise<string> => {
        const zip = new JSZip();

        for (const file of files) {
            const base64 = await convertFileToBase64(file);
            if (base64) {
                zip.file(file.name, base64, { base64: true });
            } else {
                console.error(`El archivo ${file.name} está vacío o no se pudo leer.`);
            }
        }

        const zipContent = await zip.generateAsync({ type: "base64" });
        return zipContent;
    };

    const convertFileToBase64 = async (file: File): Promise<string | null> => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                if (reader.result) {
                    const result = reader.result as string;
                    const base64Data = result.split(',')[1];
                    resolve(base64Data && base64Data);
                } else {

                    resolve(null);
                }
            };
            reader.onerror = (error) => {
                console.error(`Error al leer el archivo ${file.name}:`, error);
                reject(error);
            };
        });
    };


    const [deshabilitado, setDeshabilitado] = useState(false);
    useEffect(() => {
        if (totalSize > 50) {
            setshowModalErrorDocumentWeightLimit(true)
            setDeshabilitado(true)
        }
        else {
            setDeshabilitado(false)
        }

    }, [totalSize]);


    const handleCloseModalErrorDocumentWeightLimit = () => {
        setshowModalErrorDocumentWeightLimit(false)
    }




    //////////////////////////// ON SUBMIT ////////////////////////
    const onSubmit = async (data: any) => {

        setLoader(true);
        //console.log("Datos Formulario", data)
        const documentosEnviados: any = await Promise.all(data.Documentacion.filter((archivo) => archivo).map(async (Ar) => {
           // console.log("ARCHIVO", Ar)
            if (Ar?.file === null) return null;
            const base64 = Ar?.file?.length > 1
                ? await createZipFile(Ar?.file)
                : await convertFileToBase64(Ar?.file[0]);

            return {
                id: Ar.id,
                tipoDocumento: Ar.tipo,
                nombrePortal: Ar.nombrePortal,
                documentacionPagoDirectoId: Ar?.DocumentacionPagoDirectoId,
                nombre: Ar?.file[0]?.name,
                extension: Ar?.file.length > 1 ? 'zip' : Ar?.file[0]?.name.split('.').pop(),
                base64: base64,
            };
        }));
        //console.log("DATOSAPI",datosAPI)
        //console.log(entornoId)
        const datosEnvio = {
            Id: formId || 0,
            UsuarioId: userId || 0,
            PersonaId: datosAPI?.PersonaId || 0,
            EntornoLaboralId: info ? parseInt(entornoId as any) : datosAPI?.EntornoLaboralId || 0,
            ExpedienteId: datosAPI?.ExpedienteId || 0,
            AceptacionSolicitud: data?.Acepto || false,
            Nombre: data?.DatosPersonales?.SolicitanteNombre || "",
            Apellido1: data?.DatosPersonales?.SolicitanteApellido1 || "",
            Apellido2: data?.DatosPersonales?.SolicitanteApellido2 || "",
            IPF: data?.DatosPersonales?.SolicitanteIPF || "",
            TipoIPF: parseInt(data?.DatosPersonales?.SolicitanteIPFTipo) || 0,
            FechaNacimiento: data?.DatosPersonales?.SolicitanteFechaNacimiento || "",
            TelefonoFijo: data?.DatosPersonales?.SolicitanteFijo || "",
            TelefonoMovil: data?.DatosPersonales?.SolicitanteTelefono || "",
            Email: data?.DatosPersonales?.SolicitanteEmail || "",
            NumeroSeguridadSocial: data?.DatosPersonales?.SolicitanteSeguridadSocial || "",
            PorcentajeIRPFVoluntario: typeof data?.DatosPersonales?.IRPFVoluntario === "string"
                ? parseFloat(data?.DatosPersonales?.IRPFVoluntario.replace(/,/g, '.')) || 0
                : parseFloat(data?.DatosPersonales?.IRPFVoluntario) || 0,
            Domicilio: data?.DatosPersonales?.SolicitanteDomicilio || "",
            CodigoPostal: data?.DatosPersonales?.SolicitanteCodigoPostal || "",
            LocalidadCodigo: data?.DatosPersonales?.SolicitanteComunidadCodigo || "",
            ProvinciaCodigo: data?.DatosPersonales?.SolicitanteProvincia || "",
            RazonSocial: data?.EntornoLaboral?.PrestacionRazonSocial || "",
            CodigoCuentaCotizacion: data?.EntornoLaboral?.PrestacionCCC || "",
            RegimenSeguridadSocialCodigo: data?.EntornoLaboral?.RegimenSeguridadSocial || 0,
            TipoContratoCodigo: data?.EntornoLaboral?.TipoContrato || "",
            TipoContratoDescripcion: data?.EntornoLaboral?.TipoContratoDescripcion || "",
            FechaBajaMedica: data?.CausaPagoDirecto?.PrestacionFechaBajaMedica || "",
            Contingencia: parseInt(data?.CausaPagoDirecto?.Contingencias) || 0,
            CausaPagoDirecto: parseInt(data?.CausaPagoDirecto?.CausaPago) || 0,
            NumeroCuentaConIBAN: data?.DatosBancarios?.SolicitanteIBAN?.replace(/(.{4})(.{4})(.{4})(.{2})/, '$1-$2-$3-$4-') || "",
            documentacion: documentosEnviados
        };

        // console.log("Enviar Datos", datosEnvio);
        //console.log("Informacion", info)
        // console.log("DatosAPI", datosAPI)
        if (info) {
           createDirectPaymentFormIt(userId, datosAPI.ExpedienteId, datosEnvio);
        } else {
            updateSolicitudPagoDirectoById(userId, prestacionId, formId, datosEnvio);
        }
        setLoader(false);
    };


    return (
        <>

            <Loader activeLoader={loader || loadingDirectPaymentFormIt || loadingUpdateSolicitudPagoDirectoById} label="Cargando Formulario"></Loader>


            <FormProvider {...formMethods}>
                <div className="container form--simple-styled" id="formDeltawithoutleave">
                    <div className="row">
                        <div className="col-xl-12">

                            {/* ////////////// ACEPTACION DE LA DECLARACIÓN //////////// */}

                            <div className="row mb-4">
                                <div className="col-xl-12">
                                    <FieldsetContainer legend="" disabled={false}>
                                        <div className='paragraph text-info mb-3 col-12'>Solicito el inicio del Pago Directo por Incapacidad Temporal, y por tanto declaro bajo mi responsabilidad que son ciertos todos los datos que consigno en dicha solicitud y quedo enterado de la obligación de comunicar a la Mutua cualquier variación de los datos en ella expresados, que pudieran producirse durante la percepción de la prestación de Incapacidad Temporal.
                                        </div>
                                        <div className='ml-5 form-check mb-4 col-12'>
                                            <input className='form-check-input' type='checkbox' id='Acepto' {...register('Acepto', { onChange: AceptarSolicitud })} />
                                            <label className='form-check-label ' htmlFor='Acepto'>Acepto la información declarada.</label>
                                        </div>
                                        <div className=' mb-4 col-12' style={{ display: aceptarPagoDir ? 'none' : 'block' }}>
                                            <div className='paragraph text-info col-12'>
                                                {"Aceptado el: " + new Intl.DateTimeFormat('es-ES', { year: 'numeric', month: 'long', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit' }).format(new Date())}
                                            </div>
                                        </div>
                                    </FieldsetContainer>
                                </div>
                            </div>






                            {/* ////////////// DATOS PERSONALES //////////// */}
                            <AccordionForm title={'Datos Personales'} expandedInit={false} nombreError='DatosPersonales' >
                                <div className="row mb-4">
                                    <div className="col-xl-12">

                                        <FieldsetContainer legend="Datos de la empresa" disabled={false}>
                                            <FormInputText
                                                name='DatosPersonales.SolicitanteNombre'
                                                id='DatosPersonales.SolicitanteNombre'
                                                label='Nombre'
                                                md={6}
                                                xl={6}
                                                disabled={true}

                                            />
                                            <FormInputText
                                                name='DatosPersonales.SolicitanteApellido1'
                                                id='DatosPersonales.SolicitanteApellido1'
                                                label='Apellido 1'
                                                md={6}
                                                xl={6}
                                                disabled={true}
                                            />
                                            <FormInputText
                                                name='DatosPersonales.SolicitanteApellido2'
                                                id='DatosPersonales.SolicitanteApellido2'
                                                label='Apellido 2'
                                                md={6}
                                                xl={6}
                                                disabled={true}
                                            />
                                            <FormInputIPF
                                                name={'DatosPersonales.SolicitanteIPF'}
                                                id={'DatosPersonales.SolicitanteIPF'}
                                                label={'IPF (Identificación Personal Fisica)'} placeholder={''}
                                                req='El IPF es Obligatorio'
                                                md={6}
                                                xl={6}
                                                disabled={true}
                                            />
                                            <FormInputText
                                                name='DatosPersonales.SolicitanteSeguridadSocial'
                                                id='DatosPersonales.SolicitanteSeguridadSocial'
                                                label='Nº Seguridad Social'
                                                md={6}
                                                xl={6}
                                                pattern={/^[0-9]+$/}
                                                patternMessage='Solo se aceptan valores numéricos'
                                                disabled={datosAPI?.NumeroSeguridadSocial ? true : false}
                                            />
                                            <FormInputText
                                                name='DatosPersonales.SolicitanteEmail'
                                                id='DatosPersonales.SolicitanteEmail'
                                                label='Email'
                                                req='El Email es Obligatorio'
                                                md={6}
                                                xl={6}
                                                pattern={/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/}
                                                patternMessage='El email no tiene un formato valido'
                                                disabled={datosAPI?.Email ? true : false}
                                            />
                                            <FormInputText
                                                name='DatosPersonales.SolicitanteTelefono'
                                                id='DatosPersonales.SolicitanteTelefono'
                                                label='Telefono Movil'
                                                req='El Telefono es Obligatorio'
                                                md={3}
                                                xl={3}
                                                disabled={datosAPI?.TelefonoMovil ? true : false}
                                            />
                                            <FormInputText
                                                name='DatosPersonales.SolicitanteFijo'
                                                id='DatosPersonales.SolicitanteFijo'
                                                label='Teléfono Fijo'
                                                req='El Fijo es Obligatorio'
                                                md={3}
                                                xl={3}
                                                disabled={datosAPI?.TelefonoFijo ? true : false}
                                            />
                                            <FormInputFecha
                                                name={'DatosPersonales.SolicitanteFechaNacimiento'}
                                                id={'DatosPersonales.SolicitanteFechaNacimiento'}
                                                label={'Fecha de Nacimiento'} placeholder={''} md={6} xl={6} col={12}
                                                req='La Fecha de Nacimiento es Obligatorio'
                                                maxDate={new Date(Date.now())}
                                                disableDate={true}
                                            />
                                            <FormInputText
                                                name='DatosPersonales.SolicitanteDomicilio'
                                                id='DatosPersonales.SolicitanteDomicilio'
                                                label='Domicilio (Calle/Plaza/Avenida/...)'
                                                req='El Domicilio es Obligatorio'
                                                md={6}
                                                xl={6}
                                                disabled={datosAPI?.Domicilio ? true : false}
                                            />
                                            <FormInputText
                                                name='DatosPersonales.SolicitanteCodigoPostal'
                                                id='DatosPersonales.SolicitanteCodigoPostal'
                                                label='Código Postal'
                                                req='El Código Postal es Obligatorio'
                                                md={6}
                                                xl={6}
                                                maxLength={5}
                                                pattern={/^[0-9]+$/}
                                                patternMessage='Solo se aceptan valores numéricos'
                                                disabled={datosAPI?.CodigoPostal ? true : false}
                                            />
                                            <div className="col-xl-6 col-md-6">
                                                <Province
                                                    errors={errors}
                                                    register={register}
                                                    idCode={'DatosPersonales.SolicitanteProvincia'}
                                                    watchProvinceDescription={watchProvinciaDesc}
                                                    idDescription={'DatosPersonales.SolicitanteProvinciaDescripcion'}
                                                    watchProvinceCode={watchProvincia}
                                                    setValue={setValue}
                                                    disabled={datosAPI?.ProvinciaCodigo ? true : false}
                                                />
                                            </div>
                                            <div className="col-xl-6 col-md-6">
                                                <TestCommunity
                                                    errors={errors}
                                                    register={register}
                                                    setValue={setValue}
                                                    watchProvinceCode={watchProvincia}
                                                    watchCommunityCode={watchComm}
                                                    watchCommunityDescription={watchCommDesc}
                                                    idCode={'DatosPersonales.SolicitanteComunidadCodigo'}
                                                    idDescription={'DatosPersonales.SolicitanteComunidadCodigoDescripcion'}
                                                    filterByProvince={true}
                                                    disabled={datosAPI?.LocalidadCodigo ? true : false}
                                                />
                                            </div>
                                            <FormInputText
                                                name='DatosPersonales.IRPFVoluntario'
                                                id='DatosPersonales.IRPFVoluntario'
                                                label='Tipo IRPF Voluntario  (%)'
                                                md={12}
                                                xl={12}
                                                maxLength={5}
                                                placeholder='00,00'
                                                pattern={/^\d{1,5}([.,]\d{1,2})?$/}
                                                patternMessage='Solo se aceptan valores numéricos y decimales'
                                            />

                                        </FieldsetContainer>

                                    </div>
                                </div>

                            </AccordionForm>



                            {/* ////////////// ENTORNOS LABORALES //////////// */}
                            <AccordionForm title={'Entornos Laborales'} expandedInit={false} nombreError='EntornoLaboral' >
                                <div className="row mb-4">
                                    <div className="col-xl-12">
                                        {
                                            entornosLaborales ?
                                                <select name="entornoLaboral" id="entornoLaboral" className="form-select" aria-label="Default select example" value={entornoId} onChange={handleEntornoLaboralChange}>
                                                    <option value="0">Selecciona un Entorno Laboral</option>
                                                    {entornosLaborales &&
                                                        entornosLaborales.map((entorno) => (
                                                            <option
                                                                key={entorno.EntornoLaboralEmpresaId}
                                                                value={entorno.EntornoLaboralEmpresaId}
                                                            >
                                                                {entorno.Nombre}
                                                            </option>
                                                        ))}
                                                </select>

                                                : ""
                                        }
                                        <FieldsetContainer legend="Entornos Laborales" disabled={false}>
                                            <FormInputText
                                                name='EntornoLaboral.PrestacionRazonSocial'
                                                id='EntornoLaboral.PrestacionRazonSocial'
                                                label='Nombre o razón social de la empresa'
                                                md={entornosLaborales ? 4 : 6}
                                                xl={6}
                                                req='El Nombre de la Empresa es Obligatorio'
                                            />
                                            <FormInputText
                                                name='EntornoLaboral.PrestacionCCC'
                                                id='EntornoLaboral.PrestacionCCC'
                                                label='Código Cuenta Cotización de la empresa'
                                                md={entornosLaborales ? 4 : 6}
                                                xl={6}

                                            />
                                            <FormInputText
                                                name='EntornoLaboral.PrestacionNAF'
                                                id='EntornoLaboral.PrestacionNAF'
                                                label='Número de Afiliación a la Seguridad Social (NAF)'
                                                md={entornosLaborales ? 4 : 6}
                                                xl={6}

                                                invisible={entornosLaborales ? false : true}
                                            />
                                            <div className="col-12 col-md-12" style={{ display: info ? isAutonomo ? 'none' : 'block' : 'block' }}>
                                                <AgreementType
                                                    errors={errors}
                                                    register={register}
                                                    setValue={setValue}
                                                    watchWorkerContractCode={watchWorkerContractCode}
                                                    idCode="EntornoLaboral.TipoContrato"
                                                    idDescription="EntornoLaboral.TipoContratoDescripcion"
                                                    req='Falta especificar el tipo de contrato'
                                                />
                                            </div>
                                            <InputRegimenFormularios
                                                name={'EntornoLaboral.RegimenSeguridadSocial'}
                                                md={12}
                                                xl={12}
                                                isAutonomo={setIsAutonomo}
                                            />
                                        </FieldsetContainer>

                                    </div>
                                </div>
                            </AccordionForm>
                        </div>
                    </div>





                    {/* ////////////// CAUSA DEL PAGO //////////// */}
                    <AccordionForm title={'Causa del Pago Directo'} expandedInit={false} nombreError='CausaPagoDirecto' >
                        <div className="row mb-4">
                            <div className="col-xl-12">
                                <FieldsetContainer legend="Causa del Pago Directo" disabled={false}>

                                    <FormInputFecha
                                        name="CausaPagoDirecto.PrestacionFechaBajaMedica"
                                        id="CausaPagoDirecto.PrestacionFechaBajaMedica"
                                        label={'Fecha de Baja Médica'}
                                        placeholder={''}
                                        md={12}
                                        xl={12}
                                        col={12}
                                        req='La Fecha de Baja es Obligatoria'
                                        maxDate={new Date(Date.now())}
                                    />

                                    <InputContigenciasPagoDirecto
                                        name={'CausaPagoDirecto.Contingencias'}
                                        md={12}
                                        xl={12}
                                        col={12}
                                        required='La Contingencia es Obligatoria'
                                    />
                                    <InputCausaDelPago
                                        name='CausaPagoDirecto.CausaPago'
                                        md={12}
                                        xl={12}
                                        col={12}
                                        required='La Causa del Pago Directo es Obligatoria'

                                    />
                                </FieldsetContainer>
                            </div>
                        </div>
                    </AccordionForm>



                    {/* ////////////// DATOS BANCARIOS //////////// */}
                    <AccordionForm title={'Datos Bancarios'} expandedInit={false} nombreError='DatosBancarios' >
                        <div className="row mb-4">
                            <div className="col-xl-12">
                                <FieldsetContainer legend="Datos Bancarios" disabled={false}>

                                    <FormInputCuentaBancaria
                                        id='DatosBancarios.SolicitanteIBAN'
                                        name='DatosBancarios.SolicitanteIBAN'
                                        xl={12}
                                        md={12}
                                        placeholder=''
                                        req='Falta el Número de Cuenta Bancaria' />
                                </FieldsetContainer>
                            </div>
                        </div>
                    </AccordionForm>



                    {/* ////////////// ARCHIVOS A ADJUNTAR //////////// */}
                    <AccordionForm title={'Archivos a Adjuntar'} expandedInit={false} nombreError='Documentacion' >
                        <div className="row mb-4">
                            <div className="col-xl-12">
                                {fields.map((field, index) => (
                                    <div key={field.id}>
                                        <InputArchivo
                                            name={`Documentacion.${index}`}
                                            id={`Documentacion.${index}`}
                                            label={''}
                                            xl={12}
                                            md={12}
                                            placeholder={''}
                                            setSize={setTotalSize}
                                            totalSize={totalSize}
                                            doc={fields[index]} // Pasas todo el documento
                                            {...field} // Conecta el estado del formulario al campo "archivo"
                                        />
                                    </div>
                                ))}
                                <ProgressBarBattery totalSize={totalSize} maxSize={MAX_SIZE_MB} showProgressBar={showProgressBar}></ProgressBarBattery>

                            </div>
                        </div>
                    </AccordionForm>




                    <div className='paragraph small mt-4'>EL USUARIO DEBE SABER QUE TIENE QUE RELLENAR EL MODELO 145 Y LA DECLARACION DE SITUACIÓN DE ACTIVIDAD EN ESTE PÁRRAFO PARA COMPLETAR Y QUE SEA VALIDO EL FORMULARIO. ES SOLO TEXTO DESCRIPTIVO Y NO HAY VALIDACIÓN SI LO RELLENÓ O NO</div>



                    {/* BOTON DE ENVIAR */}
                    <button className='btn-box btn-accent mt-3 mb-3' type="submit" disabled={aceptarPagoDir || deshabilitado} onClick={handleSubmit(onSubmit)}>
                        {info ? "CREAR FORMULARIO" : "ACTUALIZAR FORMULARIO"}
                    </button>
                    {
                        successDirectPaymentFormIt || successUpdateSolicitudPagoDirectoById ? <div className='paragraph text-success mb-4'>FORMULARIO ENVIADO CORRECTAMENTE</div> : ''
                    }



                    <div className='paragraph small text-secondary  mb-4'>En cumplimiento de lo establecido en la normativa sobre de Protección de Datos de Carácter Personal, Solimat Mutua Colaboradora con la Seguridad Social nº 72 le informa que sus datos de
                        carácter personal serán objeto de tratamiento, con la finalidad del mantenimiento y cumplimiento de la relación. La base jurídica que legitima el tratamiento de sus datos es el
                        cumplimiento de una obligación legal. Sus datos serán conservados de forma indefinida en nuestra entidad para atender a las posibles responsabilidades derivadas del tratamiento. Usted
                        podrá revocar el consentimiento prestado, así como ejercitar sus derechos de acceso, rectificación, supresión, oposición, limitación del tratamiento, o portabilidad, en su caso,
                        remitiendo un escrito a la siguiente dirección: Solimat, Mutua Colaboradora con la Seguridad Social nº 72, C/ Berna, 1, 45003, Toledo, adjuntando su NIF. Si considera que sus
                        derechos no han sido debidamente atendidos, puede contactar con nuestro delegado de protección de datos lopd@solimat.com o interponer una reclamación frente a la Agencia
                        Española de Protección de Datos.</div>

                    <div className='paragraph small text-secondary  mb-4'>En el caso de que no haya presentado toda la documentación que le hemos solicitado, dispone de un plazo de diez días para presentarla. Transcurrido dicho plazo, si no lo aporta, se le
                        tendrá por desistido, de acuerdo con lo previsto en el art. 68 de la Ley 39/2015, de 1 de octubre, del Procedimiento Administrativo Común de las Administraciones Públicas. (B.O.E. 2 de
                        octubre de 2015).</div>

                    <Modal show={showModalErrorDocumentWeightLimit} onHide={handleCloseModalErrorDocumentWeightLimit} centered>
                        <Modal.Header closeButton={false} className='header-modal-observaciones-rd'>
                            <span className='information-icon-orange big'></span>
                            <Modal.Title>

                                <h2 className='title-obsevaciones-rd'> Advertencia </h2>


                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body className='body-modal-observaciones-rd'>
                            <p className='text-body-modal-observaciones-rd'>La suma total de los archivos a enviar excede el límite de 50MB. Proceda a adjuntarlos en diferentes envios </p>

                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={handleCloseModalErrorDocumentWeightLimit}>
                                Cerrar
                            </Button>
                        </Modal.Footer>
                    </Modal>





                </div>


            </FormProvider>

        </>
    )
}

export default FormularioPagoDirecto;
