import React, { useState } from 'react';
import { record } from "@solimat/solimat-web-endpoint";

const useCreatetModelo145It = () => {
  const [loadingModelo145It, setLoadingCreateSolicitudPagoDirecto] = useState(false);
  const [successModelo145It, setSuccessCreateSolicitudPagoDirecto] = useState(false);
  const [errorModelo145It, setErrorCreateSolicitudPagoDirecto] = useState(null);
  const [dataModelo145It, setDataSolicitudPagoDirecto] = useState(null);

  const BASE_URL = process.env.REACT_APP_PRESTACIONES_PACIENTE_PATH || "";
  const FUNCTIONS_KEY = process.env.REACT_APP_PRESTACIONES_PACIENTE_KEY || "";
  const apiRecord = new record.RecordService(
    BASE_URL,
    FUNCTIONS_KEY
  )



  //CREATE
  const createModelo145It = async (userId:string ,prestacionId: string, data: any ,_cb?:(data:any)=>void) => {
    setLoadingCreateSolicitudPagoDirecto(true);
    setSuccessCreateSolicitudPagoDirecto(false);
    setErrorCreateSolicitudPagoDirecto(null);

    apiRecord.newModelo145It(userId, prestacionId, data).then((res: any) => {
      setLoadingCreateSolicitudPagoDirecto(false);
      setSuccessCreateSolicitudPagoDirecto(true);
      setDataSolicitudPagoDirecto(res.data);
      if (typeof _cb === "function") {
        _cb(res.data);
      }

    }).catch((err:any) => {
      console.log(err)
      setLoadingCreateSolicitudPagoDirecto(false);
      setErrorCreateSolicitudPagoDirecto(err.response.data)
    })
  }




  return {
    createModelo145It,
    loadingModelo145It,
    successModelo145It,
    errorModelo145It,
    dataModelo145It
  }

}

export default useCreatetModelo145It;
