import React, { useState } from 'react';
import { record } from "@solimat/solimat-web-endpoint";

const useGetInfoDirectPaymentFormIt = () => {
  const [loadingGetInfoDirectPaymentFormIt, setLoadingGetInfoDirectPaymentFormIt] = useState(false);
  const [successGetInfoDirectPaymentFormIt, setSuccessGetInfoDirectPaymentFormIt] = useState(false);
  const [errorGetInfoDirectPaymentFormIt, setErrorGetInfoDirectPaymentFormIt] = useState(null);
  const [dataGetInfoDirectPaymentFormIt, setDataGetInfoDirectPaymentFormIt] = useState(null);

  const BASE_URL = process.env.REACT_APP_PRESTACIONES_PACIENTE_PATH || "";
  const FUNCTIONS_KEY = process.env.REACT_APP_PRESTACIONES_PACIENTE_KEY || "";
  const apiRecord = new record.RecordService(BASE_URL, FUNCTIONS_KEY);

  const getInfoDirectPaymentFormIt = async (userId, _cb?: (data: any) => void) => {
    setLoadingGetInfoDirectPaymentFormIt(true);
    setSuccessGetInfoDirectPaymentFormIt(false);
    setErrorGetInfoDirectPaymentFormIt(null);

    try {
      const res = await apiRecord.getInfoDirectPaymentFormIt(userId);
      setLoadingGetInfoDirectPaymentFormIt(false);
      setSuccessGetInfoDirectPaymentFormIt(true);
      setDataGetInfoDirectPaymentFormIt(res.data);
      if (typeof _cb === "function") {
        _cb(res.data);
      }
      return res.data; // Retorna los datos correctamente
    } catch (err) {
      setLoadingGetInfoDirectPaymentFormIt(false);
      setErrorGetInfoDirectPaymentFormIt(err);
      console.error("Error en getInfoDirectPaymentFormIt:", err);
      throw err;
    }
  };

  return {
    getInfoDirectPaymentFormIt,
    loadingGetInfoDirectPaymentFormIt,
    successGetInfoDirectPaymentFormIt,
    errorGetInfoDirectPaymentFormIt,
    dataGetInfoDirectPaymentFormIt
  };
};

export default useGetInfoDirectPaymentFormIt;