import { getValue, setValue } from '@syncfusion/ej2-base';
import FieldsetContainer from 'components/blocks/Delta/Inputs/Containers/FieldsetContainer';
import { watch } from 'fs';
import React, { useEffect, useState } from 'react';
import { FieldValues, RefCallBack, UseFormRegister, set, useForm, useFormContext,useWatch } from 'react-hook-form';
import { validate } from 'uuid';
import get from 'lodash/get';
interface IInputProps {
    name: string,
    id: string,
    placeholder: string,
    readonly?: boolean,
    disabled?: boolean
    req?: string
    xl?: number
    md?: number

}

const FormInputCuentaBancaria: React.FC<IInputProps> = ({ name, id, placeholder, readonly, disabled, req, xl, md }) => {

    const { trigger, setValue, register, formState: {errors}, getValues} = useFormContext();
    const errorMessage = get(errors, name)?.message;
    ////////// PARTES DEL NUMERO DE CUENTA BANCARIA ///////////
    const [numeroIBAN, setNumeroIBAN] = useState<string>('');
    const [entidad, setEntidad] = useState<string>('');
    const [sucursal, setSucursal] = useState<string>('');
    const [numeroDC, setNumeroDC] = useState<string>('');
    const [numeroCuenta, setNumeroCuenta] = useState<string>('');

       // Estado para controlar la primera carga
    const [isFirstLoad, setIsFirstLoad] = useState<boolean>(true);

    const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const valor = e.target.value;
        const longitud = valor.length;
        const ultimoCaracter = e.target.value.slice(-1);
        e.target.value = e.target.value.toUpperCase();

        if (longitud <= 2 && !/^[a-zA-Z]$/.test(ultimoCaracter)) {
            e.target.value = valor.slice(0, -1);

        } else if (longitud > 2 && longitud <= 4) {
            if (!/^[0-9]$/.test(ultimoCaracter)) {
                e.target.value = valor.slice(0, -1);

            }
        }
        setNumeroIBAN(e.target.value)
    }

    const handleSoloNumerosEntidad = (e: React.ChangeEvent<HTMLInputElement>) => {
        const valor = e.target.value;
        const ultimoCaracter = e.target.value.slice(-1);
        if (!/^[0-9]$/.test(ultimoCaracter)) {
            e.target.value = valor.slice(0, -1);

        };
        setEntidad(e.target.value)
    }

    const handleSoloNumerosSucursal = (e: React.ChangeEvent<HTMLInputElement>) => {
        const valor = e.target.value;
        const ultimoCaracter = e.target.value.slice(-1);
        if (!/^[0-9]$/.test(ultimoCaracter)) {
            e.target.value = valor.slice(0, -1);

        };
        setSucursal(e.target.value)
    }
    const handleSoloNumerosDC = (e: React.ChangeEvent<HTMLInputElement>) => {
        const valor = e.target.value;
        const ultimoCaracter = e.target.value.slice(-1);
        if (!/^[0-9]$/.test(ultimoCaracter)) {
            e.target.value = valor.slice(0, -1);

        };
        setNumeroDC(e.target.value)
    }

    const handleSoloNumerosCuentaBancaria = (e: React.ChangeEvent<HTMLInputElement>) => {
        const valor = e.target.value;
        const ultimoCaracter = e.target.value.slice(-1);
        if (!/^[0-9]$/.test(ultimoCaracter)) {
            e.target.value = valor.slice(0, -1);

        };
        setNumeroCuenta(e.target.value)
    }

    const CompletarNumeroCuenta = () => {

        setValue(name, numeroIBAN + entidad + sucursal + numeroDC + numeroCuenta)
        trigger(name)

    }

    useEffect(() => {
        if (!isFirstLoad) {
            CompletarNumeroCuenta();
        } else {
            setIsFirstLoad(false); 
        }
    }, [numeroIBAN, entidad, sucursal, numeroDC, numeroCuenta]);

    // useEffect(() => {
    //     if ( getValues(name) !== null &&  getValues(name) !== undefined &&  getValues(name) !== "") {

    //         //SE VEN LOS VALORES
    //         setValue(name + "Entidad", getValues(name).substring(4, 8));
    //         setValue(name + "Sucursal",  getValues(name).substring(8, 12));
    //         setValue(name + "DC",  getValues(name).substring(12, 14));
    //         setValue(name + "NumeroCuenta",  getValues(name).substring(14, 24));
    //         setValue(name + "IBAN",  getValues(name).substring(0, 4));

    //         //SE COMPLETA EL NUMERO DE CUENTA Y SE VALIDA
    //         setNumeroIBAN( getValues(name).substring(0, 4));
    //         setEntidad( getValues(name).substring(4, 8));
    //         setSucursal( getValues(name).substring(8, 12));
    //         setNumeroDC( getValues(name).substring(12, 14));
    //         setNumeroCuenta( getValues(name).substring(14, 24));



    //     }
    // }, [])

    const values = useWatch({ name: name });

    useEffect(() => {
        if ( getValues(name) !== null &&  getValues(name) !== undefined &&  getValues(name) !== "") {

            //SE VEN LOS VALORES
            setValue(name + "Entidad", getValues(name).substring(4, 8));
            setValue(name + "Sucursal",  getValues(name).substring(8, 12));
            setValue(name + "DC",  getValues(name).substring(12, 14));
            setValue(name + "NumeroCuenta",  getValues(name).substring(14, 24));
            setValue(name + "IBAN",  getValues(name).substring(0, 4));

            //SE COMPLETA EL NUMERO DE CUENTA Y SE VALIDA
            setNumeroIBAN( getValues(name).substring(0, 4));
            setEntidad( getValues(name).substring(4, 8));
            setSucursal( getValues(name).substring(8, 12));
            setNumeroDC( getValues(name).substring(12, 14));
            setNumeroCuenta( getValues(name).substring(14, 24));



        }
    }, [values])


    


    const Validacion = (value: string) => {
        const longitud = value.length
        if (longitud === 24) {
            if (!validateIban(value)) {

                return "El Número de cuenta bancaria no es correcto, revise todas sus partes"

            }
        }
    }

    const validateIban = (iban: string): boolean => {
        // Longitudes válidas de IBAN por país
        const ibanLengths: { [key: string]: number } = {
            'ES': 24,

            // Añade más países según sea necesario
        };

        const countryCode = iban.slice(0, 2);
        const length = ibanLengths[countryCode];

        // Verificar longitud
        if (!length || iban.length !== length) {
            return false;
        }

        // Reorganizar IBAN para la validación
        const rearrangedIban = iban.slice(4) + iban.slice(0, 4);
        const numericIban = rearrangedIban
            .split('')
            .map((char) => (isNaN(Number(char)) ? char.charCodeAt(0) - 55 : char))
            .join('');

        // Validación usando el módulo 97
        const remainder = BigInt(numericIban) % BigInt(97);
        return remainder === BigInt(1);
    };





    return (
        <>
            <div className="row mb-3">
                <div className="col-xl-12">
                    <FieldsetContainer legend="Número de Cuenta Bancaria" disabled={false}>


                        <div className={`col-xl-3 col-md-6`}>
                            <div className={`input-block ${errors && errors[name]?.message ? 'error' : ''}`}>
                                <label htmlFor={id} className="input-block__label">IBAN</label>
                                <input
                                    type="text"
                                    name={name}
                                    id={id}
                                    placeholder={placeholder}
                                    {...register(name + "IBAN")}
                                    readOnly={readonly || false}
                                    disabled={disabled || false}

                                    maxLength={4}
                                    onChange={handleOnChange}

                                />

                            </div>
                        </div>
                        {
                            //////////////////////////////////
                        }
                        <div className={`col-xl-3 col-md-6`}>
                            <div className={`input-block ${errors && errors[name]?.message ? 'error' : ''}`}>
                                <label htmlFor={id} className="input-block__label">Entidad</label>
                                <input
                                    type="text"
                                    name={name}
                                    id={id}
                                    placeholder={placeholder}
                                    {...register(name + "Entidad")}
                                    readOnly={readonly || false}
                                    disabled={disabled || false}

                                    maxLength={4}
                                    onChange={handleSoloNumerosEntidad}


                                />

                            </div>
                        </div>
                        {
                            //////////////////////////////////
                        }
                        <div className={`col-xl-3 col-md-6`}>
                            <div className={`input-block ${errors && errors[name]?.message ? 'error' : ''}`}>
                                <label htmlFor={id} className="input-block__label">Sucursal</label>
                                <input
                                    type="text"
                                    name={name}
                                    id={id}
                                    placeholder={placeholder}
                                    {...register(name + "Sucursal")}
                                    readOnly={readonly || false}
                                    disabled={disabled || false}
                                    onChange={handleSoloNumerosSucursal}
                                    maxLength={4}


                                />

                            </div>
                        </div>
                        {
                            //////////////////////////////////
                        }
                        <div className={`col-xl-3 col-md-6`}>
                            <div className={`input-block ${errors && errors[name]?.message ? 'error' : ''}`}>
                                <label htmlFor={id} className="input-block__label">D.C.</label>
                                <input
                                    type="text"
                                    name={name}
                                    id={id}
                                    placeholder={placeholder}
                                    {...register(name + "DC")}
                                    readOnly={readonly || false}
                                    disabled={disabled || false}
                                    onChange={handleSoloNumerosDC}
                                    maxLength={2}


                                />

                            </div>
                        </div>
                        {
                            //////////////////////////////////
                        }
                        <div className={`col-xl-12 col-md-12`}>
                            <div className={`input-block ${errors && errors[name]?.message ? 'error' : ''}`}>
                                <label htmlFor={id} className="input-block__label">Número de Cuenta</label>
                                <input
                                    type="text"
                                    name={name}
                                    id={id}
                                    placeholder={placeholder}
                                    {...register(name + "NumeroCuenta")}
                                    readOnly={readonly || false}
                                    disabled={disabled || false}
                                    onChange={handleSoloNumerosCuentaBancaria}
                                    maxLength={10}


                                />

                            </div>
                        </div>
                        {
                            //////////////////////////////////
                            //////////////////////////////////
                            //////////////////////////////////
                        }
                        <div className={`col-xl-12 col-md-12`} style={{ display: 'none' }}>
                            <div className={`input-block ${errors && errors[name]?.message ? 'error' : ''}`}>
                                <label htmlFor={id} className="input-block__label">Numero de Cuenta Completo</label>
                                <input
                                    type="text"
                                    name={name}
                                    id={id}
                                    placeholder={placeholder}
                                    {...register(name, { required: req, minLength: { value: 24, message: 'El Número de cuenta bancaria no tiene el formato adecuado' }, validate: Validacion })}
                                    readOnly={readonly || false}
                                    disabled={disabled || false}
                                  
                                    maxLength={24}


                                />

                            </div>
                        </div>
                    </FieldsetContainer>
                    {errorMessage && <p className="error m-0">{errorMessage}</p>}
                </div>
            </div>
        </>

    )
}

export default FormInputCuentaBancaria;

