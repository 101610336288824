import React, { useState } from 'react';
import { record } from "@solimat/solimat-web-endpoint";

const useGetModelo145ItByUser = () => {
  const [loadingGetModelo145ItByUser, setLoadingGetModelo145ItByUser] = useState(false);
  const [successGetModelo145ItByUser, setSuccessGetModelo145ItByUser] = useState(false);
  const [errorGetModelo145ItByUser, setErrorGetModelo145ItByUser] = useState(null);
  const [dataGetModelo145ItByUser, setDataModelo145ItByUser] = useState(null);

  const BASE_URL = process.env.REACT_APP_PRESTACIONES_PACIENTE_PATH || "";
  const FUNCTIONS_KEY = process.env.REACT_APP_PRESTACIONES_PACIENTE_KEY || "";
  const apirecord = new record.RecordService(
    BASE_URL,
    FUNCTIONS_KEY
  )

  
  //GET
  const getModelo145ItByUser = async (userId: string, _cb?:(data:any)=>void) => {
    setLoadingGetModelo145ItByUser(true);
    setSuccessGetModelo145ItByUser(false);
    setErrorGetModelo145ItByUser(null);

    apirecord.getModelo145ItByUser(userId).then((res: any) => {
      setLoadingGetModelo145ItByUser(false);
      setSuccessGetModelo145ItByUser(true);
      setDataModelo145ItByUser(res.data);
      if (typeof _cb === "function") {
        _cb(res.data);
      }

    }).catch((err:any) => {
      console.log(err)
      setLoadingGetModelo145ItByUser(false);
      setErrorGetModelo145ItByUser(err)
    })
  }


  return {
    getModelo145ItByUser,
    loadingGetModelo145ItByUser,
    successGetModelo145ItByUser,
    errorGetModelo145ItByUser,
    dataGetModelo145ItByUser
  }

}

export default useGetModelo145ItByUser;