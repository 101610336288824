import React, { useState } from 'react';
import { record } from "@solimat/solimat-web-endpoint";

const useUpdateModelo145ItByID = () => {
  const [loadingUpdateModelo145ById, setLoadingCreateModelo145] = useState(false);
  const [successUpdateModelo145ById, setSuccessCreateModelo145] = useState(false);
  const [errorUpdateModelo145ById, setErrorCreateModelo145] = useState(null);
  const [dataUpdateModelo145ById, setDataModelo145] = useState(null);

  const BASE_URL = process.env.REACT_APP_PRESTACIONES_PACIENTE_PATH || "";
  const FUNCTIONS_KEY = process.env.REACT_APP_PRESTACIONES_PACIENTE_KEY || "";
  const apiRecord = new record.RecordService(
    BASE_URL,
    FUNCTIONS_KEY
  )


  //UPDATE
  const updateModelo145ById = async (userId:string ,prestacionId: string, formId: string, data:any, _cb?:(data:any)=>void) => {
    setLoadingCreateModelo145(true);
    setSuccessCreateModelo145(false);
    setErrorCreateModelo145(null);

    apiRecord.updateModelo145ItByID(userId, prestacionId, formId, data).then((res: any) => {
      setLoadingCreateModelo145(false);
      setSuccessCreateModelo145(true);
      setDataModelo145(res.data);
      if (typeof _cb === "function") {
        _cb(res.data);
      }

    }).catch((err:any) => {
      console.log(err)
      setLoadingCreateModelo145(false);
      setErrorCreateModelo145(err.response.data)
    })
  }

  return {
    updateModelo145ById,
    loadingUpdateModelo145ById,
    successUpdateModelo145ById,
    errorUpdateModelo145ById,
    dataUpdateModelo145ById
  }

}

export default useUpdateModelo145ItByID;
