import React, { useEffect } from 'react';
import { FieldError, FieldValues, RefCallBack, UseFormRegister, useForm, useFormContext } from 'react-hook-form';
import get from 'lodash/get';
interface IInputProps {
  name: string,
  id: string,
  label: string,
  placeholder?: string,
  readonly?: boolean,
  defaultValue?: string
  disabled?: boolean
  maxLength?: number,
  handleOnChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  value?: any,
  isvalidateNumber?: boolean,
  isvalidateNumberPostalCode?: boolean,
  req?: string
  pattern?: RegExp,
  patternMessage?: string
  xl?: number
  md?: number
  invisible?: boolean
}

const FormInputText: React.FC<IInputProps> = ({ name, id, label, placeholder, disabled, readonly, maxLength, req, pattern, patternMessage, xl, md, invisible }) => {

  //NUEVO INPUT DE TEXTO CON MEJOR MANEJO //


  const { register, formState: { errors } } = useFormContext();



  // / Obtener mensaje de error seguro
  const errorMessage = get(errors, name)?.message;


  return (
    <div className={`col-xl-${xl} col-md-${md} ${invisible ? 'd-none' : ''}`}>


      <div className={`input-block ${errors && errors[name]?.message ? 'error' : ''}`}>
        <label htmlFor={id} className="input-block__label">{label}</label>
        <input
          type="text"
          name={name}
          id={id}
          placeholder={placeholder}
          {...register(name, { required: req, pattern: { value: pattern, message: patternMessage } })}
          readOnly={readonly || false}
          disabled={disabled}
          maxLength={maxLength}
        />

        {errorMessage && <p className="error m-0">{errorMessage}</p>}
      </div>
    </div>
  )
}

export default FormInputText;

// USE EXAMPLE
{/* <InputText 
  id="personalDataName"
  name="personalDataName"
  label="Nombre"
  placeholder="Nombre"
  errors={errors}
  register={register}
  req = 'MENSAJE DE ERROR CUANDO NO HAYA VALOR'
/> */}