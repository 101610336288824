import React, { useState } from 'react';
import { record } from "@solimat/solimat-web-endpoint";

const useCreatetDeclaracionSituacionActividadIt = () => {
  const [loadingDeclaracionSituacionActividadIt, setLoadingCreateSolicitudPagoDirecto] = useState(false);
  const [successDeclaracionSituacionActividadIt, setSuccessCreateSolicitudPagoDirecto] = useState(false);
  const [errorDeclaracionSituacionActividadIt, setErrorCreateSolicitudPagoDirecto] = useState(null);
  const [dataDeclaracionSituacionActividadIt, setDataSolicitudPagoDirecto] = useState(null);

  const BASE_URL = process.env.REACT_APP_PRESTACIONES_PACIENTE_PATH || "";
  const FUNCTIONS_KEY = process.env.REACT_APP_PRESTACIONES_PACIENTE_KEY || "";
  const apiRecord = new record.RecordService(
    BASE_URL,
    FUNCTIONS_KEY
  )



  //CREATE
  const createDeclaracionSituacionActividadIt = async (userId:string ,prestacionId: string, data: any ,_cb?:(data:any)=>void) => {
    setLoadingCreateSolicitudPagoDirecto(true);
    setSuccessCreateSolicitudPagoDirecto(false);
    setErrorCreateSolicitudPagoDirecto(null);

    apiRecord.newDeclaracionSituacionActividadIt(userId, prestacionId, data).then((res: any) => {
      setLoadingCreateSolicitudPagoDirecto(false);
      setSuccessCreateSolicitudPagoDirecto(true);
      setDataSolicitudPagoDirecto(res.data);
      if (typeof _cb === "function") {
        _cb(res.data);
      }

    }).catch((err:any) => {
      console.log(err)
      setLoadingCreateSolicitudPagoDirecto(false);
      setErrorCreateSolicitudPagoDirecto(err.response.data)
    })
  }




  return {
    createDeclaracionSituacionActividadIt,
    loadingDeclaracionSituacionActividadIt,
    successDeclaracionSituacionActividadIt,
    errorDeclaracionSituacionActividadIt,
    dataDeclaracionSituacionActividadIt
  }

}

export default useCreatetDeclaracionSituacionActividadIt;
