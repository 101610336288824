import React, { useEffect, useState } from 'react';
import { AutoCompleteComponent, FilteringEventArgs } from '@syncfusion/ej2-react-dropdowns';
import * as Fuse from 'fuse.js';
import useUtils from 'services/utils/useUtils'
import { updateData } from '@syncfusion/ej2-react-grids';
import { useFormContext } from 'react-hook-form';

//https://ej2.syncfusion.com/react/demos/?_ga=2.188859758.492554042.1614238482-182918920.1605268815&_gac=1.124803832.1614252643.Cj0KCQiAst2BBhDJARIsAGo2ldXrfA73wUAvmpUq7XucmjZQnCE4LbTrrRup1lyOqfx16l6Fw63u-KcaAlYcEALw_wcB#/material/auto-complete/custom-filtering

interface IInputProps {
  errors: any
  register: any
  setValue: any
  watchWorkerContractCode: string
  idCode: string
  idDescription: string
  req?: string
  defaultValue?: string
  disabled? : boolean
}

const AgreementType: React.FC<IInputProps> = ({ errors, register, setValue, req, defaultValue, disabled,
  watchWorkerContractCode, idCode, idDescription }) => {
  const Fuse = require('fuse.js')
  const [data, setData] = useState<{ [key: string]: Object }[]>()

  const {
    getAllContract, onlyDigitsWithMaxLength
  } = useUtils();

  useEffect(() => {
    handleGetAllContract()
    
  }, [])

  const handleGetAllContract = async () => {
    const responseAllContract = await getAllContract();
    if (responseAllContract) {
      setData(responseAllContract)
    }
  }
  const itemsData: { [key: string]: Object; }[] = data;
  // maps the appropriate column to fields property
  const fields: object = { value: 'Descripcion' };
  const inptIdCode = `${idCode ? idCode : "worker_contract"}`;
  const inptIdDescription = `${idDescription ? idDescription : "worker_contractDescription"}`;

  const { getValues } = useFormContext();
  //Bind the filter event
  function onFiltering(e: FilteringEventArgs) {
    let options: Object = {
      keys: ['Descripcion'],
      includeMatches: true,
      findAllMatches: true
    };

    
    let fuse: any = new Fuse(itemsData, options);
    let result: any = fuse.search(e.text);
    let data: { [key: string]: Object; }[] = [];
    for (let i: number = 0; i < result.length; i++) {
      data.push(result[i].item as any);
    }
  
    e.updateData(data, undefined);
    let lists: any = document?.getElementById('agreement_popup')?.querySelectorAll('.e-list-item');
    highlightSearch(lists, result as any);
  }

  function highlightSearch(listItems: Element[], result: any): void {
    if (result.length > 0) {
      for (let i: number = 0; i < listItems.length; i++) {
        let innerHTML: string = listItems[i].innerHTML;
        for (let j: number = result[i].matches[0].indices.length - 1; j >= 0; j--) {
          let indexes: number[] = result[i].matches[0].indices[j];
          innerHTML = innerHTML.substring(0, indexes[0]) + '<span class="e-highlight">' +
            innerHTML.substring(indexes[0], (indexes[1] + 1)) + '</span>' + innerHTML.substring(indexes[1] + 1);
          listItems[i].innerHTML = innerHTML;
        }
      }
    }
  }

  useEffect(() => {
    if (!watchWorkerContractCode) return;
    setValueContractDescripton();
  }, [watchWorkerContractCode])


  const handleChange = (args: any) => {
    if (data) {
      const codeValue = data?.find((item: any) => item.Descripcion === args.value);
      setValue(inptIdCode, codeValue ? codeValue.Codigo : '');
    }
  }

  const setValueContractDescripton = () => {
    if (data) {
      const descriptionValue = data?.find((item: any) => item.Codigo === watchWorkerContractCode)
      setValue(inptIdDescription, descriptionValue ? descriptionValue.Descripcion : null)
    }
  }

  if (data?.length) {

    return (
      <>
        <div className="auto-complete-component--syncfusion common-form">
          <div className={`input-block ${errors && (errors[inptIdCode]?.message || errors[inptIdDescription]?.message) ? 'error' : ''}`}>
            <p className="input-block__label">{"Tipo de contrato"}</p>
            <div id='autocustom' className='control-pane'>
              <div className='control-section'>
                <input
                  type="text"
                  inputMode="numeric"
                  id={inptIdCode}
                  name={inptIdCode}
                  className=""
                  {...register(inptIdCode , { required: req })}
                  maxLength={3}
                  autoComplete="off"
                  onKeyDown={(e) => { onlyDigitsWithMaxLength(e, getValues(inptIdCode), 3) }}
                  disabled={disabled}
                />
                <div>
                  <AutoCompleteComponent
                    change={handleChange}
                    id={inptIdDescription}
                    name={inptIdDescription}
                    dataSource={itemsData}
                    filtering={() => onFiltering}
                    fields={fields}
                    placeholder="Tipo de contrato"
                    autofill={true}
                    {...register(inptIdDescription)}
                    enabled={!disabled}
                  />
                </div>
              </div>
            </div>
            {errors &&
              <p className="error m-0">
                {errors[idCode ? idCode : "worker_contract"]?.message}
                {errors[idDescription ? idDescription : "worker_contractDescription"]?.message}
              </p>
            }
            {errors?.[idCode.split('.')[0]] && errors?.[idCode.split('.')[0]][idCode.split('.')[1]]?.message ? //procesamiento de error cuando se usa dentro de un ArrayField
          <>
            <p className="error m-0 ">
              {errors?.[idCode.split('.')[0]][idCode.split('.')[1]]?.message}
            </p>
          </>

          : ''}
          </div>
        </div>
      </>
    )
  } else {
    return (
      <div></div>
    )
  }
}

export default AgreementType;