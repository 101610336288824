import React, { useState, useEffect } from 'react';
import { DatePickerComponent } from '@syncfusion/ej2-react-calendars';
import { FieldValues, UseFormRegister } from "react-hook-form";
import { TooltipComponent } from '@syncfusion/ej2-react-popups';
import { Button, Modal } from 'react-bootstrap';
import get from 'lodash/get';
import { useFormContext } from 'react-hook-form';
interface IInputProps {
    name: string;
    id: string;
    label?: string;
    placeholder?: string;
    getDayWeek?: (date: Date) => void;
    minDate?: Date;
    maxDate?: Date;
    req?: string;
    disableDate?: boolean
    modal?: string
    xl?: number;
    md?: number;
    col?: number
}

const FormInputFecha: React.FC<IInputProps> = ({ name, id, label, getDayWeek, minDate, maxDate, req, disableDate, modal, xl , md , col
}) => {

    const { register, formState: { errors } } = useFormContext();



    // / Obtener mensaje de error seguro
    const errorMessage = get(errors, name)?.message;

    const [selectedDate, setSelectedDate] = useState<Date | null>(null);

    const handleDateChange = (date: Date | null) => {
        setSelectedDate(date);
        if (getDayWeek && date) {
            getDayWeek(date);
        }
    };



    /////// MODAL ///////
    const [show, setShow] = useState(false);
    const handleShow = () => setShow(true);
    const handleClose = () => setShow(false);

    return (
        <div className={`col-xl-${xl} col-md-${md} col-${col}`}>
            <div className={`container-inputdate input-block ${id} ${errors && errors[name]?.message ? "error" : ""}`}>
                {label && <label htmlFor={id} className="input-block__label">{label}
                </label>}


                {modal ? <TooltipComponent content="Mas Información">
                    <div onClick={handleShow} className='information-icon-orange p-0'></div>
                </TooltipComponent> : ''}


                <DatePickerComponent
                    format='dd-MM-yyyy'
                    name={name}
                    id={id}
                    change={(event) => handleDateChange(event.value)}
                    value={selectedDate} 
                    firstDayOfWeek={1}
                    {...register(name, { required: req })}
                    min={minDate}
                    max={maxDate}
                    disabled={disableDate}
                />

                {errorMessage && <p className="error m-0">{errorMessage}</p>}

                <Modal show={show} onHide={handleClose}>
                    <Modal.Header closeButton>

                        <Modal.Title>{label}</Modal.Title>

                    </Modal.Header>
                    <Modal.Body>{modal}</Modal.Body>
                </Modal>
            </div>
        </div>

    );
};

export default FormInputFecha;
