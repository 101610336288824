import React, { useEffect, useState } from 'react'
import { useHistory } from "react-router-dom";
import PageMenu from 'components/blocks/PageMenu/PageMenu'
import IconArrowGray from "../../../../img/icons/svg/botones/flecha-big-gris.svg";
import { useParams } from 'react-router-dom';
import useGetDirectPaymentFormItByID from 'services/record/useGetDirectPaymentFormItByID';
import Loader from 'components/blocks/Loader/Loader';
import FormularioPagoDirecto from './FormularioPagoDirecto';
const jwtDecode = require('jwt-decode');
interface Params {
    idprestacion: string;
    idform: string;
}


const HistorialFormularioPagoDirecto = () => {

    const [userId, setUserId] = useState<string>('');
    const [prestacionId, setPrestacionId] = useState<string>('');
    const [formId, setFormId] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(false);
    const history = useHistory()
    const { getSolicitudPagoDirectoById,
        loadingGetSolicitudPagoDirectoById,
        dataGetSolicitudPagoDirectoById } = useGetDirectPaymentFormItByID();
    const { idprestacion, idform } = useParams<Params>();


    useEffect(() => {
        const actualUrl = window.location.href;
        if (!actualUrl.includes('/mis-gestiones/formularios')) {
            setLoading(true);
            const userId = jwtDecode(localStorage.getItem("token")).extension_id;
            const prestacionId = idprestacion.split('_')[1];
            const formId = idform;
            setUserId(userId);
            setPrestacionId(prestacionId);
            setFormId(formId);
            setTimeout(() => { //Añadido porque los Endpoints iban mal ese día
                setLoading(false); //¿Quitar?
            }, 1500);
        }
    }, []);


    return (
        <div>
            <PageMenu title={'Solicitud de Pago Directo Incapacidad Temporal'}>

                <div className="btns-header-container">
                    <button onClick={() => history.goBack()}>
                        <img src={IconArrowGray} alt="icon arrow right" />
                    </button>
                </div>

            </PageMenu>


            {
                <>
                    <Loader activeLoader={loading} label="Cargando Formulario"></Loader>
                    <div style={{ display: loading ? 'none' : 'block' }}>

                        <FormularioPagoDirecto info={false} prestacionId={prestacionId} userId={userId} formId={formId} />
                    </div>
                </>


            }




        </div>
    )
}

export default HistorialFormularioPagoDirecto