import React, { useState } from 'react';
import { record } from "@solimat/solimat-web-endpoint";

const useGetDeclaracionSituacionActividadByID = () => {
  const [loadingGetDeclaracionSituacionActividadById, setLoadingCreateDeclaracionSituacionActividad] = useState(false);
  const [successGetDeclaracionSituacionActividadById, setSuccessCreateDeclaracionSituacionActividad] = useState(false);
  const [errorGetDeclaracionSituacionActividadById, setErrorCreateDeclaracionSituacionActividad] = useState(null);
  const [dataGetDeclaracionSituacionActividadById, setDataDeclaracionSituacionActividad] = useState(null);

  const BASE_URL = process.env.REACT_APP_PRESTACIONES_PACIENTE_PATH || "";
  const FUNCTIONS_KEY = process.env.REACT_APP_PRESTACIONES_PACIENTE_KEY || "";
  const apiRecord = new record.RecordService(
    BASE_URL,
    FUNCTIONS_KEY
  )



  //GET
  const getDeclaracionSituacionActividadById = async (userId:string ,prestacionId: string, id: string, _cb?:(data:any)=>void) => {
    setLoadingCreateDeclaracionSituacionActividad(true);
    setSuccessCreateDeclaracionSituacionActividad(false);
    setErrorCreateDeclaracionSituacionActividad(null);

    apiRecord.getDeclaracionSituacionActividadItByID(userId, prestacionId, id).then((res: any) => {
      setLoadingCreateDeclaracionSituacionActividad(false);
      setSuccessCreateDeclaracionSituacionActividad(true);
      setDataDeclaracionSituacionActividad(res.data);
      if (typeof _cb === "function") {
        _cb(res.data);
      }
      console.log("resdata" ,dataGetDeclaracionSituacionActividadById && dataGetDeclaracionSituacionActividadById);
    }).catch((err:any) => {
      console.log(err)
      setLoadingCreateDeclaracionSituacionActividad(false);
      setErrorCreateDeclaracionSituacionActividad(err)
    })
  }

  //UPDATE




  return {
    getDeclaracionSituacionActividadById,
    loadingGetDeclaracionSituacionActividadById,
    successGetDeclaracionSituacionActividadById,
    errorGetDeclaracionSituacionActividadById,
    dataGetDeclaracionSituacionActividadById
  }

}

export default useGetDeclaracionSituacionActividadByID;