import {useState} from 'react';
import {record} from '@solimat/solimat-web-endpoint';

const useGetInfoModelo145It = () => {
  const [loadingGetInfoModelo145It, setLoadingGetInfoModelo145It] = useState(false);
  const [successGetInfoModelo145It, setSuccessGetInfoModelo145It] = useState(false);
  const [errorGetInfoModelo145It, setErrorGetInfoModelo145It] = useState(null);
  const [infoModelo145It, setInfoModelo145It] = useState<null|any>(null);

  const BASE_URL = process.env.REACT_APP_PRESTACIONES_PACIENTE_PATH || "";
  const FUNCTIONS_KEY = process.env.REACT_APP_PRESTACIONES_PACIENTE_KEY || "";
  const apiRecord = new record.RecordService(BASE_URL, FUNCTIONS_KEY);
 
  const getInfoModelo145It = (userId:string, _cb?:(data:any)=>void) => {
    setLoadingGetInfoModelo145It(true);
    setSuccessGetInfoModelo145It(false);
    apiRecord.getInfoModelo145It(userId)
      .then((res) => {       
        setInfoModelo145It(res.data);
        setLoadingGetInfoModelo145It(false);
        setSuccessGetInfoModelo145It(true);
        if (typeof _cb === "function") {
          _cb(res.data);
        }
      })
      .catch((err)=> {
        console.log(err)
        setLoadingGetInfoModelo145It(false);
        setErrorGetInfoModelo145It(err)
      })
  }


  return {
    infoModelo145It,
    getInfoModelo145It,
    loadingGetInfoModelo145It,
    successGetInfoModelo145It,
    errorGetInfoModelo145It
    };
}

export default useGetInfoModelo145It;
