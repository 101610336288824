import React, { useEffect } from 'react';
import { FieldValues, RefCallBack, UseFormRegister, useForm, useFormContext } from 'react-hook-form';
import { validate } from 'uuid';
import get from 'lodash/get'; // Instala lodash: npm install lodash

interface IInputProps {
    name: string,
    id: string,
    label: string,
    placeholder: string,
    readonly?: boolean,
    disabled?: boolean
    maxLength?: number,
    handleOnChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
    value?: any,
    isvalidateNumber?: boolean,
    isvalidateNumberPostalCode?: boolean,
    req?: string
    xl?: number
    md?: number
    col?: number
}

const FormInputIPF: React.FC<IInputProps> = ({ name, id, label, placeholder, readonly, disabled, maxLength, req, xl, md, col }) => {

    const { register, formState: { errors } , watch } = useFormContext();

    const validateNIE = (value: string) => {
        
        if (value === undefined || value === null || value === "") {
            if (req === undefined || req === null || req === "") {
                return true;
            }
        }





        const tipo = watch(name + "Tipo");
        if (tipo === "1") {
            const regex = /^\d{8}[A-Z]$/;
            if (!regex.test(value)) {
                return "El DNI debe tener 8 números y una letra mayúscula";
            }


            const dniNumbers = value.substring(0, 8);
            const dniLetter = value.substring(8, 9);
            const letter = calculateDNIletter(dniNumbers);
            if (letter !== dniLetter) {
                return "La letra no coincide";
            }
        }
        else if (tipo === "2") {
            const regex = /^[A-Z]{1}[0-9]{7}[A-Z]{1}$/;
            if (!regex.test(value)) {
                return "El NIE debe tener 1 letra, 7 números y 1 letra (letras mayúsculas)";
            }


            const initialLetter = value.substring(0, 1);
            if (!["X", "Y", "Z"].includes(initialLetter)) {
                return "La letra inicial del NIE debe ser X, Y o Z";
            }


            const finalLetter = value.substring(8, 9);
            const calculatedLetter = calculateNIEletter(value.substring(1, 8));
            if (finalLetter !== calculatedLetter) {
                return "La letra final del NIE no coincide";
            }
        }
        else if (tipo === "6") {
          /*  const validCombinations = [
                "AA", "AB", "AC", "AD", "AE", "AF", "AG", "AH", "AI", "AJ", "AK", "AL", "AM", "AN", "AO", "AP", "AQ", "AR", "AS", "AT", "AU", "AV", "AW", "AX", "AY", "AZ",
                "BA", "BB", "BC", "BD", "BE", "BF", "BG", "BH", "BI", "BJ", "BK", "BL", "BM", "BN", "BO", "BP", "BQ", "BR", "BS", "BT", "BU", "BV", "BW", "BX", "BY", "BZ",
                "CA", "CB", "CC", "CD", "CE", "CF", "CG", "CH", "CI", "CJ", "CK", "CL", "CM", "CN", "CO", "CP", "CQ", "CR", "CS", "CT", "CU", "CV", "CW", "CX", "CY", "CZ",
                "DA", "DB", "DC", "DD", "DE", "DF", "DG", "DH", "DI", "DJ", "DK", "DL", "DM", "DN", "DO", "DP", "DQ", "DR", "DS", "DT", "DU", "DV", "DW", "DX", "DY", "DZ"
            ];
            const regex = /^[A-Z]{2}\d{7}$/;
            if (!regex.test(value)) {
                return "El pasaporte debe tener 2 letras mayúsculas y 7 dígitos";
            }
            const combination = value.substring(0, 2);
            if (!validCombinations.includes(combination)) {
                return "La combinación de letras mayúsculas no es válida";
            } */
        }


        return true;
    };



    // Función para calcular la letra final del NIE
    function calculateNIEletter(numbers: string): string {
        const nieLetters = "JPGMNRS";
        const remainder = parseInt(numbers) % 9;
        return nieLetters[remainder];
    }

    //Calcular la letra del DNI
    function calculateDNIletter(numbers: string): string {
        const dniLetters = "TRWAGMYFPDXBNJZSQVHLCKE";
        const remainder = parseInt(numbers) % 23;
        return dniLetters[remainder];
    }
    const errorMessage = get(errors, name)?.message;
    return (
        <div className={`col-xl-${xl} col-md-${md} col-${col}`}>
            <fieldset className={`input-block input-block--code ${errors && errors[name]?.message || errors && errors[name]?.message ? 'error' : ''}`} disabled={disabled}>
                <legend className="input-block--code__legend">{label}</legend>
                
                        <input
                            type="text"
                            className='col-xl-12 col-12 col-md-12'
                            name={name}
                            id={id}
                            placeholder={placeholder}
                            {...register(name, { required: req, validate: validateNIE })}
                            readOnly={readonly || false}
                            disabled={disabled}

                            maxLength={maxLength}
                        />
                        <select id="cars" {...register(name + "Tipo")} className=' col-xl-12 col-md-12 col-12 ' disabled={disabled}>
                            <option value="1">DNI</option>
                            <option value="2">NIE</option>
                            <option value="6">Pasaporte</option>
                        </select>

                        {errorMessage && <p className="error m-0">{errorMessage}</p>}
               
            </fieldset>
        </div>
    )
}

export default FormInputIPF;

