import React, { useState, useEffect } from "react";
import { AutoCompleteComponent } from "@syncfusion/ej2-react-dropdowns";
import useUtils from "services/utils/useUtils";
import { useFormContext, useWatch } from "react-hook-form";
import get from "lodash/get";

interface IInputProps {
    name: string;
    required?: string;
    disabled?: boolean;
    xl?: number;
    md?: number;
    col?: number;
}

const InputCausaPagoDirecto: React.FC<IInputProps> = ({
    name,
    required,
    disabled,
    xl,
    md,
    col,
}) => {
    const [data, setData] = useState<any>(null);
    const [desc, setDesc] = useState<any>(null);
    const { getCausaPago } = useUtils();
    const {
        register,
        formState: { errors },
        setValue,
    } = useFormContext();

    const errorMessage = get(errors, name)?.message;
    const watchDesc = useWatch({ name });

    useEffect(() => {
        const fetchData = async () => {
            try {
                const causaPagoData = await getCausaPago();
                setData(causaPagoData);
            } catch (error) {
                console.error("Error al obtener los datos de causa de pago:", error);
            }
        };

        fetchData();
    }, []);

    useEffect(() => {
        if (data && watchDesc) {
            const watchDescString = watchDesc.toString();
            const number =
                watchDescString.length === 3 ? `0${watchDescString}` : watchDescString;

            const selectedDesc = data.find((i) => i.Codigo === number);
            setDesc(selectedDesc || null);

            if (selectedDesc) {
                setValue(name + "Descripcion", selectedDesc.Descripcion);
            }
        }
    }, [data, watchDesc, setValue, name]);

    useEffect(() => {
        register(name, { required });
        register(name + "Descripcion");
    }, [register, name, required]);

    return (
        <div className={`col-xl-${xl} col-md-${md} col-${col}`}>
            <fieldset className={`input-block input-block--code`}>
                <div className="container-leyend">
                    <legend className="input-block--code__legend">Causa del Pago Directo</legend>
                </div>
                <div className="container-inputs-cno-code">
                    <AutoCompleteComponent
                        dataSource={data}
                        fields={{ value: "Descripcion" }}
                        placeholder="Selecciona una causa de pago"
                        change={(e) => {
                            setValue(name, e.itemData?.Codigo);
                            setValue(name + "Descripcion", e.itemData?.Descripcion);
                        }}
                        value={desc?.Descripcion}
                        enabled={!disabled}
                    />
                </div>

                {errorMessage && <p className="error m-0">{errorMessage}</p>}
            </fieldset>
        </div>
    );
};

export default InputCausaPagoDirecto;
