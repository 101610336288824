import {useState} from 'react';
import {record} from '@solimat/solimat-web-endpoint';

const useGetInfoDeclaracionSituacionActividadIt = () => {
  const [loadingGetInfoDeclaracionSituacionActividadIt, setLoadingGetInfoDeclaracionSituacionActividadIt] = useState(false);
  const [successGetInfoDeclaracionSituacionActividadIt, setSuccessGetInfoDeclaracionSituacionActividadIt] = useState(false);
  const [errorGetInfoDeclaracionSituacionActividadIt, setErrorGetInfoDeclaracionSituacionActividadIt] = useState(null);
  const [infoDeclaracionSituacionActividadIt, setInfoDeclaracionSituacionActividadIt] = useState<null|any>(null);

  const BASE_URL = process.env.REACT_APP_PRESTACIONES_PACIENTE_PATH || "";
  const FUNCTIONS_KEY = process.env.REACT_APP_PRESTACIONES_PACIENTE_KEY || "";
  const apiRecord = new record.RecordService(BASE_URL, FUNCTIONS_KEY);
 
  const getInfoDeclaracionSituacionActividadIt = (userId:string, _cb?:(data:any)=>void) => {
    setLoadingGetInfoDeclaracionSituacionActividadIt(true);
    setSuccessGetInfoDeclaracionSituacionActividadIt(false);
    apiRecord.getInfoDeclaracionSituacionActividadIt(userId)
      .then((res) => {       
        setInfoDeclaracionSituacionActividadIt(res.data);
        setLoadingGetInfoDeclaracionSituacionActividadIt(false);
        setSuccessGetInfoDeclaracionSituacionActividadIt(true);
        if (typeof _cb === "function") {
          _cb(res.data);
        }
      })
      .catch((err)=> {
        console.log(err)
        setLoadingGetInfoDeclaracionSituacionActividadIt(false);
        setErrorGetInfoDeclaracionSituacionActividadIt(err)
      })
  }


  return {
    infoDeclaracionSituacionActividadIt,
    getInfoDeclaracionSituacionActividadIt,
    loadingGetInfoDeclaracionSituacionActividadIt,
    successGetInfoDeclaracionSituacionActividadIt,
    errorGetInfoDeclaracionSituacionActividadIt
    };
}

export default useGetInfoDeclaracionSituacionActividadIt;
