import React, { useState } from 'react';
import { record } from "@solimat/solimat-web-endpoint";

const useGetDeclaracionSituacionActividadItByUser = () => {
  const [loadingGetDeclaracionSituacionActividadItByUser, setLoadingGetDeclaracionSituacionActividadItByUser] = useState(false);
  const [successGetDeclaracionSituacionActividadItByUser, setSuccessGetDeclaracionSituacionActividadItByUser] = useState(false);
  const [errorGetDeclaracionSituacionActividadItByUser, setErrorGetDeclaracionSituacionActividadItByUser] = useState(null);
  const [dataGetDeclaracionSituacionActividadItByUser, setDataDeclaracionSituacionActividadItByUser] = useState(null);

  const BASE_URL = process.env.REACT_APP_PRESTACIONES_PACIENTE_PATH || "";
  const FUNCTIONS_KEY = process.env.REACT_APP_PRESTACIONES_PACIENTE_KEY || "";
  const apirecord = new record.RecordService(
    BASE_URL,
    FUNCTIONS_KEY
  )

  
  //GET
  const getDeclaracionSituacionActividadItByUser = async (userId: string, _cb?:(data:any)=>void) => {
    setLoadingGetDeclaracionSituacionActividadItByUser(true);
    setSuccessGetDeclaracionSituacionActividadItByUser(false);
    setErrorGetDeclaracionSituacionActividadItByUser(null);

    apirecord.getDeclaracionSituacionActividadItByUser(userId).then((res: any) => {
      setLoadingGetDeclaracionSituacionActividadItByUser(false);
      setSuccessGetDeclaracionSituacionActividadItByUser(true);
      setDataDeclaracionSituacionActividadItByUser(res.data);
      if (typeof _cb === "function") {
        _cb(res.data);
      }

    }).catch((err:any) => {
      console.log(err)
      setLoadingGetDeclaracionSituacionActividadItByUser(false);
      setErrorGetDeclaracionSituacionActividadItByUser(err)
    })
  }


  return {
    getDeclaracionSituacionActividadItByUser,
    loadingGetDeclaracionSituacionActividadItByUser,
    successGetDeclaracionSituacionActividadItByUser,
    errorGetDeclaracionSituacionActividadItByUser,
    dataGetDeclaracionSituacionActividadItByUser
  }

}

export default useGetDeclaracionSituacionActividadItByUser;