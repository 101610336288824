import { TooltipComponent } from '@syncfusion/ej2-react-popups';
import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';

interface IInputProps {
    name: string;
    id: string;
    label: string;
    placeholder: string;
    errors: any;
    register: any;
    readonly?: boolean;
    defaultValue?: boolean;
    disabled?: boolean;
    functionOnChange?: (value: boolean) => void;
    col?: number;
    xl?: number;
    md?: number;
    justify?: string;
    modal?: string;
}

const InputChkBox: React.FC<IInputProps> = ({
    name,
    id,
    label,
    placeholder,
    errors,
    register,
    readonly,
    defaultValue = false,
    disabled,
    xl,
    md,
    col,
    modal,
    functionOnChange,
    justify
}) => {
    const [checked, setChecked] = useState(defaultValue);
    const [show, setShow] = useState(false);

    useEffect(() => {
        setChecked(defaultValue);
    }, [defaultValue]);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (!readonly && !disabled) {
            setChecked(e.target.checked);
            functionOnChange?.(e.target.checked);
        }
    };

    return (
        <>
            <div className={`col-xl-${xl} col-md-${md} col-${col} align-items-center d-flex justify-content-${justify}`}>
                <div className={`form-check d-flex justify-content-center ${errors?.[name]?.message ? 'error' : ''}`}>
                    <input
                        className='form-check mr-2'
                        type="checkbox"
                        name={name}
                        id={id}
                        checked={checked}
                        placeholder={placeholder}
                        {...register(name)}
                        readOnly={readonly}
                        disabled={disabled}
                        onChange={handleChange}
                    />
                    <label className="input-block__label" htmlFor={id}>{label}</label>

                    {modal && (
                        <TooltipComponent content="Más Información">
                            <div onClick={() => setShow(true)} className='information-icon-orange p-0'></div>
                        </TooltipComponent>
                    )}

                    {errors?.[name]?.message && <p className="error m-0">{errors[name]?.message}</p>}

                    {errors?.[name.split('.')[0]]?.[name.split('.')[1]]?.message && (
                        <p className="error m-0">{errors[name.split('.')[0]][name.split('.')[1]].message}</p>
                    )}

                    <Modal show={show} onHide={() => setShow(false)}>
                        <Modal.Header closeButton>
                            <Modal.Title>{label}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>{modal}</Modal.Body>
                    </Modal>
                </div>
            </div>
        </>
    );
};

export default InputChkBox;
