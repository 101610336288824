import React, { useState, useEffect } from "react";
import { AutoCompleteComponent } from "@syncfusion/ej2-react-dropdowns";
import useUtils from "services/utils/useUtils";
import { useFormContext, useWatch } from "react-hook-form";
import get from "lodash/get";

interface IInputProps {
    name: string;
    required?: string;
    disabled?: boolean;
    xl?: number;
    md?: number;
    col?: number;
}

const InputContigenciasPagoDirecto: React.FC<IInputProps> = ({
    name,
    required,
    disabled,
    xl,
    md,
    col,
}) => {
    const [data, setData] = useState<any>(null); // Datos de la API
    const [desc, setDesc] = useState<any>(null); // Descripción seleccionada
    const { getContingencias } = useUtils(); // Obtener la función de la API
    const {
        register,
        formState: { errors },
        setValue,
    } = useFormContext();

    const errorMessage = get(errors, name)?.message;

    // Escuchar el valor del campo en el formulario
    const watchDesc = useWatch({ name });

    // Efecto para cargar los datos desde la API
    useEffect(() => {
        const fetchData = async () => {
            try {
                const contingenciasData = await getContingencias();
                setData(contingenciasData);
            } catch (error) {
                console.error("Error al obtener contingencias:", error);
            }
        };

        fetchData();
    }, []);

    // Efecto para buscar el objeto relacionado al código seleccionado
    useEffect(() => {
        if (data && watchDesc) {
            const watchDescString = watchDesc.toString();
            const number =
                watchDescString.length === 3 ? `0${watchDescString}` : watchDescString;

            const selectedDesc = data.find((i) => i.Codigo === number);
            setDesc(selectedDesc || null); // Asigna `null` si no encuentra coincidencias

            if (selectedDesc) {
                setValue(name + "Descripcion", selectedDesc.Descripcion);
            }
        }
    }, [data, watchDesc, setValue, name]);

    // Registrar campos en el formulario
    useEffect(() => {
        register(name, { required });
        register(name + "Descripcion");
    }, [register, name, required]);

    return (
        <div className={`col-xl-${xl} col-md-${md} col-${col}`}>
            <fieldset className={`input-block input-block--code`}>
                <div className="container-leyend">
                    <legend className="input-block--code__legend">Contingencias</legend>
                </div>
                <div className="container-inputs-cno-code">
                    <AutoCompleteComponent
                        dataSource={data} // Pasar los datos obtenidos desde la API
                        fields={{ value: "Descripcion" }} // Mostrar la descripción
                        placeholder="Selecciona una contingencia" // Placeholder
                        change={(e) => {
                            setValue(name, e.itemData?.Codigo);
                            setValue(name + "Descripcion", e.itemData?.Descripcion);
                        }}
                        value={desc?.Descripcion} // Descripción actual seleccionada
                        enabled={!disabled} // Habilitar/deshabilitar según el prop
                    />
                </div>

                {errorMessage && <p className="error m-0">{errorMessage}</p>}
            </fieldset>
        </div>
    );
};

export default InputContigenciasPagoDirecto;
