import React, { useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";
import { useForm, Controller } from 'react-hook-form';
import JSZip from 'jszip';
import PageMenu from "../../blocks/PageMenu/PageMenu";
import IconArrowGray from "../../../img/icons/svg/botones/flecha-big-gris.svg";
// import IconCheck from "../../../img/icons/symbols/check-confirm.png";
import useGetRoyalDecreeFormById from 'hooks/useGetRoyalDecreeFormById';
import useUpdateIncompatibilityDocuments from 'hooks/useUpdateIncompatibilityDocuments';
import moment from "moment";
import useDecode from "hooks/useDecode";
import useCheckRecordStatusAndTypes from 'hooks/useCheckRecordStatusAndTypes';
import Delete from "../../../img/icons/svg/botones/delete.png";
import IMGnotice from "../../../img/icons/symbols/notice.png";
import './formRoyalDecree.scss';
import { TooltipComponent } from '@syncfusion/ej2-react-popups';
import { Modal, Button } from 'react-bootstrap';
import Loader from 'components/blocks/Loader/Loader';
import ProgressBarBattery from 'components/ProgressBarBattery/ProgressBarBattery';
const FormRoyalDecree: React.FC = (props: any) => {
    const { getRoyalDecreeFormById, loadingGetRoyalDecreeFormById, royalDecreeFormById, successGetRoyalDecreeFormById } = useGetRoyalDecreeFormById();
    const { UpdateIncompatibilityDocuments, loadingUpdateIncompatibilityDocuments, successUpdateIncompatibilityDocuments, errorUpdateIncompatibilityDocuments } = useUpdateIncompatibilityDocuments();
    const tokenResponse = useDecode("token");
    const userId = tokenResponse && tokenResponse.extension_id;
    const params = props.match.params;
    const paramRecordId = params.id.toString().split("_");
    const prestacionId = paramRecordId.length > 0 ? paramRecordId.length > 1 ? paramRecordId.at(1) : paramRecordId.at(0) : params.id;
    const formId = params.idform;
    const { checkFormStatusRD, checkFormStatusDocumentsRD } = useCheckRecordStatusAndTypes();
    const history = useHistory();
    const { control, handleSubmit, setValue, watch } = useForm();
    const [showModal, setShowModal] = useState(false);
    const [showModalSuccessfully, setShowModalSuccessfully] = useState(null);
    const [currentObservations, setCurrentObservations] = useState('');
    const noData = 'Sin datos'
    const [selectedFiles, setSelectedFiles] = useState<{ [key: string]: { files: File[]; incompatibilityId: string; incompatibilityName: string; documentName: string } }>({});
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [showModalErrorDocumentWeightLimit, setshowModalErrorDocumentWeightLimit] = useState(false)
    const [totalSize, setTotalSize] = useState(0)
    const [showProgressBar, setShowProgressBar] = useState(false);
    const [fileErrors, setFileErrors] = useState({})
    const [uploadStatus, setUploadStatus] = useState<{ [key: string]: "success" | "error" | null }>({});
    const [totalUploads, setTotalUploads] = useState(0);
    const [successfulUploads, setSuccessfulUploads] = useState(0);
    const [uploadProgress, setUploadProgress] = useState<{ [key: string]: number }>({});

    const MAX_SIZE_MB = 50;
    useEffect(() => {
        getRoyalDecreeFormById(userId, prestacionId, formId);
        // setSelectedFiles({})
    }, [userId, prestacionId, formId, successUpdateIncompatibilityDocuments]);
    useEffect(() => {
        if (successfulUploads > 0 && successfulUploads === totalUploads) {
            handleShowModalsuccessfully();
        }
    }, [successfulUploads, totalUploads]);
    const handleFileChange = async (
        e: React.ChangeEvent<HTMLInputElement>,
        documentId: string,
        incompatibilityId: string,
        incompatibilityName: string,
        documentName: string
    ) => {
        const allFiles = Array.from(e.target.files as FileList);

        const allowedExtensions = [
            'pdf', 'doc', 'docx', 'zip', '7z', 'txt',
            'xls', 'xlsx', 'csv', 'odt', 'ods',
            'jpg', 'jpeg', 'png', 'webp'
        ];

        const prohibitedExtensions = [
            'exe', 'bat', 'cmd', 'sh', 'msi', 'vbs', 'scr', 'js', 'jar',
            'html', 'htm', 'mhtml', 'ods', 'jfif', 'pptx'
        ];

        const suspiciousPatterns = [
            /<script>/i,
            /eval\(/i,
            /powershell/i,
            /cmd\.exe/i,
            /wscript/i,
            /cscript/i,
            /vbscript/i,
            /onerror=/i,
            /iframe/i,
            /document\.write/i,
            /base64,/i,
            /data:/i,
        ];
        setUploadStatus((prevStatus) => ({
            ...prevStatus,
            [documentId]: null,
        }));
        const checkForHiddenScripts = async (file: File): Promise<boolean> => {
            return new Promise((resolve) => {
                const reader = new FileReader();

                reader.onload = (event) => {
                    const content = event.target?.result as string;
                    if (suspiciousPatterns.some(pattern => pattern.test(content))) {
                        resolve(true);
                    } else {
                        resolve(false);
                    }
                };

                reader.readAsText(file);
            });
        };

        const validFiles: File[] = [];
        const invalidFiles: File[] = [];

        for (const file of allFiles) {
            const fileExtension = file.name.split('.').pop()?.toLowerCase();

            if (!allowedExtensions.includes(fileExtension || '') || prohibitedExtensions.includes(fileExtension || '')) {
                invalidFiles.push(file);
                continue;
            }

            if (fileExtension === 'zip' || fileExtension === '7z') {
                validFiles.push(file);
            } else {
                const hasHiddenScript = await checkForHiddenScripts(file);
                if (hasHiddenScript) {
                    invalidFiles.push(file);
                } else {
                    validFiles.push(file);
                }
            }
        }

        if (invalidFiles.length > 0) {
            setFileErrors(prevErrors => ({
                ...prevErrors,
                [documentId]: "Algunos archivos tienen una extensión no permitida y han sido descartados."
            }));
            e.target.value = '';
            return;
        }

        setFileErrors(prevErrors => ({
            ...prevErrors,
            [documentId]: ""
        }));

        if (validFiles.length > 0) {
            setShowProgressBar(true);
            setSelectedFiles(prevSelectedFiles => {
                const existingFiles = prevSelectedFiles[documentId]?.files || [];
                const updatedFiles = [...existingFiles, ...validFiles];

                const updatedSelectedFiles = {
                    ...prevSelectedFiles,
                    [documentId]: {
                        files: updatedFiles,
                        incompatibilityId,
                        incompatibilityName,
                        documentName,
                    },
                };

                const newTotalSizeMB = Object.values(updatedSelectedFiles).reduce((acc, item) => {
                    return acc + item.files.reduce((sum, file) => sum + file.size, 0);
                }, 0) / (1024 * 1024);

                setTotalSize(parseFloat(newTotalSizeMB.toFixed(1)));

                return updatedSelectedFiles;
            });
        } else {
            setShowProgressBar(false);
        }
    };


    // const handleFileChange = async (
    //     e: React.ChangeEvent<HTMLInputElement>,
    //     documentId: string,
    //     incompatibilityId: string,
    //     incompatibilityName: string,
    //     documentName: string
    // ) => {
    //     const allFiles = Array.from(e.target.files as FileList);

    //     const allowedExtensions = [
    //         'pdf', 'doc', 'docx', 'zip', '7z', 'txt',
    //         'xls', 'xlsx', 'csv', 'odt', 'ods',
    //         'jpg', 'jpeg', 'png', 'webp'
    //     ];

    //     const prohibitedExtensions = [
    //         'exe', 'bat', 'cmd', 'sh', 'msi', 'vbs', 'scr', 'js', 'jar', 
    //         'html', 'htm', 'mhtml', 'ods', 'jfif', 'pptx'
    //     ];

    //     const suspiciousPatterns = [
    //         /<script>/i,      
    //         /eval\(/i,         
    //         /powershell/i,     
    //         /cmd\.exe/i,       
    //         /wscript/i,        
    //         /cscript/i,
    //         /vbscript/i,
    //         /onerror=/i,       
    //         /iframe/i,        
    //         /document\.write/i,
    //         /base64,/i,        
    //         /data:/i,          
    //     ];

    //     const checkForHiddenScripts = async (file: File): Promise<boolean> => {
    //         return new Promise((resolve) => {
    //             const reader = new FileReader();

    //             reader.onload = (event) => {
    //                 const content = event.target?.result as string;
    //                 if (suspiciousPatterns.some(pattern => pattern.test(content))) {
    //                     resolve(true);
    //                 } else {
    //                     resolve(false);
    //                 }
    //             };

    //             reader.readAsText(file); 
    //         });
    //     };

    //     const validFiles: File[] = [];
    //     const invalidFiles: File[] = [];

    //     const handleZipFile = async (file: File) => {
    //         const zip = await JSZip.loadAsync(file);
    //         const zipFiles = Object.values(zip.files);

    //         for (const zipFile of zipFiles) {
    //             if (!zipFile.dir) { 
    //                 const fileBlob = await zipFile.async("blob");
    //                 const fileName = zipFile.name;
    //                 const fileExtension = fileName.split('.').pop()?.toLowerCase();

    //                 const zipFileAsFile = new File([fileBlob], fileName, { type: fileBlob.type });

    //                 if (!allowedExtensions.includes(fileExtension || '') || prohibitedExtensions.includes(fileExtension || '')) {
    //                     invalidFiles.push(zipFileAsFile);
    //                 } else {
    //                     const hasHiddenScript = await checkForHiddenScripts(zipFileAsFile);
    //                     if (hasHiddenScript) {
    //                         invalidFiles.push(zipFileAsFile);
    //                     } else {
    //                         validFiles.push(zipFileAsFile);
    //                     }
    //                 }
    //             }
    //         }
    //     };

    //     // 🔹 Filtrar archivos inválidos antes de procesarlos
    //     for (const file of allFiles) {
    //         const fileExtension = file.name.split('.').pop()?.toLowerCase();

    //         // 🔹 Bloquear archivos con extensiones no permitidas antes de procesarlos
    //         if (!allowedExtensions.includes(fileExtension || '') || prohibitedExtensions.includes(fileExtension || '')) {
    //             invalidFiles.push(file);
    //             continue;
    //         }

    //         if (fileExtension === 'zip') {
    //             await handleZipFile(file); 
    //         } else {
    //             const hasHiddenScript = await checkForHiddenScripts(file);
    //             if (hasHiddenScript) {
    //                 invalidFiles.push(file);
    //             } else {
    //                 validFiles.push(file);
    //             }
    //         }
    //     }

    //     if (invalidFiles.length > 0) {
    //         setFileErrors(prevErrors => ({
    //             ...prevErrors,
    //             [documentId]: "Algunos archivos tienen una extensión no permitida y han sido descartados."
    //         }));
    //         e.target.value = ''; 
    //         return;
    //     }

    //     setFileErrors(prevErrors => ({
    //         ...prevErrors,
    //         [documentId]: ""
    //     }));

    //     if (validFiles.length > 0) {
    //         setShowProgressBar(true);
    //         setSelectedFiles(prevSelectedFiles => {
    //             const existingFiles = prevSelectedFiles[documentId]?.files || [];
    //             const updatedFiles = [...existingFiles, ...validFiles];

    //             const updatedSelectedFiles = {
    //                 ...prevSelectedFiles,
    //                 [documentId]: {
    //                     files: updatedFiles,
    //                     incompatibilityId,
    //                     incompatibilityName,
    //                     documentName,
    //                 },
    //             };

    //             const newTotalSizeMB = Object.values(updatedSelectedFiles).reduce((acc, item) => {
    //                 return acc + item.files.reduce((sum, file) => sum + file.size, 0);
    //             }, 0) / (1024 * 1024);

    //             setTotalSize(parseFloat(newTotalSizeMB.toFixed(1))); 

    //             return updatedSelectedFiles;
    //         });
    //     } else {
    //         setShowProgressBar(false);
    //     }
    // };




    const removeFile = (documentId: string, fileIndex: number) => {
        setSelectedFiles(prevSelectedFiles => {
            const updatedFiles = prevSelectedFiles[documentId]?.files.filter((_, index) => index !== fileIndex) || [];

            let updatedSelectedFiles: Record<string, { files: File[], incompatibilityId: string, incompatibilityName: string, documentName: string }>;

            if (updatedFiles.length === 0) {
                const { [documentId]: removed, ...rest } = prevSelectedFiles;
                updatedSelectedFiles = rest;
            } else {
                updatedSelectedFiles = {
                    ...prevSelectedFiles,
                    [documentId]: {
                        ...prevSelectedFiles[documentId],
                        files: updatedFiles
                    }
                };
            }

            const newTotalSizeMB = Object.values(updatedSelectedFiles).reduce((acc: number, item) => {
                if (item?.files) {
                    return acc + item.files.reduce((sum: number, file: File) => sum + file.size, 0);
                }
                return acc;
            }, 0) / (1024 * 1024);

            setTotalSize(parseFloat(newTotalSizeMB.toFixed(1))); // Redondeo a 1 decimal

            return updatedSelectedFiles;
        });
    };


    const convertFileToBase64 = async (file: File): Promise<string | null> => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                if (reader.result) {
                    const result = reader.result as string;

                    const base64Data = result.split(',')[1];
                    resolve(base64Data && base64Data);
                } else {

                    resolve(null);
                }
            };
            reader.onerror = (error) => {
                console.error(`Error al leer el archivo ${file.name}:`, error);
                reject(error);
            };
        });
    };
    const createZipFile = async (files: File[]): Promise<string> => {
        const zip = new JSZip();

        for (const file of files) {
            const base64 = await convertFileToBase64(file);
            if (base64) {
                zip.file(file.name, base64, { base64: true });
            } else {
                console.error(`El archivo ${file.name} está vacío o no se pudo leer.`);
            }
        }

        const zipContent = await zip.generateAsync({ type: "base64" });
        return zipContent;
    };
    const handleCloseModalErrorDocumentWeightLimit = () => {
        setshowModalErrorDocumentWeightLimit(false)
    }




    const [failedDocs, setFailedDocs] = useState<{ id: string; name: string }[]>([]);

    const onSubmit = async () => {
        const maxFileSize = 50 * 1024 * 1024;
        let totalFiles = Object.keys(selectedFiles).length;

        if (totalFiles === 0) return;

        setTotalUploads(totalFiles);
        setSuccessfulUploads(0);

        let failedDocuments: { id: string; name: string }[] = [];

        for (const documentId in selectedFiles) {
            const { files, incompatibilityId, documentName } = selectedFiles[documentId];

            let totalSize = files.reduce((sum, file) => sum + file.size, 0);
            if (totalSize > maxFileSize) {
                setshowModalErrorDocumentWeightLimit(true);
                return;
            }

            setIsSubmitting(true);

            let zipContent: string | null = null;
            if (files.length > 1) {
                zipContent = await createZipFile(files);
            } else if (files.length === 1) {
                zipContent = await convertFileToBase64(files[0]);
            }

            if (zipContent) {
                const incompatibilityObject = {
                    id: documentId,
                    nombre: documentName,
                    extension: files.length > 1 ? 'zip' : files[0].name.split('.').pop(),
                    base64: zipContent,
                };

                let progress = 0;
                setUploadProgress((prev) => ({ ...prev, [documentId]: progress }));
                const interval = setInterval(() => {
                    progress += 10;
                    setUploadProgress((prev) => ({ ...prev, [documentId]: progress }));
                    if (progress >= 90) clearInterval(interval);
                }, 300);

                try {
                    const response = await UpdateIncompatibilityDocuments(userId, prestacionId, formId, documentId, incompatibilityObject);
                    clearInterval(interval);

                    if (response.status === 200) {
                        setUploadProgress((prev) => ({ ...prev, [documentId]: 100 }));
                        setUploadStatus((prevStatus) => ({ ...prevStatus, [documentId]: "success" }));
                        setSuccessfulUploads((prev) => prev + 1);
                        setSelectedFiles((prevSelectedFiles) => {
                            const updatedFiles = { ...prevSelectedFiles };
                            delete updatedFiles[documentId];
                            return updatedFiles;
                        });
                        setFileErrors((prevErrors) => ({
                            ...prevErrors,
                            [documentId]: "",
                        }));
                    } else {
                        failedDocuments.push({ id: documentId, name: documentName });
                        setUploadProgress((prev) => ({ ...prev, [documentId]: 0 }));
                        setUploadStatus((prevStatus) => ({ ...prevStatus, [documentId]: "error" }));
                        setFileErrors((prevErrors) => ({
                            ...prevErrors,
                            [documentId]: "Error al subir el documento. Intente nuevamente.",
                        }));
                    }
                } catch (error) {
                    clearInterval(interval);
                    failedDocuments.push({ id: documentId, name: documentName });
                    setUploadProgress((prev) => ({ ...prev, [documentId]: 0 }));
                    setUploadStatus((prevStatus) => ({ ...prevStatus, [documentId]: "error" }));
                    setFileErrors((prevErrors) => ({
                        ...prevErrors,
                        [documentId]: "Error al subir el documento. Intente nuevamente.",
                    }));
                }
            }
        }

        setIsSubmitting(false);

        if (failedDocuments.length === totalFiles) {
            setShowModalSuccessfully("error");
        } else if (failedDocuments.length > 0) {
            setTimeout(() => {
                setShowModalSuccessfully("partial-success");
            }, 500); 
        } else {
            setTimeout(() => {
                setShowModalSuccessfully("success");
            }, 500);
        }

        setFailedDocs(failedDocuments);
    };







    console.log('fileErrors', fileErrors)

    // const onSubmit = async () => {
    //     const maxFileSize = 50 * 1024 * 1024;
    //     let totalSize = 0;

    //     for (const documentId in selectedFiles) {
    //         const { files } = selectedFiles[documentId];
    //         for (const file of files) {
    //             totalSize += file.size;
    //         }
    //     }
    //     const totalSizeMB = totalSize / (1024 * 1024);
    //     setTotalSize(totalSizeMB)
    //     if (totalSize > maxFileSize) {
    //         setshowModalErrorDocumentWeightLimit(true)
    //         return;
    //     }

    //     setIsSubmitting(true);

    //     const incompatibilitiesMap = new Map();

    //     for (const documentId in selectedFiles) {
    //         const { files, incompatibilityId, documentName } = selectedFiles[documentId];

    //         let zipContent: string | null = null;

    //         if (files.length > 1) {
    //             zipContent = await createZipFile(files);
    //         } else if (files.length === 1) {
    //             zipContent = await convertFileToBase64(files[0]);
    //         }

    //         if (zipContent) {
    //             if (!incompatibilitiesMap.has(incompatibilityId)) {
    //                 incompatibilitiesMap.set(incompatibilityId, {
    //                     id: incompatibilityId,
    //                     documentos: [],
    //                 });
    //             }

    //             incompatibilitiesMap.get(incompatibilityId).documentos.push({
    //                 id: documentId,
    //                 nombre: `${documentName}`,
    //                 extension: files.length > 1 ? 'zip' : files[0].name.split('.').pop(),
    //                 base64: zipContent,
    //             });
    //         }
    //     }

    //     const incompatibilidades = Array.from(incompatibilitiesMap.values());

    //     const finalObject = {
    //         id: royalDecreeFormById?.Id,
    //         incompatibilidades: incompatibilidades,
    //     };

    //     try {
    //         await UpdateIncompatibilityDocuments(userId, prestacionId, formId, finalObject);
    //         if (!loadingGetRoyalDecreeFormById) {
    //             setIsSubmitting(false);
    //             setFileErrors({})

    //         }


    //     } catch (error) {
    //         setIsSubmitting(false);

    //         console.error("Error al enviar los documentos", error);
    //     }
    // };



    const hasSelectedFiles = Object.keys(selectedFiles).length > 0;
    useEffect(() => {
        if (!hasSelectedFiles) {
            setShowProgressBar(false)
        }

    }, [hasSelectedFiles])


    const handleShowModal = (observations: string) => {
        setCurrentObservations(observations);
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
        setCurrentObservations('');
        setTotalSize(0)
        setSelectedFiles({})
    };
    const handleShowModalsuccessfully = () => {

        setShowModalSuccessfully(true);
    };

    const handleCloseModalsuccessfully = () => {
        setShowModalSuccessfully(null);
        setTimeout(() => {
            setFailedDocs([]);
        }, 300);
    };

    const handlePrevetAttach = (estate: number) => {
        switch (estate) {
            case 0:
                return false; // "Pendiente Adjuntar Documentos"
            case 1:
                return false; //"Pendiente Validar Gestor"
            case 2:
                return true; //"Aceptado"
            case 3:
                return true; //"Aceptado sin Requerir Documento"
            case 4:
                return false; //"Rechazado"
            case 5:
                return true; //"Obsoleto"
            default:
                return true; //"Estado Desconocido"
        }
    }



    useEffect(() => {
        if (totalSize > 50) {
            setshowModalErrorDocumentWeightLimit(true);
        }
    }, [totalSize, successUpdateIncompatibilityDocuments, uploadStatus]);

    return (
        <>
            <PageMenu title={`${royalDecreeFormById?.TipoStr}`}>
                <div className="btns-header-container">
                    <button onClick={() => history.goBack()}>
                        <img src={IconArrowGray} alt="icon arrow right" />
                    </button>
                </div>
            </PageMenu>

            {loadingGetRoyalDecreeFormById && successGetRoyalDecreeFormById && (
                <Loader activeLoader={true} label="Cargando Formulario"></Loader>
            )}

            <div className="container container-incopatibilidad">
                <div className="container purpose-header">
                    <p style={{ textAlign: 'justify' }}>
                        {royalDecreeFormById?.Encabezado ? royalDecreeFormById?.Encabezado : noData}
                    </p>
                </div>

                <div className="container container-validations-observations">
                    <h2 className="title-incompatibilidades">Estado General de Auditoría</h2>
                    <p>
                        <b>
                            {royalDecreeFormById?.EstadoGeneralRevision
                                ? checkFormStatusRD(royalDecreeFormById?.EstadoGeneralRevision)
                                : noData}
                        </b>
                    </p>
                </div>

                <div className="container container-validations-observations">
                    <h2 className="title-incompatibilidades">Validaciones y Observaciones</h2>
                    <p>{royalDecreeFormById?.ValidacionesObserv ? royalDecreeFormById?.ValidacionesObserv : noData}</p>
                </div>

                <div className="container container-operational-description">
                    <h2 className="title-incompatibilidades">Descripción Operativa</h2>
                    <p>{royalDecreeFormById?.DescripcionOperativa ? royalDecreeFormById?.DescripcionOperativa : noData}</p>
                    <ul className="ul-operational-description">
                        {royalDecreeFormById?.Incompatibilidades.map((inco: any, i: number) => (
                            <li key={i} className="li-operational-description">{inco.Nombre}</li>
                        ))}
                    </ul>
                </div>

                <div className="container-title-incompatibilidades">
                    <h1 className="title-incompatibilidades">Incompatibilidades</h1>
                </div>

                {royalDecreeFormById?.Incompatibilidades.map((incopa: any, i: number) => (
                    <div className="container-incopatibilidad" key={incopa.Id}>
                        <fieldset className={`fieldset-container`}>
                            <legend className="col-12">
                                <span className="legend-text">{incopa.Nombre}</span>
                            </legend>

                            <h6 className="incompatibility-status">
                                {`Estado:  ${checkFormStatusRD(incopa.EstadoRevision)}`}
                            </h6>

                            {incopa?.Documentos && Array.isArray(incopa.Documentos) && (incopa.Documentos.length === 0 ||
                                (incopa.Documentos.length === 1 && Object.keys(incopa.Documentos[0]).length === 0)) ? (
                                <div className="container">
                                    <h3 className="no-documents">No requiere documentación o ya está solicitada en una Incompatibilidad anterior</h3>
                                </div>
                            ) : null}

                            {incopa.Documentos.some(doc => Object.keys(doc).length > 0) ? (
                                incopa?.Documentos.map((doc: any, j: number) => (
                                    <div key={doc.Id}>
                                        <div className="container-documentos">
                                            <div className="col-sm-12 col-md-12 col-lg-3">
                                                <div className="container-check-document-uploated">
                                                    <div className="column-name">Documento solicitado</div>
                                                    {/* Íconos de éxito o error */}
                                                    {uploadStatus[doc.Id] === "success" && (
                                                        // <span className="upload-success-icon">✅</span>
                                                        <span className="icon-check-blue small"></span>
                                                    )}

                                                    {uploadStatus[doc.Id] === "error" && (
                                                        <span className="error-icon2">x</span>
                                                    )}

                                                </div>
                                                <div className="name-and-document-container">
                                                    <p className="name-doc-incopatibilidad-rd">
                                                        {doc.NombrePortal ? `${doc.NombrePortal}` : noData}
                                                    </p>
                                                    {doc.SasUri && (
                                                        <TooltipComponent content="Archivo adjunto">
                                                            <a href={doc.SasUri} rel={doc.NombrePortal} className="link-bold">
                                                                <span className="file-icon-blue"></span>
                                                            </a>
                                                        </TooltipComponent>
                                                    )}
                                                </div>
                                            </div>

                                            <div className="col-sm-12 col-md-12 col-lg-3">
                                                <div className="column-name">Fecha Presentación</div>
                                                <p className="presentation-date-rd">
                                                    {doc.FechaPresentacion ? moment(doc.FechaPresentacion).format('DD-MM-YYYY') : noData}
                                                </p>
                                            </div>

                                            <div className="col-sm-12 col-md-12 col-lg-4">
                                                <div className="column-name">Estado Documento</div>
                                                <div className="name-and-icon-information">
                                                    <p>{checkFormStatusDocumentsRD(doc.EstadoDoc)}</p>
                                                    {doc.Observaciones && (
                                                        <TooltipComponent content="Observaciones">
                                                            <span className="information-icon-orange" onClick={() => handleShowModal(doc.Observaciones)}></span>
                                                        </TooltipComponent>
                                                    )}
                                                </div>
                                            </div>

                                            <div className="col-sm-12 col-md-3 col-lg-2">
                                                <Controller
                                                    name={`filesRd-${i}-${j}`}
                                                    control={control}
                                                    defaultValue={null}
                                                    render={({ field }) => (
                                                        <>
                                                            <label
                                                                htmlFor={`filesRd-${i}-${j}`}
                                                                className={`btn-communication btn-load-file-rd ${handlePrevetAttach(doc.EstadoDoc) ? 'btn-disabled' : ''}`}
                                                            >
                                                                Adjuntar archivo
                                                                <input
                                                                    type="file"
                                                                    id={`filesRd-${i}-${j}`}
                                                                    className="input-file input-file-rd"
                                                                    onChange={(e) => {
                                                                        handleFileChange(e, doc.Id, incopa.Id, incopa.Nombre, doc.NombrePortal);
                                                                        field.onChange(e.target.files[0]);
                                                                    }}
                                                                    disabled={handlePrevetAttach(doc.EstadoDoc)}
                                                                    accept=".pdf,.doc,.docx,.zip,.7z,.txt,.xls,.xlsx,.csv,.odt,.ods,.jpg,.jpeg,.png,.webp"
                                                                    multiple
                                                                />
                                                            </label>
                                                        </>
                                                    )}
                                                />

                                            </div>

                                        </div>



                                        {/* Mostrar el error solo cuando haya un error en el archivo */}

                                        {fileErrors[doc.Id] && <p className="error">{fileErrors[doc.Id]}</p>}

                                        {selectedFiles[doc.Id] && (
                                            <div className="container-file-info">
                                                <p className="selected-file-text-rd">
                                                    {`(${selectedFiles[doc.Id].documentName})`}
                                                </p>
                                                <div className="container-files-list-rd">
                                                    {selectedFiles[doc.Id].files.map((file, index) => (
                                                        <div key={index} className={!loadingUpdateIncompatibilityDocuments ? 'container-name-file-rd' : 'block'}>
                                                            <p className="name-file-rd">{file.name}</p>
                                                            <img
                                                                src={Delete}
                                                                alt="borrar"
                                                                className="remove-file-btn"
                                                                onClick={(e) => {
                                                                    if (!loadingUpdateIncompatibilityDocuments) {
                                                                        removeFile(doc.Id, index);
                                                                    } else {
                                                                        e.preventDefault();
                                                                    }
                                                                }}
                                                            />
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        )}
                                        {/* barra de progreso carga de documento */}
                                        {uploadProgress[doc.Id] !== undefined && (
                                            <div className="progress-bar-container2">
                                                <div
                                                    className="progress-bar2"
                                                    style={{ width: `${uploadProgress[doc.Id]}%` }}
                                                ></div>
                                            </div>
                                        )}

                                    </div>
                                ))
                            ) : null}
                        </fieldset>
                    </div>
                ))}

                <ProgressBarBattery totalSize={totalSize} maxSize={MAX_SIZE_MB} showProgressBar={showProgressBar}></ProgressBarBattery>

                <div className="container container-btn-send-documents-rd">
                    {loadingUpdateIncompatibilityDocuments ? (
                        <Button className="btn-box btn-accent loading btn-otpep active">
                            <div className="loader-btn"></div>
                        </Button>
                    ) : (
                        <div className="container-btn-send">
                            <button
                                type="submit"
                                className="btn-box btn-accent"
                                onClick={handleSubmit(onSubmit)}
                                disabled={!hasSelectedFiles || isSubmitting || totalSize > 50}
                            >
                                Enviar Documentos
                            </button>
                        </div>
                    )}
                </div>

                <div className="container container-audit-status-summary">
                    <h2 className="title-incompatibilidades">Resumen Estado Auditoria</h2>
                    <p>{royalDecreeFormById?.ResumenEstadoAuditoria ? royalDecreeFormById?.ResumenEstadoAuditoria : noData}</p>
                </div>

                <div className="container container-next-actions">
                    <h2 className="title-incompatibilidades">Próximas Acciones</h2>
                    <p>{royalDecreeFormById?.ProximasAcciones ? royalDecreeFormById?.ProximasAcciones : noData}</p>
                </div>

                <div className="container-lopd">
                    <p className="text-lopd">{royalDecreeFormById?.LOPD}</p>
                </div>
            </div>

            <Modal show={showModal} onHide={handleCloseModal} centered>
                <Modal.Header closeButton={false} className="header-modal-observaciones-rd">
                    <span className="information-icon-orange big"></span>
                    <Modal.Title>
                        <h2 className="title-obsevaciones-rd">Observaciones</h2>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="body-modal-observaciones-rd">
                    <p className="text-body-modal-observaciones-rd">{currentObservations ? currentObservations : noData}</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseModal}>
                        Cerrar
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* Modal de éxito o error */}
            <Modal show={showModalSuccessfully !== null} onHide={handleCloseModalsuccessfully} centered>
                <Modal.Header closeButton={false} className="header-modal-observaciones-rd">
                    {showModalSuccessfully === "error" ? (
                        <>
                            <img src={IMGnotice} alt="error" />
                            <Modal.Title>
                                <h2 className="title-obsevaciones-rd">¡Error al enviar documentos!</h2>
                                <p className="text-obsevaciones-error-rd">Por favor, inténtelo de nuevo.</p>
                            </Modal.Title>
                        </>
                    ) : showModalSuccessfully === "partial-success" ? (
                        <>
                            <span className="icon-check-blue big"></span>
                            <Modal.Title>
                                <h2 className="title-obsevaciones-rd">Algunos documentos se enviaron con éxito</h2>


                            </Modal.Title>
                            <Modal.Body className="body-modal-observaciones-rd">
                                <p className="text-body-modal-observaciones-rd">
                                    Sin embargo, los siguientes documentos no se enviaron correctamente:
                                </p>
                                <ul>
                                    {failedDocs.map((doc) => (
                                        <li key={doc.id} className='error'>{doc.name}</li>
                                    ))}
                                </ul>
                            </Modal.Body>
                        </>
                    ) : (
                        <>
                            <span className="icon-check-blue big"></span>
                            <Modal.Title>
                                <h2 className="title-obsevaciones-rd"> Documentos enviados con éxito</h2>
                            </Modal.Title>
                            {/* <Modal.Body className="body-modal-observaciones-rd"> */}
                            {/* <p className="text-body-modal-observaciones-rd">
                                    Todos los documentos de han enviado con exito</p> */}
                            {/* </Modal.Body> */}
                        </>
                    )}
                </Modal.Header>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseModalsuccessfully}>
                        Cerrar
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal show={showModalErrorDocumentWeightLimit} onHide={handleCloseModalErrorDocumentWeightLimit} centered>
                <Modal.Header closeButton={false} className="header-modal-observaciones-rd">
                    <span className="information-icon-orange big"></span>
                    <Modal.Title>
                        <h2 className="title-obsevaciones-rd"> Advertencia </h2>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="body-modal-observaciones-rd">
                    <p className="text-body-modal-observaciones-rd">
                        La suma total de los archivos a enviar excede el límite de 50MB. Proceda a adjuntarlos en diferentes envios
                    </p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseModalErrorDocumentWeightLimit}>
                        Cerrar
                    </Button>
                </Modal.Footer>
            </Modal>
        </>

    );
};

export default FormRoyalDecree;