import React, { useEffect, useState, createContext, useContext } from 'react'
import { useLocation, Link, useHistory } from 'react-router-dom';
import PageMenu from 'components/blocks/PageMenu/PageMenu';
import IconArrowGray from "../../../img/icons/svg/botones/flecha-big-gris.svg";
import FieldsetContainer from 'components/blocks/Delta/Inputs/Containers/FieldsetContainer';
import { FormProvider, useForm } from 'react-hook-form';
import Loader from 'components/blocks/Loader/Loader';
import TrabajadorButtonGroup from 'components/blocks/ManagementNewForms/ButtonGroups/TrabajadorButtonGroup';
import InputMultiRadio from 'components/blocks/ManagementNewForms/Inputs/InputMultiRadio';
import InputChkBox from 'components/blocks/ManagementNewForms/Inputs/InputChkBox';
import InputNIE from 'components/blocks/ManagementNewForms/Inputs/InputNIE';
import InputPattern from 'components/blocks/ManagementNewForms/Inputs/InputPattern';
import InputDate from 'components/blocks/Delta/Inputs/InputDate';
import ArrayFieldHijosACargo from 'components/blocks/ManagementNewForms/ButtonGroups/ArrayFieldHijosACargo';
import ArrayFieldAscendientes from 'components/blocks/ManagementNewForms/ButtonGroups/ArrayFieldAscendientes';
import AccordionForm from 'components/blocks/ManagementNewForms/Accordion/AccordionForm';
import useCreatetModelo145It from 'services/record/useCreateModelo145It';
import useUpdateModelo145ItByID from 'services/record/useUpdateModelo145ItByID';



interface Modelo145 {
    Id: string;
    Modelo145Id: number;
    PersonaId: number;
    UsuarioId: string;
    ExpedienteId: number;
    PrestacionId: string;
    EstadoFormulario: number;
    Observaciones: string;
    Acepto: boolean;
    FechaAceptacionSolicitud: string;
    DatosPersonales: DatosPersonales;
    PensionAdquisicion: PensionAdquisicion;
    HijosACargo: HijoACargo[];
    Ascendientes: Ascendientes[];
}

interface DatosPersonales {
    SolicitanteNombre: string;
    SolicitanteIPF: string;
    SolicitanteIPFTipo: number;
    SolicitanteFechaNacimiento: string;
    SolicitanteSituacionFamiliar: number;
    SolicitanteConyugeIPF: string;
    SolicitanteConyugeIPFTipo: number;
    SolicitanteDiscapacidad: number;
    SolicitanteAyudaTercerasPersonas: boolean;
    SolicitanteMovilidadGeografica: string;
    SolicitanteConyugeRendimientos: boolean;
}

interface PensionAdquisicion {
    PensionFavorDelConyuge: string;
    AnualidadesAlimentosHijos: string;
    AdquisicionVivienda: boolean;
}

interface HijoACargo {
    HijoACargoId: number;
    Id: string;
    HijoFechaNacimiento: string;
    HijoFechaAcogimiento: string;
    HijoDiscapacidad: number;
    HijoAyudaTerceraPersona: boolean;
    HijoComputoEntero: boolean;
}

interface Ascendientes {
    AscendenteId: number;
    Id: string;
    AscendienteFechaNacimiento: string;
    AscendienteDiscapacidad: number;
    AscendienteAyudaTerceraPersona: boolean;
    AscendienteConvivenciaOtrosDescendientes: boolean;
}


export const SolicitudModelo145: React.FC<{ dataUser: Modelo145, info: boolean }> = ({ dataUser, info }) => {
    const history = useHistory();
    const location = useLocation();
    const title = "Modelo 145"
    const formMethods = useForm();
    const { register, handleSubmit, setError, formState: { errors }, setValue, watch, control } = formMethods;
    const [CasadoSolicitante, setCasadoSolicitante] = useState(false);
    const [aceptarDocumento, setAceptarDocumento] = useState(true);
    const [loader, setLoader] = useState(false);

    const datos = {
        Acepto: true,
        DatosPersonales: {
            SolicitanteNombre: dataUser?.DatosPersonales?.SolicitanteNombre,
            SolicitanteIPF: dataUser?.DatosPersonales?.SolicitanteIPF,
            SolicitanteIPFTipo: dataUser?.DatosPersonales?.SolicitanteIPFTipo,
            SolicitanteFechaNacimiento: dataUser?.DatosPersonales?.SolicitanteFechaNacimiento,
            SolicitanteSituacionFamiliar: dataUser?.DatosPersonales?.SolicitanteSituacionFamiliar,
            SolicitanteConyugeIPF: dataUser?.DatosPersonales?.SolicitanteConyugeIPF,
            SolicitanteConyugeIPFTipo: dataUser?.DatosPersonales?.SolicitanteConyugeIPFTipo,
            SolicitanteDiscapacidad: dataUser?.DatosPersonales?.SolicitanteDiscapacidad,
            SolicitanteAyudaTercerasPersonas: dataUser?.DatosPersonales?.SolicitanteAyudaTercerasPersonas,
            SolicitanteMovilidadGeografica: dataUser?.DatosPersonales?.SolicitanteMovilidadGeografica,
            SolicitanteConyugeRendimientos: dataUser?.DatosPersonales?.SolicitanteConyugeRendimientos,
        },
        PensionAdquisicion: {
            PensionFavorDelConyuge: dataUser?.PensionAdquisicion?.PensionFavorDelConyuge,
            AnualidadesAlimentosHijos: dataUser?.PensionAdquisicion?.AnualidadesAlimentosHijos,
            AdquisicionVivienda: dataUser?.PensionAdquisicion?.AdquisicionVivienda
        },
        HijosACargo: dataUser?.HijosACargo?.map(hijo => ({
            HijoACargoId: hijo.HijoACargoId,
            Id: hijo.Id,
            HijoFechaNacimiento: hijo.HijoFechaNacimiento,
            HijoFechaAcogimiento: hijo.HijoFechaAcogimiento,
            HijoDiscapacidad: hijo.HijoDiscapacidad.toString(),
            HijoAyudaTerceraPersona: hijo.HijoAyudaTerceraPersona,
            HijoComputoEntero: hijo.HijoComputoEntero
        })),
        Ascendientes: dataUser?.Ascendientes?.map(ascendente => ({
            AscendenteId: ascendente?.AscendenteId,
            Id: ascendente?.Id,
            AscendienteFechaNacimiento: ascendente?.AscendienteFechaNacimiento,
            AscendienteDiscapacidad: ascendente?.AscendienteDiscapacidad?.toString(),
            AscendienteAyudaTerceraPersona: ascendente?.AscendienteAyudaTerceraPersona,
            AscendienteConvivenciaOtrosDescendientes: ascendente?.AscendienteConvivenciaOtrosDescendientes
        }))
    };






    ///////////////////////////////////// SUBMIT //////////////////////////////
    const { createModelo145It , loadingModelo145It, errorModelo145It, successModelo145It } = useCreatetModelo145It();
    const {updateModelo145ById, loadingUpdateModelo145ById , errorUpdateModelo145ById, successUpdateModelo145ById} = useUpdateModelo145ItByID();
    const onSubmit = async (data: any) => {
        console.log(data);

        const dataSubmit = {
            id: dataUser?.Id,
            PersonaId: dataUser?.PersonaId,
            UsuarioId: dataUser?.UsuarioId,
            ExpedienteId: dataUser?.ExpedienteId,
            PrestacionId: dataUser?.PrestacionId,
            Acepto: true,
            DatosPersonales: {
                SolicitanteNombre: data?.DatosPersonales?.SolicitanteNombre,
                SolicitanteIPF: data?.DatosPersonales?.SolicitanteIPF,
                SolicitanteIPFTipo: parseInt(data?.DatosPersonales?.SolicitanteIPFTipo),
                SolicitanteFechaNacimiento: data?.DatosPersonales?.SolicitanteFechaNacimiento,
                SolicitanteSituacionFamiliar: parseInt(data?.DatosPersonales?.SolicitanteSituacionFamiliar),
                SolicitanteConyugeIPF: data?.DatosPersonales?.SolicitanteConyugeIPF,
                SolicitanteConyugeIPFTipo: parseInt(data?.DatosPersonales?.SolicitanteConyugeIPFTipo),
                SolicitanteDiscapacidad: parseInt(data?.DatosPersonales?.SolicitanteDiscapacidad),
                SolicitanteAyudaTercerasPersonas: data?.DatosPersonales?.SolicitanteAyudaTercerasPersonas,
                SolicitanteMovilidadGeografica: data?.DatosPersonales?.SolicitanteMovilidadGeografica,
                SolicitanteConyugeRendimientos: data?.DatosPersonales?.SolicitanteConyugeRendimientos
            },
            PensionAdquisicion: {
                PensionFavorDelConyuge: typeof data?.PensionAdquisicion?.PensionFavorDelConyuge === "string"
                ? parseFloat(data?.PensionAdquisicion?.PensionFavorDelConyuge.replace(/,/g, '.')) || "0" //14.35
                : parseFloat(data?.PensionAdquisicion?.PensionFavorDelConyuge) || "0",
                AnualidadesAlimentosHijos: typeof data?.PensionAdquisicion?.AnualidadesAlimentosHijos === "string"
                ? parseFloat(data?.PensionAdquisicion?.AnualidadesAlimentosHijos.replace(/,/g, '.')) || "0" //14.35
                : parseFloat(data?.DatosPersonales?.IRPFVoluntario) || "0",
                AdquisicionVivienda: data?.PensionAdquisicion?.AdquisicionVivienda
            },
            HijosACargo: data?.HijosACargo?.map(hijo => ({
                HijoACargoId: hijo.HijoACargoId,
                Id: hijo.Id,
                HijoFechaNacimiento: hijo.HijoFechaNacimiento,
                HijoFechaAcogimiento: hijo.HijoFechaAcogimiento,
                HijoDiscapacidad: parseInt(hijo.HijoDiscapacidad),
                HijoAyudaTerceraPersona: hijo.HijoAyudaTerceraPersona,
                HijoComputoEntero: hijo.HijoComputoEntero
            })),
            Ascendientes: data?.Ascendientes?.map(Ascendiente => ({
                AscendientesId: Ascendiente.AscendienteId,
                Id: Ascendiente.Id,
                AscendienteFechaNacimiento: Ascendiente.AscendienteFechaNacimiento,
                AscendienteDiscapacidad: parseInt(Ascendiente.AscendienteDiscapacidad),
                AscendienteAyudaTerceraPersona: Ascendiente.AscendienteAyudaTerceraPersona,
                AscendienteConvivenciaOtrosDescendientes: Ascendiente.AscendienteConvivenciaOtrosDescendientes
            }))
        };

        console.log("Datos enviar", dataSubmit);

        if(info){
            createModelo145It(dataUser.UsuarioId, dataUser.ExpedienteId.toString(),  dataSubmit);
        }
        else{
            updateModelo145ById(dataUser.UsuarioId, dataUser.PrestacionId, dataUser.Id, dataSubmit);
        }




    };









    useEffect(() => {
        console.log("DATAUSER", dataUser && dataUser)
    }, []);

    useEffect(() => {

        if (watch('DatosPersonales.SolicitanteSituacionFamiliar') === '1') {
            setCasadoSolicitante(true);
        }
        else {
            setCasadoSolicitante(false);
        }
    }, [watch('DatosPersonales.SolicitanteSituacionFamiliar')]);

    const AceptarSolicitud = () => {
        setAceptarDocumento(!aceptarDocumento);
    }

    return (
        <>
            {/* <PageMenu title={title} >
                <div className="btns-header-container">
                    <button onClick={() => history.goBack()}>
                        <img src={IconArrowGray} alt="icon arrow right" />
                    </button>
                </div>
            </PageMenu> */}
            <Loader activeLoader={loader || loadingModelo145It || loadingUpdateModelo145ById} label="Cargando Formulario"></Loader>
            <FormProvider {...formMethods}>
                <div className="container form--simple-styled" id="formDeltawithoutleave">

                    <AccordionForm title={'Datos Personales'} expandedInit={true} nombreError='DatosPersonales' >
                        <TrabajadorButtonGroup
                            errors={errors}
                            register={register}
                            label={"Datos del Solicitante"}
                            nombreCompleto={true}
                            nacimiento={true}
                            localizacion={true}
                            seguridadSocial={false}
                            setValue={setValue}
                            watch={watch}
                            acronimo='DatosPersonales.Solicitante'
                            defaultValueNombre={datos.DatosPersonales.SolicitanteNombre}
                            defaultValueIPF={datos.DatosPersonales.SolicitanteIPF}
                            defaultValueTipoIPF={datos?.DatosPersonales?.SolicitanteIPFTipo?.toString()}
                            defaultValueFechaNacimiento={new Date(datos.DatosPersonales.SolicitanteFechaNacimiento)}
                            disableFechaNacimiento={datos.DatosPersonales.SolicitanteFechaNacimiento ? true : false}
                        >

                            <InputMultiRadio
                                errors={errors}
                                register={register}
                                opciones={["0", "1", "2"]}
                                labels={["Soltero/a, viudo/a, divorciado/a o separado/a legalmente con hijos solteros menores de 18 años o incapacitados judicialmente que conviven exclusivamente con Vd., sin convivir también con el otro progenitor, siempre que proceda consignar al menos un hijo o descendiente en el siguiente apartado de este formulario",
                                    "Casado/a y no separado/a legalmente cuyo cónyuge no obtiene rentas superiores a 1.500 euros anuales, excluidas las exentas",
                                    "Situación familiar distinta de las dos anteriores (solteros sin hijos, casados cuyo cónyuge obtiene rentas superiores a 1.500 euros anuales, ..., etc.) (Marque también esta casilla si no desea manifestar su situación familiar)"]}
                                name={'DatosPersonales.SolicitanteSituacionFamiliar'}
                                id={'DatosPersonales.SolicitanteSituacionFamiliar'}
                                required='Especifique su situación familiar'
                                label='Situación familiar'
                                defaultValue={datos?.DatosPersonales?.SolicitanteSituacionFamiliar?.toString()}
                            />
                            <div className='col-xl-12' style={{ display: CasadoSolicitante ? 'block' : 'none' }}>
                                <InputNIE disabled={false}
                                    name={'DatosPersonales.SolicitanteConyugeIPF'}
                                    id={'DatosPersonales.SolicitanteConyugeIPF'}
                                    label={'IPF Conyuge'} placeholder={''}
                                    errors={errors} register={register}
                                    watch={watch}
                                    defaultValue={datos.DatosPersonales.SolicitanteConyugeIPF}
                                    defaultValueTipo={datos?.DatosPersonales?.SolicitanteConyugeIPFTipo?.toString()} />
                            </div>
                            <InputMultiRadio
                                errors={errors}
                                register={register}
                                opciones={["0", "1", "2"]}
                                labels={["Sin Discapacidad", "Mayor o igual al 33% y Menor al 65%", "Mayor o igual al 65%"]}
                                name={'DatosPersonales.SolicitanteDiscapacidad'}
                                id={'DatosPersonales.SolicitanteDiscapacidad'}
                                label='Discapacidad'
                                defaultValue={datos?.DatosPersonales?.SolicitanteDiscapacidad?.toString()}
                            />

                            <InputChkBox
                                id="DatosPersonales.SolicitanteAyudaTercerasPersonas"
                                name="DatosPersonales.SolicitanteAyudaTercerasPersonas"
                                label="Además, tengo acreditada la necesidad de ayuda de terceras personas o movilidad reducida"
                                placeholder=""

                                col={12}
                                xl={12}
                                md={12}
                                errors={errors}
                                register={register}
                                defaultValue={datos.DatosPersonales.SolicitanteAyudaTercerasPersonas}

                            />

                            <div className='col-xl-12 mt-4'>
                                <InputDate modal='Si anteriormente estaba Vd. en situación de desempleo e inscrito en la oficina de empleo y la aceptación del puesto de trabajo actual ha exigido el traslado de su residencia habitual a un nuevo municipio, indique la fecha de dicho traslado'
                                    defaultValue={new Date(datos.DatosPersonales.SolicitanteMovilidadGeografica)} name={'DatosPersonales.SolicitanteMovilidadGeografica'} maxDate={new Date()} id={'DatosPersonales.SolicitanteMovilidadGeografica'} label={'Movilidad Geográfica'} placeholder={''} errors={errors} register={register} />
                            </div>
                            <InputChkBox
                                id="DatosPersonales.SolicitanteConyugeRendimientos"
                                name="DatosPersonales.SolicitanteConyugeRendimientos"
                                label="Obtención de rendimientos con período de generación superior a 2 años durante los 5 períodos impositivos anteriores."
                                placeholder=""
                                col={12}
                                xl={12}
                                md={12}
                                errors={errors}
                                register={register}
                                defaultValue={datos.DatosPersonales.SolicitanteConyugeRendimientos}
                                modal='Marque esta casilla si, en el plazo comprendido en los 5 períodos impositivos anteriores al ejercicio al que corresponde la presente comunicación, ha percibido rendimientos del trabajo con período de generación 
superior a 2 años, a los que, a efectos del cálculo del tipo de retención le haya sido aplicada la reducción por irregularidad contemplada en el artículo 18.2 de la Ley del Impuesto y, sin embargo, posteriormente 
usted no haya aplicado la citada reducción en su correspondiente autoliquidación del Impuesto sobre la Renta'

                            />
                        </TrabajadorButtonGroup>
                    </AccordionForm>


                    <AccordionForm title={'Personas a Cargo'} expandedInit={false} nombreError='' >
                        <div className="row" >
                            <div className="col-xl-12">
                                <FieldsetContainer legend="Hijos y otros descendientes menores de 25 años, o mayores de dicha edad si son discapacitados, que conviven con el perceptor" disabled={false}>
                                    <label className="input-block__label">Solamente para baja médica de EC-ANL cuando causa pago directo por extinción de la relación laboral.</label>
                                    <ArrayFieldHijosACargo control={control} name={'HijosACargo'} errors={errors} register={register} watch={watch} setValue={setValue} defaultValue={datos?.HijosACargo} />
                                </FieldsetContainer>
                            </div>
                        </div>

                        <div className="row" >
                            <div className="col-xl-12">
                                <FieldsetContainer legend="Ascendientes mayores de 65 años, o menores de dicha edad si son discapacitados, que conviven con el perceptor" disabled={false}>

                                    <ArrayFieldAscendientes control={control} name={'Ascendientes'} errors={errors} register={register} watch={watch} setValue={setValue} defaultValue={datos?.Ascendientes} />
                                </FieldsetContainer>
                            </div>
                        </div>
                    </AccordionForm>


                    <AccordionForm title={'Pensiones y Adquisicion de Vivienda'} expandedInit={false} nombreError='PensionAdquisicion' >
                        <div className="row" >
                            <div className="col-xl-12">
                                <FieldsetContainer legend="Pensiones compensatorias en favor del cónyuge y anualidades por alimentos en favor de los hijos, fijadas ambas por decisión judicial" disabled={false}>
                                    <InputPattern
                                        id="PensionAdquisicion.PensionFavorDelConyuge"
                                        name="PensionAdquisicion.PensionFavorDelConyuge"
                                        label="Pensión compensatoria en favor del cónyuge."
                                        placeholder=""
                                        defaultValue={datos.PensionAdquisicion.PensionFavorDelConyuge ? datos.PensionAdquisicion.PensionFavorDelConyuge : '0,0'}
                                        disabled={false}
                                        xl={6}
                                        md={6}
                                        errors={errors}
                                        register={register}
                                        pattern={/^\d+(?:[.,]\d+)?$/}
                                        patternMessage='Solo se permiten valores numericos y decimales'

                                    />
                                    <InputPattern
                                        id="PensionAdquisicion.AnualidadesAlimentosHijos"
                                        name="PensionAdquisicion.AnualidadesAlimentosHijos"
                                        label="Anualidades por alimentos en favor de los hijos."
                                        placeholder=""
                                        defaultValue={datos.PensionAdquisicion.AnualidadesAlimentosHijos ? datos.PensionAdquisicion.AnualidadesAlimentosHijos : '0,'}
                                        xl={6}
                                        md={6}
                                        disabled={false}
                                        errors={errors}
                                        register={register}
                                        pattern={/^\d+(?:[.,]\d+)?$/}
                                        patternMessage='Solo se permiten valores numericos y decimales'
                                    />
                                    <InputChkBox
                                        id="PensionAdquisicion.AdquisicionVivienda"
                                        name="PensionAdquisicion.AdquisicionVivienda"
                                        label="Pago por la adquisición o rehabilitación de la vivienda habitual utilizando financiación ajena, con derecho a deducción en el IRPF"
                                        placeholder=""
                                        justify='center'
                                        modal='Importante: sólo podrán cumplimentar este apartado los contribuyentes que hayan adquirido su vivienda habitual, o hayan satisfecho cantidades por obras de rehabilitación de la misma, antes del 1 de enero de 2013 Si está Vd. efectuando pagos por préstamos destinados a la adquisición o rehabilitación de su vivienda habitual por los que vaya a tener derecho a deducción por inversión en vivienda habitual en el IRPF y la cuantía total de sus retribuciones íntegras en concepto de rendimientos del trabajo procedentes de todos sus pagadores es inferior a 33.007,20 euros anuales'
                                        col={12}
                                        xl={12}
                                        md={12}
                                        errors={errors}
                                        register={register}
                                        defaultValue={datos.PensionAdquisicion.AdquisicionVivienda}

                                    />
                                </FieldsetContainer>
                            </div>
                        </div>
                    </AccordionForm>





                    <div className="row mb-4">
                        <div className="col-xl-12">
                            <FieldsetContainer legend="" disabled={false}>


                                <div className='paragraph text-info mb-3 col-12'>
                                    Manifiesto ser contribuyente del IRPF y declaro que son ciertos los datos arriba indicados, presentando
                                    ante la empresa o entidad pagadora la presente comunicación de mi situación personal y familiar, o de
                                    su variación, a los efectos previstos en el artículo 88 del Reglamento del IRPF.
                                </div>
                                <div className='ml-5 form-check mb-4 col-12'>
                                    <input className='form-check-input' type='checkbox' id='Acepto' {...register('Acepto', { onChange: AceptarSolicitud })} />
                                    <label className='form-check-label ' htmlFor='Acepto'>Acepto la información declarada.</label>
                                </div>
                                <div className=' mb-4 col-12' style={{ display: aceptarDocumento ? 'none' : 'block' }}>
                                    <div className='paragraph text-info col-12'>
                                        {"Aceptado el: " + new Intl.DateTimeFormat('es-ES', { year: 'numeric', month: 'long', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit' }).format(new Date())}

                                    </div>
                                </div>


                            </FieldsetContainer>
                        </div>
                    </div>



                    {/* BOTON DE ENVIAR */}
                    <button className='btn-box btn-accent  mt-3 mb-3' disabled={aceptarDocumento} type="submit" onClick={handleSubmit(onSubmit)}>
                        {info ? "CREAR FORMULARIO" : "ACTUALIZAR FORMULARIO"}
                    </button>
                    {
                        errorModelo145It && <div className="paragraph text-danger mt-3 mb-3">{errorModelo145It}</div>
                    }
                    {
                        errorUpdateModelo145ById && <div className="paragraph  text-danger mt-3 mb-3">{errorUpdateModelo145ById}</div>
                    }
                    {
                        successUpdateModelo145ById && <div className="paragraph  text-success mt-3 mb-3">Formulario actualizado correctamente</div>
                    }
                    {
                        successModelo145It && <div className="paragraph  text-success mt-3 mb-3">Formulario creado correctamente</div>
                    }

                    <div className='paragraph small text-secondary  mb-4'>En cumplimiento de lo establecido en la normativa sobre Protección de Datos de Carácter Personal, Solimat Mutua Colaboradora con la Seguridad Social nº 72, le informa que sus datos de carácter personal serán objeto de tratamiento, con la finalidad del mantenimiento y cumplimiento de la relación. La base jurídica que legitima el tratamiento de sus datos es el cumplimiento de una obligación legal. Sus datos serán conservados de forma indefinida en nuestra entidad para atender a las posibles responsabilidades derivadas del tratamiento. Usted podrá revocar el consentimiento prestado, así como, ejercitar sus derechos de acceso, rectificación, supresión, oposición, limitación del tratamiento o portabilidad, en su caso, remitiendo un escrito a la siguiente dirección postal: Solimat, Mutua Colaboradora con la Seguridad Social nº 72, C/. Berna, 1  45003  Toledo, adjuntando su NIF. Si considera que sus derechos no han sido debidamente atendidos, puede contactar con nuestro delegado de protección de datos en la siguiente dirección de correo electrónico: lopd@solimat.com o interponer una reclamación frente a la Agencia Española de Protección de Datos. </div>

                    <div className='paragraph small text-secondary  mb-4'>Esta solicitud será remitida telemáticamente por el solicitante o por su representante legal. El órgano gestor podrá comprobar la identidad del remitente de la solicitud y, en su caso, la facultad de representación del comunicante. </div>


                    <div className='paragraph small text-secondary  mb-4'>La cumplimentación incorrecta de este formulario o la falta de aportación de los documentos que se indican podrá exigir su subsanación. En tanto en cuanto ésta no se produzca, demorará la tramitación de la solicitud. </div>
               
               
               
                </div>


            </FormProvider>

        </>
    )
}

export default SolicitudModelo145;
