import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
interface IInputProps {
  yesId: string,
  noId: string,
  name: string,
  errors: any,
  register: any
  label: string
  defaultValue?: string
}

const YesNoRadioButtons: React.FC<IInputProps> = ({
  yesId,
  noId,
  label,
  name,
  errors,
  defaultValue,
  register }) => {


  return (
    <div className={`pl-4 pr-4 pb-2 d-flex inputs--group ${errors && errors.name?.message ? 'error' : ''}`}>
      <label htmlFor={yesId} className="input-block--radio-check__label">
        <span className="text">Sí</span>
        <input
          type="radio"
          name={name}
          id={yesId}
          value="true"
          {...register(name, { required: 'Selecciona al menos una opción' })}
          defaultChecked={defaultValue === 'true' || defaultValue === null || defaultValue === undefined} 
        />
      </label>
      <label htmlFor={noId} className="input-block--radio-check__label">
        <span className="text">No</span>
        <input
          type="radio"
          name={name}
          id={noId}
          value="false"
          {...register(name, { required: 'Selecciona al menos una opción' })}
          defaultChecked={defaultValue === 'false'} 
        />
      </label>

      {errors &&
        <>
          <p className="error m-0">
            {errors.name?.message}
          </p>
        </>
      }
    </div>
  )
}

export default YesNoRadioButtons;