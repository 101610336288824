import React from 'react'

const ProgressBarBattery = ({totalSize, maxSize, showProgressBar}) => {

    const getProgressWidth = () => {

        return Math.min((totalSize / maxSize) * 70, 70);
    };

       const getProgressColor = () => {
            if (totalSize > 50) return "progress-red";
            if (totalSize > 40) return "progress-orange";
            if (totalSize > 35) return "progress-yellow";
            if (totalSize > 10) return "progress-light-green";
            return "progress-green";
        };
  return (
    <div className="container-general-bar-progress">
                   
                    <div className={`container-progres-bar ${showProgressBar ? "show" : "hide"}`}>

                        <div className="progress-bar-container mt-1">

                            <div
                                className={`progress-bar ${getProgressColor()} transition-all duration-300`}
                                style={{ width: `${getProgressWidth()}%` }}
                            ><p className='text-in-battery'>{parseFloat(totalSize.toFixed(1))}MB</p></div>
                            <div className="container-text-bar">

                                <span className="progress-text">

                                    MAXIMO - {maxSize}MB
                                </span>
                            </div>
                        </div>

                        <div className="container">

                        </div>
                    </div>
                </div>
  )
}

export default ProgressBarBattery