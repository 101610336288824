import React, { useState } from 'react';
import { record } from "@solimat/solimat-web-endpoint";

const useGetModelo145ByID = () => {
  const [loadingGetModelo145ById, setLoadingCreateModelo145] = useState(false);
  const [successGetModelo145ById, setSuccessCreateModelo145] = useState(false);
  const [errorGetModelo145ById, setErrorCreateModelo145] = useState(null);
  const [dataGetModelo145ById, setDataModelo145] = useState(null);

  const BASE_URL = process.env.REACT_APP_PRESTACIONES_PACIENTE_PATH || "";
  const FUNCTIONS_KEY = process.env.REACT_APP_PRESTACIONES_PACIENTE_KEY || "";
  const apiRecord = new record.RecordService(
    BASE_URL,
    FUNCTIONS_KEY
  )



  //GET
  const getModelo145ById = async (userId:string ,prestacionId: string, id: string, _cb?:(data:any)=>void) => {
    setLoadingCreateModelo145(true);
    setSuccessCreateModelo145(false);
    setErrorCreateModelo145(null);

    apiRecord.getModelo145ItByID(userId, prestacionId, id).then((res: any) => {
      setLoadingCreateModelo145(false);
      setSuccessCreateModelo145(true);
      setDataModelo145(res.data);
      if (typeof _cb === "function") {
        _cb(res.data);
      }
      console.log("resdata" ,dataGetModelo145ById && dataGetModelo145ById);
    }).catch((err:any) => {
      console.log(err)
      setLoadingCreateModelo145(false);
      setErrorCreateModelo145(err)
    })
  }

  //UPDATE




  return {
    getModelo145ById,
    loadingGetModelo145ById,
    successGetModelo145ById,
    errorGetModelo145ById,
    dataGetModelo145ById
  }

}

export default useGetModelo145ByID;