import React, { useState, useEffect } from "react";
import { AutoCompleteComponent } from "@syncfusion/ej2-react-dropdowns";
import useUtils from "services/utils/useUtils";
import { useFormContext, useWatch } from "react-hook-form";
import get from "lodash/get";

interface IInputProps {
    name: string;
    required?: string;
    disabled?: boolean;
    xl?: number;
    md?: number;
    col?: number;
    isAutonomo?: any;
}

const InputRegimenFormularios: React.FC<IInputProps> = ({
    name,
    required,
    disabled,
    xl,
    md,
    col,
    isAutonomo,
}) => {
    const [data, setData] = useState<any>(null); // Datos de la API
    const [desc, setDesc] = useState<any>(null); // Descripción seleccionada
    const { getRegimenesFormulario } = useUtils();
    const {
        register,
        formState: { errors },
        setValue,
    } = useFormContext();

    const errorMessage = get(errors, name)?.message;

    // Escucha el valor actual del input con useWatch
    const watchDesc = useWatch({ name });

    // Efecto para obtener los datos iniciales de la API
    useEffect(() => {
        const fetchProvinces = async () => {
            try {
                const provincesData = await getRegimenesFormulario();
                setData(provincesData);
            } catch (error) {
                console.error("Error al obtener provincias:", error);
            }
        };

        fetchProvinces();
    }, []);

    // Efecto para actualizar `desc` cuando cambien los datos o el valor de `watchDesc`
    useEffect(() => {
        if (data && watchDesc) {
            const watchDescString = watchDesc.toString();
            const number =
                watchDescString.length === 3
                    ? `0${watchDescString}`
                    : watchDescString;

            const selectedDesc = data.find((i) => i.Codigo === number);
            //console.log(selectedDesc)
            setDesc(selectedDesc || null); // Asegura que sea null si no encuentra coincidencia

            if (selectedDesc) {
                setValue(name + "Descripcion", selectedDesc.Descripcion);
            }
        }
    }, [data, watchDesc]); // Se ejecuta cuando `data` o `watchDesc` cambian

    // Registra los campos en el formulario al cargar el componente
    useEffect(() => {
        register(name);
        register(name + "Descripcion");
    }, [register, name]);

    return (
        <div className={`col-xl-${xl} col-md-${md} col-${col}`}>
            <fieldset className={`input-block input-block--code`}>
                <div className="container-leyend">
                    <legend className="input-block--code__legend">
                        Regimen Seguridad Social
                    </legend>
                </div>
                <div className="container-inputs-cno-code">
                    <AutoCompleteComponent
                        dataSource={data} // Pasamos los datos de provincias obtenidos de la API
                        fields={{ value: "Descripcion" }} // Mostrar la descripción
                        placeholder="Selecciona un Regimen" // Placeholder
                        change={(e) => {
                            if (typeof isAutonomo === "function") {
                                isAutonomo(e.itemData?.EsAutonomo);
                            }
                            setValue(name, e.itemData?.Codigo);
                            setValue(name + "Descripcion", e.itemData?.Descripcion);
                        }}
                        value={desc?.Descripcion} // Mostrar la descripción actual seleccionada
                        enabled={!disabled} // Deshabilitar si es necesario
                    />
                </div>

                {errorMessage && <p className="error m-0">{errorMessage}</p>}
            </fieldset>
        </div>
    );
};

export default InputRegimenFormularios;
