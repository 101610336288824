import React, { useEffect, useState, createContext, useContext } from 'react'
import { useLocation, Link } from 'react-router-dom';
import PageMenu from 'components/blocks/PageMenu/PageMenu';
import IconArrowGray from "../../../img/icons/svg/botones/flecha-big-gris.svg";
import FieldsetContainer from 'components/blocks/Delta/Inputs/Containers/FieldsetContainer';
import { FormProvider, useForm } from 'react-hook-form';
import Loader from 'components/blocks/Loader/Loader';
import InputPattern from 'components/blocks/ManagementNewForms/Inputs/InputPattern';
import InputNIE from 'components/blocks/ManagementNewForms/Inputs/InputNIE';
import Province from 'components/blocks/Delta/Inputs/Province';
import TestCommunity from 'components/blocks/Delta/Inputs/TestCommunity';
import YesNoNoAnswer from 'components/blocks/Delta/Groups/YesNoNoAnswer';
import YesNoRadioButtons from 'components/blocks/ManagementNewForms/ButtonGroups/YesNoRadioButtons';
import InputChkBox from 'components/blocks/ManagementNewForms/Inputs/InputChkBox';
import InputCNAE2009 from 'components/blocks/ManagementNewForms/Inputs/InputCNAE2009';
import TrabajadorButtonGroup from 'components/blocks/ManagementNewForms/ButtonGroups/TrabajadorButtonGroup';
import useUpdateDeclaracionSituacionActividadItByID from 'services/record/DeclaracionSituacionActividad.tsx/useUpdateDeclaracionSituacionActividadItByID';
import useCreateDeclaracionSituacionActividadIt from 'services/record/DeclaracionSituacionActividad.tsx/useCreateDeclaracionSituacionActividadIt';


export interface DeclaracionSituacionActividadInterface {
    Id: string;
    DeclaracionSituacionActividadId: number;
    PersonaId: number;
    UsuarioId: string;
    ExpedienteId: number;
    PrestacionId: string;
    EstadoFormulario: number;
    Observaciones: string;
    Acepto: boolean;
    FechaAceptacionSolicitud: string;
    ActividadEconomica: string;
    CeseActividad: boolean;
    GestionActividad: number;
    DeclaracionRegimenEspecial: string;
    CNAE2009: string;
    CNAE2009Code: string;
    Titularidad: boolean;
    SolicitanteNombre: string;
    SolicitanteIPF: string;
    SolicitanteIPFTipo: number;
    SolicitanteFechaNacimiento: string;
    SolicitanteSeguridadSocial: string;
    SolicitanteDomicilio: string;
    SolicitanteCodigoPostal: string;
    SolicitanteProvincia: string;
    SolicitanteProvinciaDescripcion: string;
    SolicitanteComunidadCodigo: string;
    SolicitanteComunidadDescripcion: string;
    EstablecimientoCalle: string;
    EstablecimientoProvincia: string;
    EstablecimientoProvinciaDescripcion: string;
    EstablecimientoCodigoMunicipio: string;
    EstablecimientoDescripcionMunicipio: string;
    GestionadoNombreApellidos: string;
    GestionadoNumeroSeguridadSocial: string;
    GestionadoNIE: string;
    GestionadoNIETipo: number;
    GestionadoParentesco: string;
}


export const DeclaracionSituacionActividad: React.FC<{ dataUser: DeclaracionSituacionActividadInterface , info: boolean }> = ({ dataUser , info }) => {

    const location = useLocation();
    const title = "Declaración de Situación de Actividad"
    const breadcrumbs = ["Declaración de Situación de Actividad"];
    const formMethods = useForm();
    const { register, handleSubmit, setError, formState: { errors }, setValue, watch } = formMethods;


    const watchProvinceCode = watch("EstablecimientoProvincia", 0);
    const watchProvinceDescription = watch("EstablecimientoProvinciaDescripcion");
    const watchCommunityCode = watch("EstablecimientoCodigoMunicipio");
    const watchCommunityDescription = watch("EstablecimientoDescripcionMunicipio");

    const watchCNAE = watch("CNAE2009Code");

    const [disableCese, setdisableCese] = useState(true);
    const [disableTitularidad, setdisableTitularidad] = useState(false);
    const [disableGetionActividad, setdisableGetionActividad] = useState(false);
    const [disableGetionActividadFamiliar, setdisableGetionActividadFamiliar] = useState(false);



    useEffect(() => {

        if (watch("titularidad") == 'true') {
            setdisableTitularidad(true);
            setValue("DeclaracionRegimenEspecial", "")
        }
        else {
            setdisableTitularidad(false)
            setValue("EstablecimientoCalle", "")
            setValue("EstablecimientoProvincia", "")
            setValue("EstablecimientoProvinciaDescripcion", "")
            setValue("EstablecimientoCodigoMunicipio", "")
            setValue("EstablecimientoDescripcionMunicipio", "")
            setValue("ActividadEconomica", "")
            setValue("CNAE2009Code", "")
            setValue("CNAE2009", "")
            setValue("GestionadoParentesco", "")
            setValue("GestionadoNombreApellidos", "")
            setValue("GestionadoNumeroSeguridadSocial", "")
            setValue("GestionadoNIE", "")
        }


        if (watch("gestionActividad") == '0') {
            setdisableGetionActividadFamiliar(true);
            setdisableGetionActividad(true);
        }
        else if (watch("gestionActividad") == '1' || '2') {
            setdisableGetionActividadFamiliar(false);
            setdisableGetionActividad(true);
            setValue("GestionadoParentesco", "")
        }
        if (watch("gestionActividad") == 'Cese') {
            setdisableGetionActividad(false);
            setdisableGetionActividadFamiliar(false);
        }
        if (watch("ceseActividad") == 'true') {
            setdisableCese(true);
            setValue("GestionadoParentesco", "")
            setValue("GestionadoNombreApellidos", "")
            setValue("GestionadoNumeroSeguridadSocial", "")
            setValue("GestionadoNIE", "")
        }
        else (
            setdisableCese(false)
        )


    }, [watch("ceseActividad"), watch("titularidad"), watch("gestionActividad")]);
    const [aceptarDocumento, setAceptarDocumento] = useState(true);
    const AceptarSolicitud = () => {

        setAceptarDocumento(!aceptarDocumento);
    }

    const datos = {
        ceseActividad: dataUser?.CeseActividad,
        gestionActividad: dataUser?.GestionActividad,
        Acepto: dataUser?.Acepto,
        SolicitanteNombreApellidos: dataUser?.SolicitanteNombre,
        SolicitanteNumeroSeguridadSocial: dataUser?.SolicitanteSeguridadSocial,
        SolicitanteCodigoPostal: dataUser?.SolicitanteCodigoPostal,
        SolicitanteNIE: dataUser?.SolicitanteIPF,
        SolicitanteNIETipo: dataUser?.SolicitanteIPFTipo || 1,
        SolicitanteCalle: dataUser?.SolicitanteDomicilio,
        SolicitanteProvincia: dataUser?.SolicitanteProvincia,
        SolicitanteProvinciaDescripcion: dataUser?.SolicitanteProvinciaDescripcion,
        titularidad: dataUser?.Titularidad.toString(),
        EstablecimientoCalle: dataUser?.EstablecimientoCalle,
        EstablecimientoProvincia: dataUser?.EstablecimientoProvincia,
        EstablecimientoProvinciaDescripcion: dataUser?.EstablecimientoProvinciaDescripcion,
        ActividadEconomica: dataUser?.ActividadEconomica,
        CNAE2009Code: dataUser?.CNAE2009Code,
        GestionadoNombreApellidos: dataUser?.GestionadoNombreApellidos,
        GestionadoNumeroSeguridadSocial: dataUser?.GestionadoNumeroSeguridadSocial,
        GestionadoNIE: dataUser?.GestionadoNIE,
        GestionadoNIETipo: dataUser?.GestionadoNIETipo || 1,
        GestionadoParentesco: dataUser?.GestionadoParentesco,
        DeclaracionRegimenEspecial: dataUser?.DeclaracionRegimenEspecial,
        CNAE2009: dataUser?.CNAE2009,
        SolicitantecommunityCode: dataUser?.SolicitanteComunidadCodigo,
        SolicitantecommunityDescription: dataUser?.SolicitanteComunidadDescripcion,
        EstablecimientoCodigoMunicipio: dataUser?.EstablecimientoCodigoMunicipio,
        EstablecimientoDescripcionMunicipio: dataUser?.EstablecimientoDescripcionMunicipio
    };

    useEffect(() => {
        setValue("EstablecimientoProvincia", datos.EstablecimientoProvincia);
        setValue("EstablecimientoCodigoMunicipio", datos.EstablecimientoCodigoMunicipio);
        setValue("CNAE2009Code", datos.CNAE2009Code);
    }, []);



    ////////////////////////////////
    ///////// SUBMIT FORM //////////
    ////////////////////////////////
    const { updateDeclaracionSituacionActividadById , dataUpdateDeclaracionSituacionActividadById , errorUpdateDeclaracionSituacionActividadById , loadingUpdateDeclaracionSituacionActividadById, successUpdateDeclaracionSituacionActividadById } = useUpdateDeclaracionSituacionActividadItByID();
    const { createDeclaracionSituacionActividadIt , dataDeclaracionSituacionActividadIt , errorDeclaracionSituacionActividadIt , loadingDeclaracionSituacionActividadIt , successDeclaracionSituacionActividadIt } = useCreateDeclaracionSituacionActividadIt();
    const onSubmit = async (data: any) => {

        console.log(data);

        const datosEnviar = {
            Id: dataUser?.Id,
            PersonaId: dataUser?.PersonaId,
            UsuarioId: dataUser?.UsuarioId,
            ExpedienteId: dataUser?.ExpedienteId,
            PrestacionId: dataUser?.PrestacionId,
            Acepto: true,

            ActividadEconomica: data?.ActividadEconomica,
            CeseActividad: data?.ceseActividad === 'true',
            GestionActividad: parseInt(data?.gestionActividad),
            DeclaracionRegimenEspecial: data?.DeclaracionRegimenEspecial,
            CNAE2009: data?.CNAE2009,
            CNAE2009Code: data?.CNAE2009Code,
            Titularidad: data?.titularidad === 'true',
            SolicitanteNombre: data?.SolicitanteNombre,
            SolicitanteIPF: data?.SolicitanteIPF,
            SolicitanteIPFTipo: parseInt(data?.SolicitanteIPFTipo) || 1,
            SolicitanteFechaNacimiento: data?.SolicitanteFechaNacimiento,
            SolicitanteSeguridadSocial: data?.SolicitanteSeguridadSocial,
            SolicitanteDomicilio: data?.SolicitanteDomicilio,
            SolicitanteCodigoPostal: data?.SolicitanteCodigoPostal,
            SolicitanteProvincia: data?.SolicitanteProvincia,
            SolicitanteProvinciaDescripcion: data?.SolicitanteProvinciaDescripcion,
            SolicitanteComunidadCodigo: data?.SolicitanteComunidadCodigo,
            SolicitanteComunidadDescripcion: data?.SolicitanteComunidadDescripcion,
            EstablecimientoCalle: data?.EstablecimientoCalle,
            EstablecimientoProvincia: data?.EstablecimientoProvincia,
            EstablecimientoProvinciaDescripcion: data?.EstablecimientoProvinciaDescripcion,
            EstablecimientoCodigoMunicipio: data?.EstablecimientoCodigoMunicipio,
            EstablecimientoDescripcionMunicipio: data?.EstablecimientoDescripcionMunicipio,
            GestionadoNombreApellidos: data?.GestionadoNombreApellidos,
            GestionadoNumeroSeguridadSocial: data?.GestionadoNumeroSeguridadSocial,
            GestionadoNIE: data?.GestionadoNIE,
            GestionadoNIETipo: parseInt(data?.GestionadoNIETipo) || 1,
            GestionadoParentesco: data?.GestionadoParentesco
        };

        console.log("Datos que se envian: " , datosEnviar)

        if(info){
            createDeclaracionSituacionActividadIt(dataUser?.UsuarioId.toString(), dataUser?.ExpedienteId.toString(), datosEnviar);
        }
        else{
            updateDeclaracionSituacionActividadById(dataUser?.UsuarioId, dataUser?.PrestacionId , dataUser?.Id , datosEnviar);
        }




    };






    return (
        <>
            <Loader activeLoader={ loadingUpdateDeclaracionSituacionActividadById || loadingDeclaracionSituacionActividadIt } label="Cargando Formulario"></Loader>

            <FormProvider {...formMethods}>
                <div className="container form--simple-styled" id="formDeltawithoutleave">
                    <div className='paragraph small text-secondary  mt-4'>Resolución de 04/02/2004 del INSS y Resolución de 22/03/2004 del ISM, sobre cumplimiento por los trabajadores por cuenta propia de la
                        obligación establecida en Real Decreto 1273/2003, en la que se establece que la declaración deberá presentarse dentro de los 15 días siguientes
                        a la fecha de la baja médica, en el caso de incapacidad temporal y de los 15 días siguientes a la suspensión de la actividad, en los supuestos de
                        riesgo durante el embarazo.
                    </div>


                    <TrabajadorButtonGroup errors={errors} register={register} label={'Solicitante'} setValue={setValue} watch={watch} nombreCompleto={true} seguridadSocial={true} acronimo='Solicitante'
                        defaultValueNombre={datos?.SolicitanteNombreApellidos}
                        defaultValueIPF={datos?.SolicitanteNIE}
                        defaultValueTipoIPF={datos?.SolicitanteNIETipo.toString()}
                        defaultValueSeguridadSocial={datos?.SolicitanteNumeroSeguridadSocial}
                        defaultValueCP={datos?.SolicitanteCodigoPostal}
                        defaultValueDomicilio={datos?.SolicitanteCalle}
                        defaultValueProvincia={datos?.SolicitanteProvincia}
                        defaultValueMunicipio={datos?.SolicitantecommunityCode}
                        disableProvincia={true}
                        disableMunicipio={true}

                    >


                    </TrabajadorButtonGroup>

                    <div className="row">
                        <div className="col-xl-12">
                            <FieldsetContainer legend="" disabled={false}>
                                <div>
                                    <label className="input-block__label">Soy titular de un establecimiento mercantil, industrial o de otra naturaleza:</label>
                                    <YesNoRadioButtons
                                        yesId="titularidadYes"
                                        noId="titularidadNo"
                                        label='¿Es titular de la actividad?'
                                        name="titularidad"
                                        errors={errors}
                                        defaultValue={datos?.titularidad}
                                        register={register}
                                    />
                                </div>
                                <div style={{ display: disableTitularidad ? 'block' : 'none' }}>
                                    <div className="row">
                                        <div className="col-xl-12">
                                            <FieldsetContainer legend="Dicho establecimiento tiene su domicilio social en" disabled={false}>
                                                <InputPattern
                                                    id="EstablecimientoCalle"
                                                    name="EstablecimientoCalle"
                                                    label="Calle, Via, Calleja, Avenida, etc."
                                                    placeholder=""
                                                    defaultValue={datos?.EstablecimientoCalle}
                                                    xl={6}
                                                    md={12}
                                                    errors={errors}
                                                    register={register}
                                                    disabled={false}

                                                />
                                                <div className="col-xl-6">
                                                    <Province
                                                        setValue={setValue}
                                                        errors={errors}
                                                        register={register}
                                                        watchProvinceCode={watchProvinceCode}
                                                        watchProvinceDescription={watchProvinceDescription}
                                                        idCode="EstablecimientoProvincia"
                                                        idDescription="EstablecimientoProvinciaDescripcion"
                                                    />
                                                </div>
                                                <div className="col-xl-6">
                                                    <TestCommunity
                                                        errors={errors}
                                                        register={register}
                                                        setValue={setValue}
                                                        idCode="EstablecimientoCodigoMunicipio"
                                                        idDescription="EstablecimientoDescripcionMunicipio"
                                                        disabled={false}
                                                        filterByProvince={true}
                                                        watchProvinceCode={watchProvinceCode}
                                                        watchCommunityCode={watchCommunityCode}
                                                        watchCommunityDescription={watchCommunityDescription}
                                                    />
                                                </div>
                                                <InputPattern
                                                    id="ActividadEconomica"
                                                    name="ActividadEconomica"
                                                    label="Actividad económica"
                                                    placeholder=""
                                                    defaultValue={datos?.ActividadEconomica}
                                                    xl={6}
                                                    md={12}
                                                    errors={errors}
                                                    register={register}


                                                />
                                                <div className="col-xl-12">
                                                    <InputCNAE2009 errors={errors} register={register} idCode={'CNAE2009Code'} idDescription={'CNAE2009'} setValue={setValue} watchCnaeCode={watchCNAE}
                                                    />
                                                </div>
                                            </FieldsetContainer>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-xl-12 mb-3">
                                            <FieldsetContainer legend="Y que durante la situacion de incapacidad temporal / riesgo, la actividad queda gestionada por: " disabled={false}>



                                                <div className="input-block--radio-check__group d-flex flex-wrap flex-column align-items-start " style={{ display: disableCese ? 'block' : 'none' }}>
                                                    <label htmlFor="opcion11" className="input-block--radio-check__label ml-4">
                                                        <span className="text">Y que durante la situacion de incapacidad temporal / riesgo, la actividad queda gestionada por:</span>
                                                        <input
                                                            type="radio"
                                                            name="ceseActividad"
                                                            id="opcion11"
                                                            value="false"
                                                            {...register("ceseActividad")}
                                                            defaultChecked={!datos?.ceseActividad}
                                                        />
                                                    </label>
                                                    <label htmlFor="opcion22" className="input-block--radio-check__label ">
                                                        <span className="text">Cese temporal o definitivo de la actividad durante la situación de incapacidad temporal/riesgo durante el embarazo o durante la
                                                            lactancia natural del titular del establecimiento</span>
                                                        <input
                                                            type="radio"
                                                            name="ceseActividad"
                                                            id="opcion22"
                                                            value="true"
                                                            {...register("ceseActividad")}
                                                            defaultChecked={datos?.ceseActividad}
                                                        />
                                                    </label>

                                                </div >
                                                <div style={{ display: !disableCese ? 'block' : 'none' }}>
                                                    <div className="input-block--radio-check__group d-flex flex-wrap flex-column align-items-start ">
                                                        <label className="input-block__label">Gestionado Por:</label>
                                                        <label htmlFor="opcion1" className="input-block--radio-check__label ml-4">
                                                            <span className="text">Familiar</span>
                                                            <input
                                                                type="radio"
                                                                name="gestionActividad"
                                                                id="opcion1"
                                                                value="0"
                                                                {...register("gestionActividad")}
                                                                defaultChecked={datos?.gestionActividad === 0}
                                                            />
                                                        </label>
                                                        <label htmlFor="opcion2" className="input-block--radio-check__label ">
                                                            <span className="text">Empleado</span>
                                                            <input
                                                                type="radio"
                                                                name="gestionActividad"
                                                                id="opcion2"
                                                                value="1"
                                                                {...register("gestionActividad")}
                                                                defaultChecked={datos?.gestionActividad === 1}
                                                            />
                                                        </label>
                                                        <label htmlFor="opcion3" className="input-block--radio-check__label ">
                                                            <span className="text">Otra Persona</span>
                                                            <input
                                                                type="radio"
                                                                name="gestionActividad"
                                                                id="opcion3"
                                                                value="2"
                                                                {...register("gestionActividad")}
                                                                defaultChecked={datos?.gestionActividad === 2}
                                                            />
                                                        </label>


                                                    </div>

                                                    <div className="mb-1    " style={{ display: disableGetionActividad ? 'block' : 'none' }} >
                                                        <FieldsetContainer legend=" " disabled={false}>
                                                            <InputPattern
                                                                id="GestionadoNombreApellidos"
                                                                name="GestionadoNombreApellidos"
                                                                label="Nombres y Apellidos"
                                                                placeholder=""
                                                                defaultValue={datos?.GestionadoNombreApellidos}
                                                                xl={12}
                                                                md={12}
                                                                errors={errors}
                                                                register={register}
                                                                disabled={false}

                                                            />
                                                            <InputPattern
                                                                id="GestionadoNumeroSeguridadSocial"
                                                                name="GestionadoNumeroSeguridadSocial"
                                                                label="Número de afiliación a la Seguridad Social"
                                                                placeholder=""
                                                                defaultValue={datos?.GestionadoNumeroSeguridadSocial}
                                                                xl={6}
                                                                md={6}
                                                                errors={errors}
                                                                register={register}
                                                                disabled={false}

                                                            />
                                                            <InputNIE
                                                                id="GestionadoNIE"
                                                                name="GestionadoNIE"
                                                                label="NIE/NIF/DNI/Pasaporte"
                                                                placeholder=""
                                                                watch={watch}
                                                                defaultValue={datos?.GestionadoNIE}
                                                                defaultValueTipo={datos?.GestionadoNIETipo.toString()}
                                                                xl={6}
                                                                md={6}
                                                                errors={errors}
                                                                register={register}
                                                                maxLength={20}
                                                                disabled={false}
                                                            />
                                                            <div style={{ display: disableGetionActividadFamiliar ? 'block' : 'none' }}>
                                                                <InputPattern
                                                                    id="GestionadoParentesco"
                                                                    name="GestionadoParentesco"
                                                                    label="Parentesco"
                                                                    placeholder=""
                                                                    defaultValue={datos?.GestionadoParentesco}
                                                                    xl={12}
                                                                    md={12}
                                                                    errors={errors}
                                                                    register={register}
                                                                    disabled={false}

                                                                />
                                                            </div>
                                                        </FieldsetContainer>


                                                    </div>
                                                </div>




                                            </FieldsetContainer>
                                        </div>
                                    </div>
                                </div>
                                <div style={{ display: disableTitularidad ? 'none' : 'block' }}>
                                    <InputPattern
                                        id="DeclaracionRegimenEspecial"
                                        name="DeclaracionRegimenEspecial"
                                        label="Por el contrario declara que se encuentra incluido en el Régimen Especial de Trabajadores por Cuenta Propia o Autónomos en razón de la actividad económica de"
                                        placeholder=""
                                        defaultValue={datos?.DeclaracionRegimenEspecial}
                                        xl={12}
                                        md={12}
                                        errors={errors}
                                        register={register}
                                        disabled={false}

                                    />
                                </div>


                            </FieldsetContainer>
                        </div>
                    </div>

                    <div className="row mb-4">
                        <div className="col-xl-12">
                            <FieldsetContainer legend="" disabled={false}>


                                <div className='paragraph text-info mb-3 col-12'>
                                    Con la firma de este documento, declaro bajo mi responsabilidad que son ciertos todos los datos que consigno en el mismo y quedo enterado de la obligación de comunicar a la Mutua cualquier variación de los datos en él expresados, que pudieran producirse durante la percepción de la prestación de Incapacidad Temporal </div>
                                <div className='ml-5 form-check mb-4 col-12'>
                                    <input className='form-check-input' type='checkbox' id='Acepto' {...register('Acepto', { onChange: AceptarSolicitud })} />
                                    <label className='form-check-label ' htmlFor='Acepto'>Acepto la información declarada.</label>
                                </div>
                                <div className=' mb-4 col-12' style={{ display: aceptarDocumento ? 'none' : 'block' }}>
                                    <div className='paragraph text-info col-12'>
                                        {"Aceptado el: " + new Intl.DateTimeFormat('es-ES', { year: 'numeric', month: 'long', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit' }).format(new Date())}

                                    </div>
                                </div>


                            </FieldsetContainer>
                        </div>
                    </div>



                    {/* BOTON DE ENVIAR */}
                    <button className='btn-box btn-accent  mt-3 mb-3' disabled={aceptarDocumento} type="submit" onClick={handleSubmit(onSubmit)}>
                        {info ? "CREAR FORMULARIO" : "ACTUALIZAR FORMULARIO"}
                    </button>
                    {
                        successDeclaracionSituacionActividadIt || successUpdateDeclaracionSituacionActividadById ? <div className='paragraph text-success mb-4'>FORMULARIO ENVIADO CORRECTAMENTE</div> : ''
                    }
                    {
                        errorDeclaracionSituacionActividadIt && <div className="paragraph text-danger mt-3 mb-3">{errorDeclaracionSituacionActividadIt}</div>
                    }
                    {
                        errorUpdateDeclaracionSituacionActividadById && <div className="paragraph  text-danger mt-3 mb-3">{errorUpdateDeclaracionSituacionActividadById}</div>
                    }
                    <div className='paragraph small text-secondary  mb-4'>En cumplimiento de lo establecido en la normativa sobre de Protección de Datos de Carácter Personal, Solimat Mutua Colaboradora con la Seguridad Social nº 72 le informa
                        que sus datos de carácter personal serán objeto de tratamiento, con la finalidad del mantenimiento y cumplimiento de la relación. La base
                        jurídica que legitima el tratamiento de sus datos es el cumplimiento de una obligación legal. Sus datos serán conservados de forma indefinida en nuestra
                        entidad para atender a las posibles responsabilidades derivadas del tratamiento. Usted podrá revocar el consentimiento prestado, así como ejercitar sus derechos de
                        acceso, rectificación, supresión, oposición, limitación del tratamiento, o portabilidad, en su caso, remitiendo un escrito a la siguiente dirección: Solimat,
                        Mutua Colaboradora con la Seguridad Social nº 72, C/ Berna, 1, 45003, Toledo, adjuntando su NIF. Si considera que sus derechos no han sido debidamente
                        atendidos, puede contactar con nuestro delegado de protección de datos lopd@solimat.com o interponer una reclamación frente a la Agencia Española de Protección
                        de Datos
                    </div>
                </div>


            </FormProvider>

        </>
    )
}

export default DeclaracionSituacionActividad;
