import React, { useEffect, useState } from 'react'
import { useHistory } from "react-router-dom";
import PageMenu from 'components/blocks/PageMenu/PageMenu'
import IconArrowGray from "../../../../img/icons/svg/botones/flecha-big-gris.svg";
import { useParams } from 'react-router-dom';

import Loader from 'components/blocks/Loader/Loader';
import useGetModelo145ItByID from 'services/record/useGetModelo145ItByID';

import useGetDeclaracionSituacionActividadByID from 'services/record/DeclaracionSituacionActividad.tsx/useGetDeclaracionSituacionActividadItByID';
import DeclaracionSituacionActividad from './DeclaracionSituacionActividad';
const jwtDecode = require('jwt-decode');
interface Params {
    idprestacion: string;
    idform: string;
}


const HistorialDeclaracionSituacionActividad = () => {

    const [data, setData] = useState(null);
    const history = useHistory()
    const { getDeclaracionSituacionActividadById,
        loadingGetDeclaracionSituacionActividadById,
        dataGetDeclaracionSituacionActividadById } = useGetDeclaracionSituacionActividadByID();
    const { idprestacion, idform } = useParams<Params>();


    useEffect(() => {
        const actualUrl = window.location.href;
        if (!actualUrl.includes('/mis-gestiones/formularios')) {
            const userId = jwtDecode(localStorage.getItem("token")).extension_id;
            const prestacionId = idprestacion.split('_')[1];
            const formId = idform;
            
            getDeclaracionSituacionActividadById(userId, prestacionId, formId, setData);
        }
    }, []);




    return (
        <div>
            <PageMenu title={'Declaracion de Situacion de Actividad'}>

                <div className="btns-header-container">
                    <button onClick={() => history.goBack()}>
                        <img src={IconArrowGray} alt="icon arrow right" />
                    </button>
                </div>
            </PageMenu>


            {
                loadingGetDeclaracionSituacionActividadById ?
                    <Loader activeLoader={true} label="Cargando Formulario"></Loader>
                    :
                    <DeclaracionSituacionActividad dataUser={data} info={false} />
            }




        </div>
    )
}

export default HistorialDeclaracionSituacionActividad