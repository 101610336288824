import React, { useState } from 'react';
import { record } from "@solimat/solimat-web-endpoint";

const useGetDirectPaymentFormItByID = () => {
  const [loadingGetSolicitudPagoDirectoById, setLoadingCreateSolicitudPagoDirecto] = useState(false);
  const [successGetSolicitudPagoDirectoById, setSuccessCreateSolicitudPagoDirecto] = useState(false);
  const [errorGetSolicitudPagoDirectoById, setErrorCreateSolicitudPagoDirecto] = useState(null);
  const [dataGetSolicitudPagoDirectoById, setDataSolicitudPagoDirecto] = useState(null);

  const BASE_URL = process.env.REACT_APP_PRESTACIONES_PACIENTE_PATH || "";
  const FUNCTIONS_KEY = process.env.REACT_APP_PRESTACIONES_PACIENTE_KEY || "";
  const apiRecord = new record.RecordService(
    BASE_URL,
    FUNCTIONS_KEY
  )



  //GET
  const getSolicitudPagoDirectoById = async (userId, prestacionId, id, _cb?: (data: any) => void) => {
    setLoadingCreateSolicitudPagoDirecto(true);
    setSuccessCreateSolicitudPagoDirecto(false);
    setErrorCreateSolicitudPagoDirecto(null);

    try {
      const res = await apiRecord.getDirectPaymentFormItByID(userId, prestacionId, id);
      setLoadingCreateSolicitudPagoDirecto(false);
      setSuccessCreateSolicitudPagoDirecto(true);
      setDataSolicitudPagoDirecto(res.data);
      if (typeof _cb === "function") {
        _cb(res.data);
      }
      return res.data; // Retorna los datos correctamente
    } catch (err) {
      setLoadingCreateSolicitudPagoDirecto(false);
      setErrorCreateSolicitudPagoDirecto(err);
      console.error("Error en getSolicitudPagoDirectoById:", err);
      throw err;
    }
  };

  //UPDATE




  return {
    getSolicitudPagoDirectoById,
    loadingGetSolicitudPagoDirectoById,
    successGetSolicitudPagoDirectoById,
    errorGetSolicitudPagoDirectoById,
    dataGetSolicitudPagoDirectoById
  }

}

export default useGetDirectPaymentFormItByID;