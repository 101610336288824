import { TooltipComponent } from '@syncfusion/ej2-react-popups';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import Delete from "../../../../img/icons/svg/botones/delete.png";
import get from 'lodash/get';
interface Documentacion {
    Id: string;
    EntornoId?: number;
    documentacionPagoDirectoId?: number;
    TipoDocumento?: number;
    NombrePortal?: string;
    NombreStorage?: string;
    Container?: string;
    Extension?: string;
    FechaPresentacion?: Date;
    Uri?: string;
    SasUri?: string;
    Observaciones?: string;
    Nota?: string;
}

interface IInputProps {
    name: string;
    id: string;
    label: string;
    disabled?: boolean;
    setSize?: any
    totalSize?: any
    doc: Documentacion;
}

const InputArchivo: React.FC<IInputProps> = ({ name, id, label, disabled, doc, setSize, totalSize }) => {
    const { control, formState: { errors }, register, setValue, watch } = useFormContext();
    const errorMessage = get(errors, name + ".file")?.message;
    const [deshabilitado, setDeshabilitado] = useState(false);
    const handleFileChange = (onChange) => (event) => {
        const newFiles = Array.from(event.target.files);
        const currentFiles = watch(name + '.file') || [];
        const updatedFiles = [...currentFiles, ...newFiles];
        if (typeof setSize === 'function') {
            let tamano = 0;
            //event.target.files.map(file => tamano += file.size);
            for (let x = 0; x <= event.target.files.length - 1; x++) {
                tamano += event.target.files[x].size / (1024 * 1024);
            }
            setSize(tamano + totalSize)
        }
        onChange(updatedFiles);
    };

useEffect(() => {
    console.log('Documentacion:', doc);
}, []);


    const removeFile = (onChange, index) => {


        const currentFiles = watch(name + '.file') || [];
        if (typeof setSize === 'function') {
            const tamano = currentFiles[index].size / (1024 * 1024);
            setSize(totalSize - tamano)
        }


        const updatedFiles = currentFiles.filter((_, i) => i !== index);
        onChange(updatedFiles); // Actualiza el valor del campo en el formulario
    };


    useEffect(() => {
        if (totalSize > 50) {
            setDeshabilitado(true)
        }
        else {
            setDeshabilitado(false)
        }

    }, [totalSize]);


    const allowedExtensions = ['.jpg', '.jpeg', '.png', '.gif', '.pdf', '.docx', '.doc', '.xlsx', '.xls', '.pptx', '.ppt', '.zip', '.7z', '.txt', '.csv', '.odt', '.ods', '.webp'];

    const validateFileExtensions = (files) => {
        if (!files || files.length === 0) return true; // Si no hay archivos, la validación pasa

        for (const file of files) {
            const fileExtension = '.' + file.name.split('.').pop().toLowerCase();
            if (!allowedExtensions.includes(fileExtension)) {
                return 'Los archivos contienen extensiones no permitidas';
            }
        }
        return true;
    };


    return (
        <>
            <div className="container-incopatibilidad mb-2" key={id}>
                <fieldset className={`fieldset-container`}>
                    <legend className="col-12">
                        <span className="legend-text">{label}</span>
                    </legend>
                    <div key={id}>
                        <div className="container-documentos">
                            {/* DOCUMENTO SOLICITADO TITULO */}
                            <div className='col-sm-12 col-md-12 col-lg-4'>
                                <div className="column-name">Documento solicitado</div>
                                <div className="name-and-document-container">
                                    <p className='name-doc-incopatibilidad-rd'>{doc?.NombrePortal ? `${doc?.NombrePortal}` : 'Sin Datos'}</p>
                                    {doc?.SasUri &&
                                        <TooltipComponent content="Archivo adjunto">
                                            <a
                                                href={doc?.SasUri}
                                                rel={doc?.NombrePortal}
                                                className="link-bold"
                                            >
                                                <span className="file-icon-blue"></span>
                                            </a>
                                        </TooltipComponent>
                                    }
                                </div>
                            </div>

                            {/* FECHA DE PRESENTACIÓN */}
                            <div className='col-sm-12 col-md-12 col-lg-4'>
                                <div className="column-name">Fecha Presentación</div>
                                <p className='presentation-date-rd'>{doc?.FechaPresentacion ? moment(doc.FechaPresentacion).format('DD-MM-YYYY') : 'Sin Datos'}</p>
                            </div>

                            {/* NOTAS */}
                            <div className='col-sm-12 col-md-12 col-lg-4'>
                                <div className="column-name">Notas</div>
                                <p className='presentation-date-rd'>{doc?.Nota ? doc?.Nota : 'Sin Especificaciones'}</p>
                            </div>

                            {/* INPUT DE ARCHIVOS */}
                            <div className='col-sm-12 col-md-12 m-2 col-lg-12'>
                                <Controller
                                    name={name + '.file'}
                                    control={control}
                                    defaultValue={[]}
                                    rules={{
                                        required: 'Debe adjuntar al menos un archivo',
                                        validate: validateFileExtensions
                                    }}
                                    render={({ field: { onChange, value } }) => (
                                        <>
                                            <label htmlFor={`files-${id}`} className={`btn-communication btn-load-file-rd ${deshabilitado ? 'btn-disabled' : ''}`}>
                                                Adjuntar Archivo
                                                <input
                                                    type="file"
                                                    id={`files-${id}`}
                                                    className="input-file input-file-rd"
                                                    onChange={handleFileChange(onChange)}
                                                    multiple
                                                    disabled={deshabilitado}
                                                />
                                            </label>

                                            {/* Campos ocultos para otros datos */}
                                            <input
                                                type="text"
                                                id={`tipo-file-${id}`}
                                                {...register(name + '.tipo', { value: doc?.TipoDocumento })}
                                                hidden
                                            />
                                            <input
                                                type="text"
                                                id={`nombrePortal-file-${id}`}
                                                {...register(name + '.nombrePortal', { value: doc?.NombrePortal })}
                                                hidden
                                            />
                                            <input
                                                type="text"
                                                id={`id-file-${id}`}
                                                {...register(name + '.id', { value: doc?.Id })}
                                                hidden
                                            />
                                            <input
                                                type="text"
                                                id={`entorno-file-${id}`}
                                                {...register(name + '.entornoLaboral', { value: doc?.EntornoId })}
                                                hidden
                                            />
                                            <input
                                                type="text"
                                                id={`entorno-file-${id}`}
                                                {...register(name + '.documentacionPagoDirectoId', { value: doc?.documentacionPagoDirectoId })}
                                                hidden
                                            />

                                            {/* Lista de archivos seleccionados */}
                                            {value?.length > 0 && (
                                                <div className='container-file-info'>
                                                    <p className='selected-file-text-rd'>
                                                        {`(${doc.NombrePortal})`}
                                                    </p>
                                                    <div className='container-files-list-rd'>
                                                        {value.map((file, index) => (
                                                            <div key={index} className='container-name-file-rd'>
                                                                <p className='name-file-rd'>
                                                                    {file.name}
                                                                </p>
                                                                <img
                                                                    src={Delete}
                                                                    alt="borrar"
                                                                    className="remove-file-btn"
                                                                    onClick={() => removeFile(onChange, index)}
                                                                />
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                            )}
                                        </>
                                    )}
                                />
                            </div>
                        </div>
                    </div>
                </fieldset>
            </div>
            {errorMessage && <p className="error m-0">{errorMessage}</p>}
        </>
    );
};

export default InputArchivo;