import FieldsetContainer from 'components/blocks/Delta/Inputs/Containers/FieldsetContainer';
import React, { useEffect } from 'react';
import { FieldValues, RefCallBack, UseFormRegister, useForm, useFormContext } from 'react-hook-form';
import InputPattern from '../Inputs/InputPattern';
import Province from 'components/blocks/Delta/Inputs/Province';
import TestCommunity from 'components/blocks/Delta/Inputs/TestCommunity';
import InputNIE from '../Inputs/InputNIE';
import InputDate from 'components/blocks/Delta/Inputs/InputDate';

interface IInputProps {
    errors: any,
    register: any,
    label: string
    setValue: any
    watch: any
    children?: any
    seguridadSocial?: boolean
    acronimo?: string
    telefono?: boolean
    localizacion?: boolean
    nacimiento?: boolean
    email?: boolean
    disableNombre?: boolean
    disableApellido1?: boolean
    disableApellido2?: boolean
    disableIPF?: boolean
    disableTipoIPF?: boolean
    disableSeguridadSocial?: boolean
    disableTelefonoMovil?: boolean
    disableTelefonoFijo?: boolean
    disableEmail?: boolean
    disableFechaNacimiento?: boolean
    disableProvincia?: boolean
    disableMunicipio?: boolean
    disableCP?: boolean
    disableDomicilio?: boolean
    defaultValueNombre?: string
    defaultValueApellido1?: string
    defaultValueApellido2?: string
    defaultValueIPF?: string
    defaultValueTipoIPF?: string
    defaultValueSeguridadSocial?: string
    defaultValueTelefonoMovil?: string
    defaultValueTelefonoFijo?: string
    defaultValueEmail?: string
    defaultValueFechaNacimiento?: Date
    defaultValueProvincia?: string
    defaultValueMunicipio?: string
    defaultValueCP?: string
    defaultValueDomicilio?: string
    nombreCompleto?: boolean
}

const TrabajadorButtonGroup: React.FC<IInputProps> = ({ errors, register, setValue, watch, label, children, seguridadSocial, acronimo, telefono, localizacion, nacimiento, email,
    defaultValueNombre, defaultValueApellido1, defaultValueApellido2, defaultValueIPF, defaultValueTipoIPF, defaultValueSeguridadSocial, defaultValueTelefonoFijo, defaultValueTelefonoMovil, defaultValueEmail, defaultValueFechaNacimiento,
    defaultValueProvincia, defaultValueMunicipio, defaultValueCP, defaultValueDomicilio, disableNombre, disableApellido1, disableApellido2, disableIPF, disableTipoIPF, disableSeguridadSocial,
    disableTelefonoMovil, disableTelefonoFijo, disableEmail, disableFechaNacimiento, disableProvincia, disableMunicipio, disableCP, disableDomicilio, nombreCompleto
}) => {



    const watchProvinceCode = watch(`${acronimo ? acronimo : 'Trabajador'}Provincia`, 0);
    const watchProvinceDescription = watch(`${acronimo ? acronimo : 'Trabajador'}ProvinciaDescripcion`);
    const watchCommunityCode = watch(`${acronimo ? acronimo : 'Trabajador'}ComunidadCodigo`);
    const watchCommunityDescription = watch(`${acronimo ? acronimo : 'Trabajador'}ComunidadDescripcion`);

    useEffect(() => {

        setValue(`${acronimo ? acronimo : 'Trabajador'}Provincia`, defaultValueProvincia ? defaultValueProvincia : "");
        setValue(`${acronimo ? acronimo : 'Trabajador'}ComunidadCodigo`, defaultValueMunicipio ? defaultValueMunicipio : "");
        setValue(`${acronimo ? acronimo : 'Trabajador'}FechaNacimiento`, defaultValueFechaNacimiento ? defaultValueFechaNacimiento : null);


    }, [])


    return (
        <>
            <div className="row">
                <div className="col-xl-12">
                    <FieldsetContainer legend={label} disabled={false}>

                        {nombreCompleto ?

                            <InputPattern
                                id={`${acronimo ? acronimo : 'Trabajador'}Nombre`}
                                name={`${acronimo ? acronimo : 'Trabajador'}Nombre`}
                                label="Nombre y Apellidos"
                                placeholder=""
                                req='Falta Nombre y Apellidos'
                                xl={12}
                                md={12}
                                errors={errors}
                                register={register}
                                defaultValue={defaultValueNombre}
                                disabled={disableNombre}

                            />

                            :
                            <>
                                <InputPattern
                                    id={`${acronimo ? acronimo : 'Trabajador'}Nombre`}
                                    name={`${acronimo ? acronimo : 'Trabajador'}Nombre`}
                                    label="Nombre"
                                    placeholder=""
                                    req='Falta Nombre'
                                    xl={6}
                                    md={6}
                                    errors={errors}
                                    register={register}
                                    defaultValue={defaultValueNombre}
                                    disabled={disableNombre}

                                />
                                <InputPattern
                                    id={`${acronimo ? acronimo : 'Trabajador'}Apellido1`}
                                    name={`${acronimo ? acronimo : 'Trabajador'}Apellido1`}
                                    label="1º Apellido"
                                    placeholder=""
                                    req='Falta 1º Apellido'
                                    xl={6}
                                    md={6}
                                    errors={errors}
                                    register={register}
                                    defaultValue={defaultValueApellido1}
                                    disabled={disableApellido1}

                                />
                                <InputPattern
                                    id={`${acronimo ? acronimo : 'Trabajador'}Apellido2`}
                                    name={`${acronimo ? acronimo : 'Trabajador'}Apellido2`}
                                    label="2º Apellido"
                                    placeholder=""
                                    req='Falta 2º Apellido'
                                    xl={6}
                                    md={6}
                                    errors={errors}
                                    register={register}
                                    defaultValue={defaultValueApellido2}
                                    disabled={disableApellido2}
                                />
                            </>

                        }


                        <InputNIE
                            id={`${acronimo ? acronimo : 'Trabajador'}IPF`}
                            name={`${acronimo ? acronimo : 'Trabajador'}IPF`}
                            label="NIE/DNI/Pasaporte"
                            placeholder=""
                            req='Falta rellenar el Documento de Identificación'
                            xl={6}
                            md={6}
                            errors={errors}
                            register={register}
                            maxLength={20}
                            watch={watch}
                            defaultValue={defaultValueIPF}
                            defaultValueTipo={defaultValueTipoIPF}
                            disabled={disableIPF}
                        />
                        {seguridadSocial && (
                            <InputPattern
                                id={`${acronimo ? acronimo : 'Trabajador'}SeguridadSocial`}
                                name={`${acronimo ? acronimo : 'Trabajador'}SeguridadSocial`}
                                label="Nº Seguridad Social"
                                placeholder=""
                                errors={errors}
                                register={register}
                                maxLength={12}
                                xl={6}
                                md={6}
                                pattern={/^[0-9]+$/i}
                                patternMessage='Solo se aceptan números'
                                req='Falta rellenar el Número de Seguridad Social'
                                defaultValue={defaultValueSeguridadSocial}
                                disabled={disableSeguridadSocial}
                            />
                        )}
                        {email &&
                            <InputPattern
                                id={`${acronimo ? acronimo : 'Trabajador'}Email`}
                                name={`${acronimo ? acronimo : 'Trabajador'}Email`}
                                label="Email"
                                placeholder=""
                                req='Falta Email'
                                xl={6}
                                md={6}
                                errors={errors}
                                register={register}
                                defaultValue={defaultValueEmail}
                                disabled={disableEmail}
                            />}
                        {telefono &&
                            <>
                                <InputPattern
                                    id={`${acronimo ? acronimo : 'Trabajador'}TelefonoMovil`}
                                    name={`${acronimo ? acronimo : 'Trabajador'}TelefonoMovil`}
                                    label="Telefono Movil"
                                    placeholder=""
                                    req='Falta Teléfono Movil'
                                    xl={3}
                                    md={3}
                                    errors={errors}
                                    register={register}
                                    defaultValue={defaultValueTelefonoMovil}
                                    disabled={disableTelefonoMovil}
                                />
                                <InputPattern
                                    id={`${acronimo ? acronimo : 'Trabajador'}TelefonoFijo`}
                                    name={`${acronimo ? acronimo : 'Trabajador'}TelefonoFijo`}
                                    label="Telefono Fijo"
                                    placeholder=""
                                    req='Falta Teléfono Fijo'
                                    xl={3}
                                    md={3}
                                    errors={errors}
                                    register={register}
                                    defaultValue={defaultValueTelefonoFijo}
                                    disabled={disableTelefonoFijo}
                                />
                            </>

                        }
                        {nacimiento &&
                            <div className="col-xl-6 col-md-6">
                                <InputDate
                                    id={`${acronimo ? acronimo : 'Trabajador'}FechaNacimiento`}
                                    name={`${acronimo ? acronimo : 'Trabajador'}FechaNacimiento`}
                                    label="Fecha de Nacimiento"
                                    placeholder=""
                                    req='Falta Fecha de Nacimiento'
                                    maxDate={new Date(Date.now())}
                                    errors={errors}
                                    register={register}
                                    defaultValue={defaultValueFechaNacimiento}
                                    disableDate={disableFechaNacimiento ? defaultValueFechaNacimiento ? true : false : false}
                                />
                            </div>
                        }
                        {!localizacion &&
                            <>
                                <InputPattern
                                    id={`${acronimo ? acronimo : 'Trabajador'}Domicilio`}
                                    name={`${acronimo ? acronimo : 'Trabajador'}Domicilio`}
                                    label="Domicilio (Calle/Plaza/Avenida/...)"
                                    placeholder=""
                                    req='Falta Domicilio'
                                    xl={6}
                                    md={6}
                                    errors={errors}
                                    register={register}
                                    defaultValue={defaultValueDomicilio}

                                />
                                <InputPattern
                                    id={`${acronimo ? acronimo : 'Trabajador'}CodigoPostal`}
                                    name={`${acronimo ? acronimo : 'Trabajador'}CodigoPostal`}
                                    label="Código Postal"
                                    placeholder=""
                                    errors={errors}
                                    register={register}
                                    maxLength={5}
                                    req='Falta Código Postal'
                                    xl={6}
                                    md={6}
                                    pattern={/^[0-9]+$/i}
                                    patternMessage='No es un CP válido'
                                    defaultValue={defaultValueCP}
                                    disabled={disableCP}
                                />


                                <div className="col-xl-6 col-md-6">
                                    <Province
                                        setValue={setValue}
                                        errors={errors}
                                        register={register}
                                        watchProvinceCode={watchProvinceCode}
                                        watchProvinceDescription={watchProvinceDescription}
                                        idCode={`${acronimo ? acronimo : 'Trabajador'}Provincia`}
                                        idDescription={`${acronimo ? acronimo : 'Trabajador'}ProvinciaDescripcion`}
                                        required='Falta Provincia'
                                        disabled={disableProvincia ? defaultValueProvincia ? true : false : false}
                                    />
                                </div>
                                <div className="col-xl-6 col-md-6">
                                    <TestCommunity
                                        errors={errors}
                                        register={register}
                                        setValue={setValue}
                                        idCode={`${acronimo ? acronimo : 'Trabajador'}ComunidadCodigo`}
                                        idDescription={`${acronimo ? acronimo : 'Trabajador'}ComunidadDescripcion`}
                                        filterByProvince={true}
                                        watchProvinceCode={watchProvinceCode}
                                        watchCommunityCode={watchCommunityCode}
                                        watchCommunityDescription={watchCommunityDescription}
                                        required='Falta el Municipio'
                                        disabled={disableMunicipio ? defaultValueMunicipio ? true : false : false}
                                    />
                                </div>
                            </>
                        }





                        {children}

                    </FieldsetContainer>
                </div>
            </div>
        </>
    )
}

export default TrabajadorButtonGroup;

// USE EXAMPLE
{/* <InputText 
  id="personalDataName"
  name="personalDataName"
  label="Nombre"
  placeholder="Nombre"
  errors={errors}
  register={register}
  req = 'MENSAJE DE ERROR CUANDO NO HAYA VALOR'
/> */}
