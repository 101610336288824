import React, { useState } from 'react';
import { record } from "@solimat/solimat-web-endpoint";

const useUpdateDeclaracionSituacionActividadItByID = () => {
  const [loadingUpdateDeclaracionSituacionActividadById, setLoadingCreateDeclaracionSituacionActividad] = useState(false);
  const [successUpdateDeclaracionSituacionActividadById, setSuccessCreateDeclaracionSituacionActividad] = useState(false);
  const [errorUpdateDeclaracionSituacionActividadById, setErrorCreateDeclaracionSituacionActividad] = useState(null);
  const [dataUpdateDeclaracionSituacionActividadById, setDataDeclaracionSituacionActividad] = useState(null);

  const BASE_URL = process.env.REACT_APP_PRESTACIONES_PACIENTE_PATH || "";
  const FUNCTIONS_KEY = process.env.REACT_APP_PRESTACIONES_PACIENTE_KEY || "";
  const apiRecord = new record.RecordService(
    BASE_URL,
    FUNCTIONS_KEY
  )


  //UPDATE
  const updateDeclaracionSituacionActividadById = async (userId:string ,prestacionId: string, formId: string, data:any, _cb?:(data:any)=>void) => {
    setLoadingCreateDeclaracionSituacionActividad(true);
    setSuccessCreateDeclaracionSituacionActividad(false);
    setErrorCreateDeclaracionSituacionActividad(null);

    apiRecord.updateDeclaracionSituacionActividadItByID(userId, prestacionId, formId, data).then((res: any) => {
      setLoadingCreateDeclaracionSituacionActividad(false);
      setSuccessCreateDeclaracionSituacionActividad(true);
      setDataDeclaracionSituacionActividad(res.data);
      if (typeof _cb === "function") {
        _cb(res.data);
      }

    }).catch((err:any) => {
      console.log(err)
      setLoadingCreateDeclaracionSituacionActividad(false);
      setErrorCreateDeclaracionSituacionActividad(err.response.data)
    })
  }

  return {
    updateDeclaracionSituacionActividadById,
    loadingUpdateDeclaracionSituacionActividadById,
    successUpdateDeclaracionSituacionActividadById,
    errorUpdateDeclaracionSituacionActividadById,
    dataUpdateDeclaracionSituacionActividadById
  }

}

export default useUpdateDeclaracionSituacionActividadItByID;
